<template>
  <div v-if="gmapIsLoaded" class="card card-inverse card-info">
    <div class="card-header" v-if="header">{{ header }}</div>
    <div>
      <div class="map" v-if="geoloc">
        <gmap-map
          :center="geoloc"
          :zoom="zoom"
          style="width: 100%; height: 350px"
        >
          <gmap-marker
            v-for="(m, index) in markers"
            :key="index"
            :position="m.position"
            :clickable="true"
            @click="center = m.position"
          ></gmap-marker>

          <gmap-circle
            v-if="range"
            :radius="range * 1000"
            :center="geoloc"
          ></gmap-circle>
        </gmap-map>
      </div>
    </div>
    <gmap-autocomplete
      v-if="displayAddress"
      class="form-control geocoding-address"
      :placeholder="placeholder"
      :value="address"
      :componentRestrictions="geocodeRestrictions"
      :selectFirstOnEnter="true"
      @place_changed="placeChangedHandler"
      :disabled="disabled"
      @blur="toastError"
    >
    </gmap-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: "Géolocalisation",
    },
    geoloc: {
      type: Object,
      default: null,
    },
    displayAddress: {
      type: Boolean,
      default: false,
    },
    address: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    zoom: {
      type: Number,
      default: 10,
    },
    range: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gmapIsLoaded: false,
    };
  },
  computed: {
    markers() {
      return [{ position: this.geoloc }];
    },
    geocodeRestrictions() {
      return { country: ["fr", "re"] };
    },
  },
  async mounted() {
    try {
      const google = await this.$gmapApiPromiseLazy();

      this.gmapIsLoaded =
        google?.maps && Object.keys(google.maps).includes("places");
    } catch (e) {
      this.gmapIsLoaded = false;
    }
  },

  methods: {
    toastError(e) {
      if (e.target.value === "") {
        this.$errorToast(
          "Veuillez remplir les champs obligatoires (entourés en rouge)"
        );
      }
    },

    async placeChangedHandler(place) {
      var data = {
        address: null,
        city: null,
        zipcode: null,
        country: null,
        geoloc: [place.geometry.location.lng(), place.geometry.location.lat()],
      };

      for (var i in place.address_components) {
        var c = place.address_components[i];

        if (c.types.indexOf("street_number") !== -1) {
          data.address = c.long_name;
        } else if (c.types.indexOf("route") !== -1) {
          data.address = data.address
            ? data.address + " " + c.long_name
            : c.long_name;
        } else if (c.types.indexOf("country") !== -1) {
          data.country = c.long_name;
        } else if (c.types.indexOf("locality") !== -1) {
          data.city = c.long_name;
        } else if (c.types.indexOf("postal_code") !== -1) {
          data.zipcode = c.long_name;
        }
      }

      if (!data.zipcode) {
        const { data: zipcode } = await this.$api.get(
          "/geolocation/get-zipcode",
          {
            params: {
              city: data.city,
            },
          }
        );

        data.zipcode = zipcode;
      }

      this.$emit("placeChanged", data);
    },
  },
};
</script>

<style type="text/css">
.geocoding-address {
  margin-top: 1px;
}

.pac-container {
  z-index: 9999 !important;
}
</style>
