<template>
  <table class="table table-condensed" v-if="orders">
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th v-if="invoice">Facture</th>
        <th v-if="invoice">Client</th>
        <th v-if="invoice">Adresse</th>
        <th v-if="!invoice">Date</th>
        <th v-if="!invoice && client">Prénom</th>
        <th v-if="!invoice && client">Nom</th>
        <th>Pack</th>
        <th v-if="state">Etat</th>
        <th>Montant</th>
        <th>Concept</th>
        <th v-if="showInvoiceFile">Facture</th>
        <th v-if="!invoice && !client">Mode</th>
        <th v-if="showFrequency">Fréquence</th>
        <th v-if="showIntervenants">Intervenants</th>
        <th>Commercial</th>
        <th>Coach manager</th>
        <th v-if="notes">Notebook</th>
        <th v-if="status">Statut</th>
        <th v-if="notes">Label</th>
        <th></th>
        <th v-if="invoice">Facture</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(order, index) in orders" :key="index" class="click">
        <td>
          <i
            v-if="orderHasFailedPayment(order)"
            class="text-danger fa fa-exclamation-circle"
          />
          <i v-if="order.giftCard" class="text-primary fa fa-gift" />
          <i
            v-if="order.sponsorship || order.usedSponsorship"
            class="text-primary fa fa-bullhorn"
          ></i>
        </td>
        <td v-html="conceptLogo(order.concept, concepts)" />
        <td v-if="invoice">
          <span v-if="order.invoice">{{ order.invoice.filename }}</span>
        </td>
        <td v-if="invoice">
          <span v-if="order.invoice">{{ order.invoice.name }}</span>
        </td>
        <td v-if="invoice">
          <span v-if="order.invoice">{{ formatAddress(order.invoice) }}</span>
        </td>
        <td v-if="!invoice">{{ formatDate(order.createdAt) }}</td>
        <td
          v-if="
            !invoice && client && order.refClient && order.refClient.company
          "
          colspan="2"
        >
          <strong>{{ order.refClient.company }}</strong>
        </td>
        <template v-else-if="!invoice && client && order.refClient">
          <td>
            {{ order.refClient.firstName }}
            {{ order.refClient.company }}
          </td>
          <td>
            <div class="d-flex">
              {{ order.refClient && order.refClient.lastName }}
              <div
                class="text-primary"
                style="margin-left: 0.5rem"
                @click="openParticipantsTabs(order)"
              >
                <i class="icon-user"></i>
                <span
                  v-if="
                    order.orderGroup &&
                    order.orderGroup.orders &&
                    order.orderGroup.orders.length > 1
                  "
                >
                  {{ order.orderGroup.orders.length }}
                </span>
              </div>
            </div>
          </td>
        </template>
        <td>
          <a @click="(e) => goToOrder(e, order)" href="#">{{
            formatPack(order.session) || "#"
          }}</a>
        </td>
        <td v-if="state">{{ orderState(order) }}</td>
        <td v-if="order.free">0€</td>
        <td v-else>{{ formatPrice(order.price) }}</td>
        <td>{{ order.concept }}</td>
        <td v-if="showInvoiceFile">
          <a
            @click="
              downloadSecuredFile(`uploads/invoice/${order.invoice.pdf}.pdf`)
            "
            v-if="order.invoice && order.invoice.pdf"
            v-on:click.stop
          >
            {{ order.invoice.filename }}
          </a>
        </td>
        <td v-if="!invoice && !client">
          <div class="d-flex justify-content-center">
            <div class="text-primary" @click="openParticipantsTabs(order)">
              <i class="icon-user"></i>
              <span
                v-if="
                  order.orderGroup &&
                  order.orderGroup.orders &&
                  order.orderGroup.orders.length > 1
                "
              >
                {{ order.orderGroup.orders.length }}
              </span>
            </div>
          </div>
        </td>
        <td v-if="showFrequency">
          <template v-if="frequency(order.sessions)">
            {{ frequency(order.sessions).toFixed(2) }}
          </template>
        </td>
        <td v-if="showIntervenants">
          <span v-for="(name, index) in orderCoachNames(order)" :key="index"
            >{{ name }}<br
          /></span>
        </td>
        <td>
          <template v-for="(rel, index) of order.commercials">
            <CommercialRel :key="`com-${index}`" :relation="rel" />
          </template>
        </td>
        <td>
          <template v-for="(rel, index) of order.coachManagers">
            <CoachManagerRel :key="`coach-manager-${index}`" :relation="rel" />
          </template>
        </td>
        <td v-if="notes">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            @click="showNotebook(order)"
            v-tooltip="concatedNotes(order)"
          >
            <i class="fa fa-commenting"></i>
            ({{ order.notebook ? order.notebook.length : 0 }}) Consulter
          </button>
        </td>
        <td v-if="status">
          <span class="badge badge-default" v-if="order.free">Offerte</span>
          <span class="badge badge-success" v-else-if="order.status === 'payed'"
            >Payée</span
          >
          <span class="badge badge-info" v-else-if="order.status === 'refunded'"
            >Avoir</span
          >

          <span
            class="badge badge-success"
            v-else-if="order.status === 'unpaid'"
            >Impayé (traité)</span
          >

          <span
            class="badge badge-danger"
            v-else-if="order.status === 'pending'"
            >En attente du paiement</span
          >
          <span
            class="badge badge-warning"
            v-else-if="order.status === 'partial'"
            >Paiement partiel</span
          ><br />
          <button
            v-if="order.status === 'partial' && order.payments"
            type="button"
            class="btn btn-outline-warning btn-sm"
          >
            <strong>Reste à régler : {{ amountDiff(order) }}</strong>
          </button>
        </td>
        <td v-if="notes">
          <NoteModal
            :notes="order.label"
            :key="order.id"
            @showComment="$emit('showNotes', order)"
            permission="USERS_READ"
          />
        </td>
        <td>
          <PaymentsHasTroubles :order="order" />
        </td>
        <td v-if="invoice">
          <a
            @click="
              downloadSecuredFile(`uploads/invoice/${order.invoice.pdf}.pdf`)
            "
            class="btn btn-sm btn-outline-primary"
            v-if="order.invoice && order.invoice.pdf"
          >
            <i class="icon-cloud-download"></i> Télécharger
          </a>
        </td>
      </tr>
    </tbody>

    <ModalNotebook
      :show="modalNotebook.show"
      :notebook="modalNotebook.notebook"
      :client="modalNotebook.client"
      :order="modalNotebook.order"
      @close="closeNotebook"
      @added="notebookNoteAdded"
      @updated="notebookNoteUpdated"
      @deleted="notebookNoteDeleted"
    />
  </table>
</template>

<script>
import { mapState } from "vuex";
import FrequencyMixin from "../mixin/FrequencyMixin";
import PaymentsHasTroubles from "../components/PaymentsHasTrouble.vue";
import NoteModal from "./form/NoteModal";
import CommercialRel from "../components/CommercialRel";
import CoachManagerRel from "../components/CoachManagerRel";
import DownloadFile from "../mixin/DownloadFile";
import ModalNotebook from "./ModalNotebook";

export default {
  components: {
    PaymentsHasTroubles,
    NoteModal,
    CommercialRel,
    CoachManagerRel,
    ModalNotebook,
  },
  mixins: [FrequencyMixin, DownloadFile],
  props: {
    orders: {
      type: Array,
      default: null,
    },
    client: {
      type: Boolean,
      default: true,
    },
    clients: {
      type: Object,
      default: null,
    },
    invoice: {
      type: Boolean,
      default: false,
    },
    showInvoiceFile: {
      type: Boolean,
      default: false,
    },
    showFrequency: {
      type: Boolean,
      default: false,
    },
    showIntervenants: {
      tyoe: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
      default: true,
    },
    notes: {
      type: Boolean,
      default: true,
    },
    state: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modalNotebook: {
        show: false,
        notebook: [],
        order: undefined,
        client: {},
      },
    };
  },

  computed: {
    ...mapState({
      concepts: (state) => state.concepts,
    }),
  },

  methods: {
    orderCoachNames(order) {
      let sessions = order.sessions || [];
      let names = sessions
        .filter((s) => s.scheduledBy)
        .map(
          (s) =>
            s.scheduledBy.coach.firstName + " " + s.scheduledBy.coach.lastName
        );
      names = [...new Set(names)];
      return names;
    },

    amountDiff(order) {
      let payed = 0;

      for (let payment of order.payments) {
        if (payment.captured) {
          payed += payment.price;
        }
      }

      return this.formatPrice(order.price - payed);
    },

    orderState(order) {
      if (order.sessions) {
        return (
          order.sessions.filter((s) => s.completed).length + "/" + order.session
        );
      }
    },

    orderHasFailedPayment(order) {
      if (!order.payments) {
        return false;
      }

      return !!order.payments.find((p) => p.failed && !p.captured);
    },

    goToOrder(e, order) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: order.id,
        },
      });

      window.open(route.href, "_blank");
    },

    goToClient(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });
      window.open(route.href, "_blank");
    },

    openParticipantsTabs(order) {
      let ids = this.client ? [order.refClient.id] : [];

      if (order.orderGroup?.orders?.length > 0) {
        ids = [];
        let participantsOrders = order.orderGroup.orders;

        if (!this.client) {
          participantsOrders = participantsOrders.filter(
            (participantOrder) => participantOrder.id !== order.id
          );
        }

        for (const participantOrder of participantsOrders) {
          ids.push(participantOrder.refClient.id);
        }
      }

      ids.forEach((id) => {
        this.goToClient(id);
      });
    },

    showNotebook(order) {
      this.modalNotebook.order = order;
      this.modalNotebook.client = order.refClient || {};
      this.modalNotebook.notebook = order.notebook || [];
      this.modalNotebook.show = true;
    },

    closeNotebook() {
      this.modalNotebook.show = false;
      this.modalNotebook.order = null;
      this.modalNotebook.client = {};
      this.modalNotebook.notebook = [];
    },

    notebookNoteAdded(note) {
      this.$emit("notebookNoteAdded", {
        orderId: this.modalNotebook.order.id,
        note,
      });
    },

    notebookNoteUpdated(note) {
      this.$emit("notebookNoteUpdated", {
        orderId: this.modalNotebook.order.id,
        note,
      });
    },

    notebookNoteDeleted(noteId) {
      this.$emit("notebookNoteDeleted", {
        orderId: this.modalNotebook.order.id,
        noteId,
      });
    },

    concatedNotes(order) {
      return (
        order.notebook
          .filter((n) => n.orderId === order.id)
          .map((note) => `${note.title}: ${note.note}`)
          .join(" | ") || "Aucune note pour cette commande"
      );
    },
  },
};
</script>
