<template>
  <modal
    title="Modifier les informations de facturation"
    v-model="visible"
    cancelText="Fermer"
    okText="Enregistrer"
    effect="fade/zoom"
    @ok="handleEditBilling"
    @cancel="close"
  >
    <div class="card card-inverse card-warning text-center">
      <div class="card-block">
        <blockquote class="card-blockquote">
          Les informations seront utilisées pour la création de la facture.
        </blockquote>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label>Nom</label>
        <div class="input-group">
          <input
            v-model="form.lastName"
            type="text"
            class="form-control"
            v-if="checkPermission"
          />
          <span v-else>{{ form.lastName }}</span>
        </div>
      </div>

      <div class="form-group col-sm-6">
        <label>Prénom</label>
        <div class="input-group">
          <input
            v-model="form.firstName"
            type="text"
            class="form-control"
            v-if="checkPermission"
          />
          <span v-else>{{ form.firstName }}</span>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Société</label>
      <div class="input-group">
        <textarea
          v-model="form.company"
          type="text"
          class="form-control"
          v-if="checkPermission"
        />
        <span v-else>{{ form.company }}</span>
      </div>
    </div>

    <div class="form-group">
      <label>Service</label>
      <div class="input-group">
        <input
          v-model="form.service"
          type="text"
          class="form-control"
          v-if="checkPermission"
        />
        <span v-else>{{ form.service }}</span>
      </div>
    </div>

    <div class="form-group">
      <label>Adresse</label>
      <div class="input-group">
        <input
          v-model="form.address"
          type="text"
          class="form-control"
          v-if="checkPermission"
        />
        <span v-else>{{ form.address }}</span>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label>Code postal</label>
        <div class="input-group">
          <input
            v-model="form.zipcode"
            type="text"
            class="form-control"
            v-if="checkPermission"
          />
          <span v-else>{{ form.zipcode }}</span>
        </div>
      </div>

      <div class="form-group col-sm-6">
        <label>Ville</label>
        <div class="input-group">
          <input
            v-model="form.city"
            type="text"
            class="form-control"
            v-if="checkPermission"
          />
          <span v-else>{{ form.city }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label>Email</label>
        <div class="input-group">
          <input
            v-model="form.email"
            type="text"
            class="form-control"
            v-if="checkPermission"
          />
          <span v-else>{{ form.email }}</span>
        </div>
      </div>

      <div class="form-group col-sm-12">
        <label>Téléphone</label>
        <PhoneNumberInput
          v-if="checkPermission"
          :phone-number="form.phone"
          :phone-code="form.phoneCode"
          copy
          @changeNumber="changeBillingPhone"
          @changeCode="changeBillingPhoneCode"
        />

        <div v-else>
          <span v-if="form.phoneCode">({{ form.phoneCode }}) </span
          >{{ form.phone }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label>Description (pré-rempli la création de commande)</label>

        <button
          v-if="client.isB2B"
          class="btn btn-primary btn-sm float-right"
          style="margin-bottom: 5px"
          @click="openModalDescription({}, false)"
        >
          <i class="fa fa-plus mr-05"></i>Ajouter
        </button>

        <div class="input-group d-flex flex-column">
          <v-select
            v-if="client.isB2B"
            style="margin-bottom: 5px"
            :options="orderDescriptions"
            v-model="form.label"
            @input="updateBillingDescriptionForB2B"
          >
            <template v-slot:option="option">
              <div class="d-flex justify-content-between">
                <span>{{ option.label }}</span>
                <i
                  class="fa fa-edit"
                  @click.stop="openModalDescription(option)"
                ></i>
              </div>
            </template>
          </v-select>

          <textarea
            v-model="form.description"
            type="text"
            class="form-control w-100"
            v-if="checkPermission"
          ></textarea>

          <span v-else>{{ form.description }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <div class="commercial-toggle">
          <label
            class="switch switch-sm switch-text switch-info mb-0"
            v-if="checkPermission"
          >
            <input
              type="checkbox"
              class="switch-input"
              v-model="form.showCommercial"
            />

            <span class="switch-label" data-on="On" data-off="Off"></span>
            <span class="switch-handle"></span>
          </label>

          <span class="txt-sap">
            {{ form.showCommercial ? "Afficher" : "Ne pas afficher" }}
            les informations du commercial dans la facture
          </span>
        </div>
      </div>
    </div>

    <ManagementOrderDescriptions
      :show="showModalDescription"
      :description="orderDescription"
      :clientId="client.id"
      :isUpdate="isOrderDescriptionUpdate"
      @close="closeModalDescription"
      @editOrderDescription="editOrderDescription"
      @deleteOrderDescription="deleteOrderDescription"
      @addOrderDescription="addOrderDescription"
    />
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import PhoneNumberInput from "../PhoneNumberInput.vue";
import ManagementOrderDescriptions from "./ManagementOrderDescriptions.vue";

export default {
  components: {
    Modal,
    PhoneNumberInput,
    ManagementOrderDescriptions,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      visible: false,
      showModalDescription: false,
      orderDescription: {},
      isOrderDescriptionUpdate: true,

      form: {
        lastName: "",
        firstName: "",
        company: "",
        service: "",
        address: "",
        zipcode: "",
        city: "",
        email: "",
        phone: "",
        phoneCode: "",
        label: "",
        description: "",
        showCommercial: false,
      },
    };
  },

  created() {
    this.form = this.client.billing;
  },

  watch: {
    show(val) {
      this.visible = val;
    },
  },

  computed: {
    orderDescriptions() {
      const orderDescriptionsSorted = this.client.orderDescriptions;

      orderDescriptionsSorted.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }

        if (a.label > b.label) {
          return 1;
        }

        return 0;
      });

      return orderDescriptionsSorted;
    },
  },

  methods: {
    openModalDescription(orderDescription, isUpdate = true) {
      this.orderDescription = orderDescription;
      this.isOrderDescriptionUpdate = isUpdate;
      this.showModalDescription = true;
    },

    addOrderDescription(template) {
      this.$emit("addOrderDescription", template);
    },

    deleteOrderDescription(descriptionId) {
      this.$emit("deleteOrderDescription", descriptionId);
      this.updateBillingDescriptionForB2B({ description: "", label: "" });
    },

    editOrderDescription(template) {
      this.$emit("editOrderDescription", template);
      this.updateBillingDescriptionForB2B(template);
    },

    updateBillingDescriptionForB2B(value) {
      const label = value ? value.label : "";
      const description = value ? value.description : "";

      this.form = {
        ...this.form,
        label,
        description,
      };
    },

    close() {
      this.$emit("close");
    },

    closeModalDescription() {
      this.showModalDescription = false;
    },

    async handleEditBilling() {
      await this.$api.put("/clients", {
        email: this.client.email,
        billing: this.form,
      });

      this.close();
      window.location.reload();
    },

    changeBillingPhoneCode(phoneCode) {
      this.clientData.billing = {
        ...this.clientData.billing,
        phoneCode,
      };
    },

    changeBillingPhone(phone) {
      this.clientData.billing = {
        ...this.clientData.billing,
        phone,
      };
    },

    checkPermission() {
      return (
        this.hasPermission(this.$store.state.user, "CLIENTS_WRITE") &&
        !this.client.deletedAt
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.fa-edit {
  color: #409eff;
  background-color: white;
  padding: 5px 5px 5px 7px;
  border-radius: 50px;
}
</style>
