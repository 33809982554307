<template>
  <div class="wrapper">
    <router-view></router-view>
    <loader :isVisible="isLoading" />
    <div
      class="animated fadeIn"
      v-if="['companies-list'].indexOf($route.name) > -1"
    >
      <CompaniesListFilters
        @apply-filters="applyFilters"
        :commercials="commercials"
      />

      <div class="card">
        <div class="card-header">
          <span><i class="icon-people"></i> Entreprise</span>
          <button
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="addModal.show = true"
          >
            <i class="icon-plus"></i>&nbsp; Ajouter
          </button>
        </div>
        <div class="card-block display-tab">
          <el-table
            :data="companies"
            :default-sort="{ prop: 'name', order: 'ascending' }"
          >
            <el-table-column type="index" label="#" fixed sortable>
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="Nom" prop="name" sortable>
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column label="statut">
              <template slot-scope="scope">
                <el-select
                  :value="scope.row.statut"
                  size="mini"
                  @change="openStatusConfirm($event, scope.row.id)"
                  :disabled="
                    !hasPermission($store.state.user, 'COMPANIES_COMPANY_WRITE')
                  "
                  class="w-100"
                  style="border-radius: 5px"
                  :class="`status-color__${scope.row.statut}`"
                >
                  <el-option
                    v-for="status in companyStatuts"
                    :key="status.label"
                    :value="status.value"
                    :label="status.label"
                    :class="`status-color__${status.value}`"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Clients" align="center">
              <template slot-scope="scope">
                <a
                  href="#"
                  @click="showClients(scope.row.clients, scope.row.name)"
                >
                  <i class="fa fa-user"></i> {{ scope.row.clients.length }}</a
                >
              </template>
            </el-table-column>
            <el-table-column label="Note">
              <template slot-scope="scope">
                <NoteModal
                  style="margin-top: 1px"
                  :notes="scope.row.note"
                  :key="scope.row.id"
                  v-if="
                    hasPermission($store.state.user, 'COMPANIES_COMPANY_WRITE')
                  "
                  @showComment="showComment(scope.row)"
                />
                <span v-else>
                  {{ scope.row.prospect.note }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="Commercial">
              <template slot-scope="scope">
                <template v-for="(rel, index) of scope.row.commercials">
                  <CommercialRel :key="`com-${index}`" :relation="rel" />
                </template>
              </template>
            </el-table-column>

            <el-table-column
              label="Suppression"
              align="right"
              v-if="isAdmin($store.state.user)"
            >
              <template slot-scope="scope">
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteEntreprise(scope.row.id)"
                >
                  <i class="fa fa-trash-o"></i>
                </button>
              </template>
            </el-table-column>
          </el-table>
          <paginate
            v-model="currentPage"
            :page-count="pageCount"
            :click-handler="handleCurrentChange"
            prev-text="Précédent"
            next-text="Suivant"
            container-class="pagination"
            style="padding: 1.25rem 0"
          ></paginate>
        </div>
      </div>
    </div>
    <modal
      title="Ajouter une société"
      v-model="addModal.show"
      effect="fade/zoom"
      :backdrop="false"
      cancelText="Fermer"
      okText="Créer"
      @ok="createCompany()"
    >
      <div class="modal-body">
        <form autocomplete="off">
          <div class="form-group">
            <label>Nom</label>
            <el-input
              v-model="addModal.form.name"
              size="mini"
              v-if="hasPermission($store.state.user, 'COMPANIES_COMPANY_WRITE')"
            />
          </div>
          <div
            class="alert alert-danger"
            role="alert"
            v-if="addModal.formError.emptyName"
          >
            Le nom est obligatoire
          </div>

          <div class="form-group">
            <label>Rechercher une fiche client</label>
            <v-select
              v-model="addModal.form.client"
              :options="clients"
              @search="searchClients"
            ></v-select>
          </div>
          <div class="form-group">
            <label>Commercial</label>
            <multiselect
              placeholder="Choisir un commercial"
              v-model="addModal.form.commercial"
              :options="commercials"
              label="label"
              track-by="label"
              class="custom-multiselect"
            >
            </multiselect>
          </div>
        </form>
      </div>
    </modal>
    <modal
      title="Note"
      v-model="modalUpdateNote.display"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateNote()"
      @cancel="modalUpdateNote.display = false"
      effect="fade/zoom"
    >
      <textarea
        v-model="modalUpdateNote.note"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
    <modal
      title="Fiche(s) client"
      v-model="clientModal.show"
      @cancel="clientModal.show = false"
      effect="fade/zoom"
      large
    >
      <el-table :data="clientModal.clients" max-height="550">
        <el-table-column label="Nom">
          <template slot-scope="scope">
            <a href="#" @click="goToClient(scope.row.id)">
              <span v-if="scope.row.firstName && scope.row.lastName"
                >{{ scope.row.firstName }} {{ scope.row.lastName }}</span
              >

              <span v-else>{{ scope.row.email }}</span>
            </a>
          </template>
        </el-table-column>

        <el-table-column label="Société">
          <template slot-scope="scope">
            {{ scope.row.company }}
          </template>
        </el-table-column>

        <el-table-column label="Statut">
          <template slot-scope="scope">
            <StatusRel v-if="scope.row.status" :status="scope.row.status" />

            <p v-if="scope.row.lostReason">
              {{ lostReasonsLabel(scope.row.lostReason) }}
            </p>
          </template>
        </el-table-column>

        <el-table-column label="Facturation" sortable prop="isB2BBilling">
          <template slot-scope="scope">
            {{ scope.row.isB2BBilling ? "Oui" : "Non" }}
          </template>
        </el-table-column>
      </el-table>
      <div slot="modal-footer" class="modal-footer d-flex justify-end">
        <button
          type="button"
          class="btn btn-secondary"
          @click="clientModal.show = false"
        >
          Fermer
        </button>
      </div>
    </modal>

    <CompanyDetailsModal
      :statusConfirmModal="statusConfirmModal"
      @update="editCompanyStatut"
    />
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import Loader from "../components/Loader";
import { mapState, mapGetters } from "vuex";
import Modal from "vue-strap/src/Modal";
import debounce from "lodash.debounce";
import StatusRel from "../components/StatusRel";
import CommercialRel from "../components/CommercialRel";
import CompaniesListFilters from "../components/CompaniesListFilters";
import Multiselect from "vue-multiselect";
import NoteModal from "../components/form/NoteModal";
import CompanyDetailsModal from "../components/company/CompanyDetailsModal";

export default {
  components: {
    Loader,
    Paginate,
    Modal,
    CompaniesListFilters,
    StatusRel,
    CommercialRel,
    Multiselect,
    NoteModal,
    CompanyDetailsModal,
  },

  data() {
    return {
      isLoading: false,
      activeTab: 0,
      currentPage: 1,
      limit: 15,
      companies: [],
      totals: 0,
      filters: {
        statuts: [],
        companyName: "",
        commercials: [],
      },
      addModal: {
        show: false,
        form: {
          name: "",
          client: "",
          commercial: {},
        },
        formError: {
          emptyName: false,
          emptyClient: false,
        },
      },
      clientModal: {
        show: false,
        clients: [],
        companyName: "",
      },
      modalUpdateNote: {
        display: false,
        note: "",
      },
      statusConfirmModal: {
        show: false,
        statut: null,
        companyId: null,
      },
      clients: [],
      commercials: [],
    };
  },

  async created() {
    let activeTab = sessionStorage.getItem("AcquisitionTab");

    if (activeTab) {
      this.activeTab = parseInt(activeTab);
    }

    await this.loadCompanies();
    await this.loadCommercials();
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),

    ...mapGetters({
      lostReasons: "getLostReasons",
      companyLost: "getCompanyLost",
      companyStatuts: "getCompanyStatuts",
    }),

    globalLostReasons() {
      return this.companyLost.concat(this.lostReasons);
    },

    pageCount() {
      if (!this.totals) {
        return 0;
      }

      return Math.ceil(this.totals / this.limit);
    },
  },

  methods: {
    async loadCompanies() {
      try {
        const { data } = await this.$api.get("/companies", {
          params: {
            limit: this.limit,
            offset: (this.currentPage - 1) * this.limit,
            companyName: this.filters.companyName,
            statuts: this.filters.statuts,
            commercials: this.filters.commercials?.map((c) => c.value),
          },
        });

        this.companies = data.data;
        this.totals = data.totals;
        this.isLoading = false;
      } catch (e) {
        this.loadingError = "Impossible de charger les acquisitions";
      } finally {
        this.isLoading = false;
      }
    },

    async handleCurrentChange(val) {
      this.currentPage = val;

      await this.loadCompanies();
    },

    goToClient(clientId) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id: clientId,
        },
      });

      window.open(route.href, "_blank");
    },

    goToCompany(companyId) {
      const route = this.$router.resolve({
        name: "company-details",
        params: {
          id: companyId,
        },
      });

      window.open(route.href, "_blank");
    },

    lostReasonsLabel(lostReasonCode) {
      const lostReason = this.globalLostReasons.find(
        (reason) => reason.value === lostReasonCode
      );

      return lostReason ? lostReason.label : null;
    },

    statusLabel(statusCode) {
      const status = this.companyStatuts.find(
        (statut) => statut.value === statusCode
      );

      return status ? status.label : null;
    },

    applyFilters(filters) {
      this.filters = filters;
      this.currentPage = 1;
      this.loadCompanies();
    },

    async searchClients(search, loading) {
      if (search !== "") {
        await this.fetchClients(search, loading, this);
      }
    },

    fetchClients: debounce(async (search, loading, vm) => {
      if (!search || !search.lentgh > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(`/clients/filter`, {
        params: {
          q: search,
        },
      });

      vm.clients = data.map((c) => ({
        label: `${c.company && "[" + c.company + "]"} ${c.name} (${c.email})`,
        value: c.email,
        usedEmail: c.usedEmail,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    async createCompany() {
      if (this.checkFormErrors() === true) {
        return;
      }

      await this.$api
        .post("/companies", {
          name: this.addModal.form.name,
          commercialEmail: this.addModal.form.commercial.value,
          clientEmail: this.addModal.form.client.value,
        })
        .then(() => {
          this.addModal.form = {};
          this.addModal.show = false;

          this.loadCompanies();
        });
    },

    checkFormErrors() {
      const errors = [];

      this.addModal.formError.emptyName = false;
      this.addModal.formError.emptyClient = false;

      if (!this.addModal.form.name) {
        this.addModal.formError.emptyName = true;
        errors.push("name");
      }

      if (!this.addModal.form.client) {
        this.addModal.formError.emptyClient = true;
        errors.push("client");
      }

      return errors.length > 0;
    },

    async loadCommercials() {
      const { data } = await this.$api.get("/users/commercials/search", {
        params: { b2b: true },
      });

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));
    },

    showComment(company) {
      this.modalUpdateNote.display = true;
      this.modalUpdateNote.id = company.id;
      this.modalUpdateNote.note = company.note;
    },

    openStatusConfirm(value, companyId) {
      if (value) {
        this.statusConfirmModal.show = true;
        this.statusConfirmModal.statut = value || null;
        this.statusConfirmModal.companyId = companyId;
      }
    },

    async editCompanyStatut({ statut, lostReason }) {
      if (lostReason && lostReason.value) {
        lostReason = lostReason.value;
      }

      await this.$api.put(`/companies/${this.statusConfirmModal.companyId}`, {
        statut,
        lostReason,
      });

      this.companies = this.companies.map((company) => {
        if (company.id === this.statusConfirmModal.companyId) {
          company.statut = statut;
        }

        return company;
      });
    },

    async updateNote() {
      await this.$api
        .put(`/companies/${this.modalUpdateNote.id}`, {
          note: this.modalUpdateNote.note,
        })
        .then(() => {
          this.modalUpdateNote.display = false;
          this.loadCompanies();
        });
    },

    async showClients(clients, companyName) {
      this.clientModal.companyName = companyName;
      this.clientModal.clients = clients;
      this.clientModal.show = true;
    },

    async deleteEntreprise(id) {
      this.$confirm({
        message:
          "Voulez-vous vraiment supprimer le ban pour ce client ? Cette action est irréversible.",
        onConfirm: async () => {
          try {
            await this.$api.delete(`/companies/${id}`);

            this.loadCompanies();
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },
  },
};
</script>
<style type="text/css" scoped>
.checkboxes {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
}

.type {
  display: flex;
  align-items: center;
}

.type label {
  margin: 0;
}

.type input {
  height: 14px;
  width: 30px;
}
</style>
