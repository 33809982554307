<template>
  <div class="wrapper" style="position: absolute; top: 0">
    <Loader :is-visible="loading" />

    <div v-if="error">
      <div class="card card-inverse card-danger text-center">
        <div class="card-block">
          <blockquote class="card-blockquote">
            Une erreur est survenue lors du chargement de la fiche client...
          </blockquote>
        </div>
      </div>
    </div>

    <div v-if="client" class="animated fadeIn pr-2">
      <ClientDetailsHeader :client="client" :show-live="isLiveClient" />

      <div v-if="client.status === 'suspended'">
        <div class="card card-inverse card-danger text-center">
          <div class="card-block">
            <blockquote class="card-blockquote">
              Le client est bloqué, il ne peut plus accéder à son compte.
            </blockquote>
          </div>
        </div>
      </div>

      <ClientDetailsSection id="global" title="Général">
        <ClientGlobalTab
          :client="client"
          :groups="groups"
          :concepts="concepts"
          :commercials="commercials"
          :disabled-dates="disabledDates"
          @edit="editClient"
          @editNotes="handleEditNotes"
          @reload="refreshClient"
          @editOrderDescription="updateOrderDescription"
          @deleteOrderDescription="deleteOrderDescription"
          @addOrderDescription="addOrderDescription"
        />
      </ClientDetailsSection>

      <ClientDetailsSection id="notebook" title="Notebook">
        <NotebookCard
          :data="notebook"
          :client="client"
          @noteAdded="notebookNoteAdded"
          @noteUpdated="notebookNoteUpdated"
          @noteDeleted="notebookNoteDeleted"
        />
      </ClientDetailsSection>

      <ClientDetailsSection id="orders" title="Commandes">
        <ClientOrdersTab
          :client="client"
          :notebook="notebook"
          @orderLoading="orderLoading"
          @newOrder="refreshClient(true)"
          @notebookNoteAdded="notebookNoteAdded"
          @notebookNoteUpdated="notebookNoteUpdated"
          @notebookNoteDeleted="notebookNoteDeleted"
        />
      </ClientDetailsSection>

      <ClientDetailsSection id="payments" title="Paiements">
        <ClientPaymentsTab :client="client" @reload="refreshClient(true)" />
      </ClientDetailsSection>

      <ClientDetailsSection id="tracking" title="Suivi">
        <ClientTrackingTab :client="client" @edit="editClient" />
      </ClientDetailsSection>

      <ClientDetailsSection id="sponsorships" title="Parrainages">
        <ClientSponsorshipsTab :client="client" />
      </ClientDetailsSection>

      <ClientDetailsSection
        id="saps"
        title="Attestation Service à la personne (SAP)"
      >
        <ClientSapsTab :client="client" />
      </ClientDetailsSection>

      <ClientDetailsSection v-if="isLiveClient" id="live" title="Live">
        <ClientLiveTab
          :client="client"
          :current-subscription="currentSubscription"
          :subscriptions="subscriptions"
          :bookings-live="bookingsLive"
          :bookings-small="bookingsSmall"
          :bookings-live-pagination="bookingsLivePagination"
          :bookings-small-pagination="bookingsSmallPagination"
          @changePageLive="changeBookingsLivePage"
          @changePageSmall="changeBookingsSmallPage"
        />
      </ClientDetailsSection>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import Loader from "../components/Loader";

import ClientDetailsHeader from "../components/ClientDetailsHeader";
import ClientDetailsSection from "../components/ClientDetailsSection";
import ClientGlobalTab from "../components/ClientGlobalTab";
import ClientOrdersTab from "../components/ClientOrdersTab";
import ClientPaymentsTab from "../components/ClientPaymentsTab";
import ClientTrackingTab from "../components/ClientTrackingTab";
import ClientSponsorshipsTab from "../components/ClientSponsorshipsTab";
import ClientLiveTab from "../components/ClientLiveTab";
import ClientSapsTab from "../components/ClientSapsTab";
import NotebookCard from "../components/NotebookCard";

export default {
  components: {
    Loader,
    ClientDetailsHeader,
    ClientDetailsSection,
    ClientGlobalTab,
    ClientOrdersTab,
    ClientPaymentsTab,
    ClientTrackingTab,
    ClientSponsorshipsTab,
    ClientLiveTab,
    ClientSapsTab,
    NotebookCard,
  },

  data() {
    return {
      loading: false,
      error: false,

      client: null,

      // notebook
      notebook: [],

      // live
      currentSubscription: null,
      subscriptions: [],
      bookingsLive: [],
      bookingsLivePagination: {
        page: 1,
        limit: 20,
        total: 0,
      },
      bookingsSmall: [],
      bookingsSmallPagination: {
        page: 1,
        limit: 20,
        total: 0,
      },

      // forms options
      commercials: [],
      concepts: [],
      groups: [],
      disabledDates: {
        to: moment().toDate(),
        from: moment().toDate(),
      },
    };
  },

  computed: {
    isLiveClient() {
      return this.client?.concepts.some(
        ({ name }) =>
          name === "Just Coaching Live" || name === "Just Coaching Live Solo"
      );
    },
  },

  watch: {
    $route(route) {
      const { id } = route.params;

      if (id !== this.client?.id) {
        this.client = null;

        this.loadClient();
      }
    },
  },

  created() {
    this.loadClient();
    this.getCommercials();
    this.getConcepts();
    this.getGroups();
  },

  methods: {
    updateOrderDescription(template) {
      this.client.orderDescriptions = this.client.orderDescriptions.map(
        (description) =>
          description.id === template.id ? template : description
      );
    },

    deleteOrderDescription(id) {
      this.client.orderDescriptions = this.client.orderDescriptions.filter(
        (description) => description.id !== id
      );
    },

    addOrderDescription(template) {
      this.client.orderDescriptions = [
        template,
        ...this.client.orderDescriptions,
      ];
    },

    orderLoading(isLoading) {
      this.loading = isLoading;
    },

    async loadClient() {
      try {
        this.error = false;
        this.loading = true;

        await this.getClient();

        if (!this.client) throw new Error("Loading client error");

        if (this.isLiveClient) {
          await this.getSubscriptions();
          await this.getBookingsLive(0, true);
          await this.getBookingsSmall(0, true);
        }

        if (this.isAdmin(this.$store.state.user)) {
          this.disabledDates = {
            to: moment(this.client.createdAt)
              .endOf("day")
              .subtract(1, "days")
              .toDate(),
            from: moment().endOf("day").toDate(),
          };
        }
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },

    async getClient() {
      try {
        const { id } = this.$route.params;

        const { data } = await this.$api.get(`/clients/${id}`);

        this.getNotebook(data.email);

        this.client = data;
        this.client.billing = this.client.billing || {};
        this.client.concepts = this.client.concepts || [];
        this.client.contactEmail =
          this.client.contactEmail || this.client.email;

        if (this.client.commercials && !this.client.notes) {
          // Update note
          let notes = "";

          this.client.commercials.forEach(({ commercial }) => {
            if (commercial?.commercial) {
              notes += `${commercial.commercial.pseudo} : ${commercial.commercial.mobile} - `;
            }
          });

          this.client.notes = notes;

          this.handleEditNotes();
        }
      } catch (e) {
        this.client = null;
      }
    },

    async getCommercials() {
      try {
        const { data } = await this.$api.get("/users/commercials/search");

        this.commercials = data.map((user) => ({
          label: user.commercial.pseudo,
          value: user.email,
          mobile: user.commercial.mobile,
          color: user.commercial.color,
        }));
      } catch (e) {
        this.commercials = [];
      }
    },

    async getGroups() {
      try {
        const { data } = await this.$api.get("/groups");

        this.groups = data;
      } catch (e) {
        this.groups = [];
      }
    },

    async getConcepts() {
      try {
        const { data } = await this.$api.get("/concepts");

        this.concepts = data;
      } catch (e) {
        this.concepts = [];
      }
    },

    async getSubscriptions() {
      try {
        const { id } = this.$route.params;

        const { data } = await this.$api.get(`/clients/subscriptions/${id}`);

        const { current, subscriptions } = data;

        this.currentSubscription = current;
        this.subscriptions = subscriptions;
      } catch (e) {
        this.subscriptions = [];
        this.currentSubscription = null;
      }
    },

    async getBookingsLive(offset, count) {
      try {
        const { id } = this.$route.params;
        const { email } = this.client;

        if (email) {
          const { data } = await this.$api.get(`/clients/${id}/bookings`, {
            params: {
              type: "live",
              offset,
              limit: this.bookingsLivePagination.limit,
              currentTotal: this.bookingsLivePagination.total,
              count,
            },
          });

          const { bookings, total } = data;

          this.bookingsLive = bookings;
          this.bookingsLivePagination.total = total;
        }
      } catch (e) {
        this.bookingsLive = [];
      }
    },

    async getBookingsSmall(offset, count) {
      try {
        const { id } = this.$route.params;
        const { email } = this.client;

        if (email) {
          const { data } = await this.$api.get(`/clients/${id}/bookings`, {
            params: {
              type: "small",
              offset,
              limit: this.bookingsSmallPagination.limit,
              currentTotal: this.bookingsSmallPagination.total,
              count,
            },
          });

          const { bookings, total } = data;

          this.bookingsSmall = bookings;
          this.bookingsSmallPagination.total = total;
        }
      } catch (e) {
        this.bookingsSmall = [];
      }
    },

    async getNotebook(clientEmail) {
      try {
        const { data } = await this.$api.get("/notebook/client", {
          params: {
            email: clientEmail,
          },
        });

        this.notebook = data;
      } catch (e) {
        this.notebook = [];
      }
    },

    changeBookingsLivePage(page) {
      this.bookingsLivePagination.page = page;

      const { limit } = this.bookingsLivePagination;
      const offset = page * limit - limit;

      this.getBookingsLive(offset);
    },

    changeBookingsSmallPage(page) {
      this.bookingsSmallPagination.page = page;

      const { limit } = this.bookingsSmallPagination;
      const offset = page * limit - limit;

      this.getBookingsSmall(offset);
    },

    handleEditNotes() {
      this.$api.put("/clients", {
        email: this.client.email,
        notes: this.client.notes,
        managerNotes: this.client.managerNotes,
        coachNotes: this.client.coachNotes,
      });
    },

    async editClient({ name, value }) {
      const data = {
        email: this.client.email,
      };
      data[name] = value;

      try {
        await this.$api.put("/clients", data);

        if (name === "marketEstablishment" && value && this.client.isB2B) {
          await this.$api.post("/clients/commercial", {
            email: this.client.email,
            commercial: "marche@justcoaching.fr",
          });

          window.location.reload();
        }
      } catch (e) {
        if (name === "birthDate") {
          this.client.birthDate = null;
        }

        this.$errorToast("La mise à jour du client a échoué");
      } finally {
        if (name === "trustpilotNotice" && value) {
          this.client.trustpilotNoticeSince = moment().format();
        } else if (name === "trustpilotNotice" && !value) {
          this.client.trustpilotNoticeSince = null;
        }

        if (name === "googleNotice" && value) {
          this.client.googleNoticeSince = moment().format();
        } else if (name === "googleNotice" && !value) {
          this.client.googleNoticeSince = null;
        }
      }
    },

    async refreshClient(clear) {
      this.error = false;
      this.loading = true;

      if (clear) this.client = null;

      await this.getClient();

      if (!this.client) this.error = true;

      this.loading = false;
    },

    notebookNoteAdded(note) {
      this.notebook.unshift(note);
    },

    notebookNoteUpdated(note) {
      this.notebook = this.notebook.map((n) => (n.id === note.id ? note : n));
    },

    notebookNoteDeleted(noteId) {
      this.notebook = this.notebook.filter((note) => note.id !== noteId);
    },
  },
};
</script>

<style lang="scss">
.events-wrapper {
  background: #384042 !important;
  padding: 5px !important;
}

p.time {
  font-size: 12px !important;
  right: 20px !important;
}

h3.title a {
  color: #444 !important;
}

h2.date {
  display: none;
}

.modal-lg {
  max-width: 1100px;
}

/*[TODO] May we improved */
.payments-custom-wrapper {
  max-width: calc(100vw - 320px);
}

@media (max-width: 991px) {
  .payments-custom-wrapper {
    max-width: calc(100vw - 70px);
  }
}

.aside-menu-fixed.sidebar-hidden .payments-custom-wrapper {
  max-width: calc(100vw - 70px);
}

.overflow-auto {
  overflow: auto;
}

.client-details {
  & > .tab-content {
    border: none;
    background: transparent;
    & > .tab-pane {
      padding: 0;
    }
  }
}
</style>
