<template>
  <div>
    <div class="card">
      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-4">
            <label>Statut(s)</label>
            <multiselect
              v-model="filters.status"
              :options="statusOptions"
              label="label"
              track-by="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-4">
            <label>Département(s)</label>
            <multiselect
              v-model="filters.departments"
              :options="departmentsOptions"
              group-values="libs"
              group-label="groupeName"
              :group-select="true"
              track-by="label"
              label="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-4">
            <label>Société(s)</label>
            <multiselect
              v-model="filters.merchants"
              :options="merchantsOptions"
              label="label"
              track-by="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-4">
            <label>Concept(s)</label>
            <multiselect
              v-model="filters.concepts"
              :options="conceptOptions"
              label="label"
              track-by="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-4">
            <label>Prestation(s)</label>
            <multiselect
              v-model="filters.prestations"
              :options="prestationsOptions"
              group-values="libs"
              group-label="groupeName"
              :group-select="true"
              track-by="label"
              label="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-4">
            <label>Langue(s)</label>
            <multiselect
              v-model="filters.languages"
              :options="languagesOptions"
              group-values="libs"
              group-label="groupeName"
              :group-select="true"
              track-by="label"
              label="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-4">
            <label>Tag(s)</label>
            <multiselect
              v-model="filters.tags"
              :options="tagsOptions"
              group-values="libs"
              group-label="groupeName"
              :group-select="true"
              track-by="label"
              label="label"
              :multiple="true"
            >
            </multiselect>
          </div>
          <div class="form-group col-sm-4">
            <label>Genre(s)</label>
            <multiselect
              v-model="filters.genders"
              :options="gendersOptions"
              :multiple="true"
            >
            </multiselect>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-4">
            <button class="btn btn-default" @click="applyFilters">
              <i class="icon-eye"></i>&nbsp; Visualiser
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filters: [],
      conceptOptions: [],
      merchantsOptions: [],
      prestationsOptions: [],
      languagesOptions: [],
      tagsOptions: [],
      departmentsOptions: [],
      gendersOptions: ["Homme", "Femme"],
    };
  },

  async created() {
    this.filters = this.default;

    await this.getConcepts();
    await this.getMerchants();
    await this.loadSettings();
    await this.loadLanguages();
    await this.loadDepartments();
  },

  computed: {
    ...mapGetters({
      languages: "getLanguages",
      departments: "getDepartments",
      statusOptions: "getCoachStatus",
    }),
  },

  methods: {
    applyFilters() {
      this.$emit("update", { ...this.filters });
    },

    async getConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.conceptOptions = data.map((c) => {
        return { label: c.name, value: c.name };
      });
    },

    async getMerchants() {
      const { data } = await this.$api.get("/merchants");

      this.merchantsOptions = data.map((merchant) => {
        return { label: merchant.name, value: merchant.id };
      });
    },

    async loadDepartments() {
      this.departmentsOptions = [
        {
          groupeName: "Tous",
          libs: this.departments
            .map(({ code, name }) =>
              code !== "all"
                ? { label: code + " - " + name, value: code }
                : null
            )
            .filter((d) => d),
        },
      ];
    },

    async loadSettings() {
      const { data } = await this.$api.get("/settings");

      this.prestationsOptions = [
        {
          groupeName: "Tous",
          libs: data["intervenants_prestations"].map((p) => {
            return { label: p, value: p };
          }),
        },
      ];

      this.tagsOptions = [
        {
          groupeName: "Tous",
          libs: data["intervenants_tags"].map((p) => {
            return { label: p, value: p };
          }),
        },
      ];
    },

    async loadLanguages() {
      this.languagesOptions = [
        {
          groupeName: "Tous",
          libs: this.languages.map(({ code, name }) => {
            return { label: code + " - " + name, value: name };
          }),
        },
      ];
    },
  },
};
</script>
