<template>
  <footer class="app-footer">
    Just Coaching &copy; 2017 -
    {{ new Date().getFullYear() }}
    : v.1.21.0 - 18 Nov 2024
  </footer>
</template>

<script>
export default {};
</script>
