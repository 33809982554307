<template>
  <div class="rank-custom-wrapper">
    <CoachsFilters
      :default="filters"
      @update="handleFilters"
      :zipcodes="zipcodes"
    />

    <div class="card card-inverse card-info text-center" v-if="loading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>
    <div class="card text-center" v-else-if="data.length === 0">
      <div class="card-block">
        <blockquote class="card-blockquote">
          Aucune données, appliquer ou modifier les filtres
        </blockquote>
      </div>
    </div>

    <div v-else>
      <Tabs v-model="activeTab">
        <Tab header="Statistiques">
          <CoachStats
            :data="mapData"
            :others="mapDataOthers"
            :filters="filters"
          />
        </Tab>
        <Tab header="Données commandes">
          <CoachsOrders :orders="orders" />
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import CoachsFilters from "./prospected-coach/CoachsFilters";
import CoachStats from "./prospected-coach/CoachStats";
import CoachsOrders from "./prospected-coach/CoachsOrders";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    CoachsFilters,
    CoachStats,
    CoachsOrders,
    Tabs,
    Tab,
  },
  data() {
    return {
      loading: false,
      data: [],
      others: [],
      activeTab: 0,
      filters: this.defaultFilters(),
    };
  },

  computed: {
    ...mapGetters({
      departments: "getDepartments",
    }),

    zipcodes() {
      return this.departments.map(({ code, name }) => {
        if (code === "all") {
          return { label: name, value: code };
        } else {
          return { label: code + " - " + name, value: code };
        }
      });
    },

    //improve
    zipcodesOthers() {
      return this.departments.map(({ code, name }) => {
        if (code === "all") {
          return { label: name, value: code };
        } else {
          return { label: code + " - " + name, value: code };
        }
      });
    },

    mapData() {
      const domTom = ["971", "972", "973", "974", "976"];
      const departments = [...this.zipcodes].filter(
        (dpt) => !domTom.includes(dpt.value) && dpt.value !== "all"
      );

      departments.forEach((dpt) => {
        dpt.data = this.departmentClientInfo(dpt.value);
      });

      return departments;
    },

    mapDataOthers() {
      const domTom = ["971", "972", "973", "974", "976"];

      const departments = [...this.zipcodesOthers].filter(
        (dpt) => !domTom.includes(dpt.value) && dpt.value !== "all"
      );

      departments.forEach((dpt) => {
        dpt.data = this.departmentOthersClientInfo(dpt.value);
      });

      return departments;
    },

    orders() {
      return this.data
        .map((coach) => {
          return coach.orders.map((order) => {
            return {
              ...order,
              refClient: coach,
            };
          });
        })
        .flat();
    },
  },

  methods: {
    handleFilters(newFilters) {
      this.filters = newFilters;

      this.loadCoachs();
    },

    async loadCoachs() {
      try {
        this.loading = true;

        const { data } = await this.$api.get(
          `/rank/coach-manager/prospected-coachs`,
          {
            params: {
              begin: this.filters.begin,
              end: this.filters.end,
              coachManagers: this.filters.coachManagers,
              departments: this.filters.departments,
            },
          }
        );

        this.loading = false;
        this.data = data.prospectedCoachs;
        this.others = data.others;
      } catch (e) {
        console.log(e);
      }
    },

    departmentOthersClientInfo(dpt) {
      let departmentCoachs = this.others.filter((client) => {
        if (client.department && typeof client.department === "string") {
          return client.department === dpt;
        }
      });

      return departmentCoachs;
    },

    departmentClientInfo(dpt) {
      let departmentCoachs = this.data.filter((client) => {
        if (client.department && typeof client.department === "string") {
          return client.department === dpt;
        }
      });

      return departmentCoachs;
    },

    defaultFilters() {
      return {
        begin: new moment()
          // .subtract(1, "months")
          .subtract(3, "years")
          .startOf("day")
          .format("YYYY-MM-DD"),
        end: new moment().endOf("day").format("YYYY-MM-DD"),
      };
    },
  },
};
</script>
