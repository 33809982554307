<template>
  <div>
    <CoachDocumentsGroupAction
      v-if="coachDocumentsToValidated.length > 0 && documentsToSend.length > 1"
      @sendDocuments="sendAllDocuments"
      @rejectDocuments="rejectAllDocuments"
    />

    <el-table
      :data="coachDocumentsToValidated"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" fixed width="55" />
      <el-table-column width="180" sortable fixed label="Coach" prop="name">
        <template slot-scope="scope">
          <DisplayCoachName
            :coach="{
              coach: { name: scope.row.coachName },
              id: scope.row.coachId,
            }"
          />
        </template>
      </el-table-column>

      <el-table-column label="Nom de fichier">
        <template slot-scope="scope">
          {{ scope.row.document.tag }}
        </template>
      </el-table-column>

      <el-table-column label="Date de création">
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.document.createdAt) }}
        </template>
      </el-table-column>

      <el-table-column label="Coach manager">
        <template slot-scope="scope">
          {{
            scope.row.coachManager
              ? scope.row.coachManager.coachManager.pseudo
              : ""
          }}
        </template>
      </el-table-column>

      <el-table-column label="Actions">
        <template slot-scope="scope">
          <button
            @click="
              downloadSecuredFile(
                `uploads/document/${scope.row.document.fileId}`
              )
            "
            class="btn btn-primary"
          >
            <i class="icon-cloud-download"></i>
            Télécharger
          </button>

          <button
            class="btn btn-success"
            @click="
              validDocument(scope.row.document.fileId, scope.row.coachEmail)
            "
            v-if="hasPermission($store.state.user, 'DOCUMENTS_WRITE')"
          >
            <i class="icon-check"></i>
            Valider
          </button>

          <button
            class="btn btn-danger"
            @click="
              rejectDocument(scope.row.document.fileId, scope.row.coachEmail)
            "
            v-if="hasPermission($store.state.user, 'DOCUMENTS_WRITE')"
          >
            <i class="icon-close"></i>
            Refuser
          </button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import DisplayCoachName from "../components/coach/displayCoachName";
import DownloadFile from "../mixin/DownloadFile";
import CoachDocumentsGroupAction from "./CoachDocumentsGroupAction.vue";

export default {
  components: {
    DisplayCoachName,
    CoachDocumentsGroupAction,
  },

  mixins: [DownloadFile],

  props: {
    coachs: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      documentsToSend: [],
      coachDocumentsToValidated: [],
    };
  },

  watch: {
    coachs(value) {
      this.coachDocumentsToValidated = value;
    },
  },

  methods: {
    handleSelectionChange(selected) {
      this.documentsToSend = selected.map((coach) => ({
        coachEmail: coach.coachEmail,
        fileId: coach.document.fileId,
      }));
    },

    async sendAllDocuments() {
      if (this.documentsToSend.length > 1) {
        this.$confirm({
          message: `Valider ${this.documentsToSend.length} document${
            this.documentsToSend.length > 1 ? "s" : ""
          } ?`,
          onConfirm: async () => {
            try {
              await this.$api.patch("/users/coachs/documents", {
                documents: this.documentsToSend,
              });

              window.location.reload();
            } catch (e) {
              this.$errorToast(`Erreur lors de la sauvergarde`);
            }

            return this.coachDocumentsToValidated;
          },
        });
      }
    },

    async rejectAllDocuments() {
      if (this.documentsToSend.length > 1) {
        this.$confirm({
          message: `Refuser ${this.documentsToSend.length} document${
            this.documentsToSend.length > 1 ? "s" : ""
          } ?`,
          onConfirm: async () => {
            try {
              await this.$api.delete("/users/coachs/documents", {
                data: {
                  documents: this.documentsToSend,
                },
              });

              window.location.reload();
            } catch (e) {
              this.$errorToast(`Erreur lors de la sauvergarde`);
            }
          },
        });
      }
    },

    validDocument(fileId, email) {
      this.$confirm({
        message: "Valider le document ?",
        onConfirm: async () => {
          try {
            const { data } = await this.$api.patch(
              `/users/coachs/document/${fileId}`,
              {
                email,
              }
            );

            this.coachDocumentsToValidated =
              this.coachDocumentsToValidated.filter(
                (coach) => coach.document.fileId !== data
              );

            this.$successToast(`Le document à bien été sauvegardé`);
          } catch (e) {
            this.$errorToast(`Erreur lors de la sauvergarde`);
          }
        },
      });
    },

    rejectDocument(fileId, email) {
      this.$confirm({
        message: "Supprimer le document ? Cette action est irréversible.",
        onConfirm: async () => {
          try {
            await this.$api.delete(`/users/coachs/document/${fileId}`, {
              data: {
                email,
              },
            });

            this.coachDocumentsToValidated =
              this.coachDocumentsToValidated.filter(
                (coach) => coach.document.fileId !== fileId
              );

            this.$successToast(`Le document à bien été rejeté`);
          } catch (e) {
            this.$errorToast(`Erreur lors de la sauvergarde`);
          }
        },
      });
    },
  },
};
</script>
