<template>
  <div>
    <div class="card">
      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-4">
            <label>Département(s)</label>
            <multiselect
              v-model="filters.departments"
              :options="dptOptions"
              label="label"
              track-by="value"
              multiple
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-4">
            <button class="btn btn-default" @click="applyFilters">
              <i class="icon-eye"></i>&nbsp; Visualiser
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filters: [],
    };
  },

  async created() {
    this.filters = this.default;
  },

  computed: {
    ...mapGetters({
      departments: "getDepartments",
    }),

    dptOptions() {
      return this.departments
        .filter((dpt) => dpt.code !== "all")
        .map(({ code, name }) => ({
          label: `${code} - ${name}`,
          value: code,
        }));
    },
  },

  methods: {
    applyFilters() {
      this.$emit("update", {
        departments: this.filters.departments,
      });
    },
  },
};
</script>
