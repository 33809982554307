<template>
  <div class="flex flex-col flex-wrap">
    <div class="flex flex-col">
      <h3>
        TOP COACHS
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{
            content: 'Coachs ayant un taux de conversion supérieur à 66%.',
            placement: 'top',
          }"
        ></i>
      </h3>
      <FidelisationLevel
        id="successCoachs"
        type="success"
        :coachs="successCoachs"
      />
    </div>

    <div class="flex flex-col mt-3">
      <h3>
        MIDDLE COACHS
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{
            content: 'Coachs ayant un taux de conversion entre 33% et 66%.',
            placement: 'top',
          }"
        ></i>
      </h3>
      <FidelisationLevel
        id="warningCoachs"
        type="warning"
        :coachs="warningCoachs"
      />
    </div>

    <div class="flex flex-col mt-3">
      <h3>
        BAD COACHS
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{
            content: 'Coachs ayant un taux de conversion inférieur à 33%.',
            placement: 'top',
          }"
        ></i>
      </h3>
      <FidelisationLevel
        id="dangerCoachs"
        type="danger"
        :coachs="dangerCoachs"
      />
    </div>
  </div>
</template>

<script>
import FidelisationLevel from "./FidelisationLevel";
export default {
  components: {
    FidelisationLevel,
  },

  props: {
    data: { type: Array, required: true },
    filters: {
      required: true,
      type: Object,
    },
    currentCoach: null,
  },

  data() {
    return {};
  },

  computed: {
    successCoachs() {
      return this.data.filter((coach) => coach.tf >= 66);
    },
    warningCoachs() {
      return this.data.filter((coach) => coach.tf >= 33 && coach.tf < 66);
    },
    dangerCoachs() {
      return this.data.filter((coach) => coach.tf < 33);
    },
  },

  methods: {
    goToUser(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },
  },
};
</script>
