<template>
  <div class="row" style="position: relative">
    <div class="col-sm-8" v-if="user">
      <div class="card">
        <div class="card-header">
          <i class="icon-user"></i>
          {{ user.email }}
          <el-select
            :value="currentStatus && currentStatus.value"
            size="mini"
            @change="handleStatus($event)"
            :disabled="!hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE')"
            class="current-status text-white float-right"
            :style="`background-color: ${
              currentStatus && currentStatus.color
            }; border-radius: 5px;`"
          >
            <el-option
              v-for="status in coachStatus"
              :key="status.value"
              :value="status.value"
              :label="status.label"
              class="text-white"
              :style="`background-color: ${status.color}`"
            />
          </el-select>
        </div>
        <div class="card-block">
          <dl class="row">
            <dt class="col-sm-4">Administrateur</dt>
            <dd class="col-sm-8">
              <button
                type="button"
                class="btn btn-danger float-right"
                @click="loginToUser()"
                v-if="hasRole($store.state.user, 'admin')"
              >
                <i class="icon-lock-open"></i>&nbsp; Se connecter
              </button>
              <el-switch
                ref="toggleAdmin"
                :value="hasRole(user, 'admin')"
                @change="toggleRole"
                :disabled="!hasRole($store.state.user, 'admin')"
              />
            </dd>
            <dt class="col-sm-4">Prénom</dt>
            <dd class="col-sm-8">
              <el-input
                v-model="user.firstName"
                size="mini"
                @change="editFirstName($event)"
                v-if="hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE')"
              />
              <p v-else>{{ user.firstName }}</p>
            </dd>
            <dt class="col-sm-4">Nom de famille</dt>
            <dd class="col-sm-8">
              <el-input
                v-model="user.lastName"
                size="mini"
                @change="editLastName($event)"
                v-if="hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE')"
              />
              <p v-else>{{ user.lastName }}</p>
            </dd>
            <dt
              class="col-sm-4"
              v-if="hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE')"
            >
              Mot de passe
            </dt>
            <dd
              class="col-sm-8"
              v-if="hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE')"
            >
              <button
                @click="modalChangePassword = true"
                class="btn btn-primary btn-sm"
              >
                Changer
              </button>
            </dd>
            <dt class="col-sm-4">Rôle commercial</dt>
            <dd class="col-sm-8">
              <div class="row">
                <div class="col-sm-2">
                  <el-switch
                    :value="
                      user.commercial ? user.commercial.isCommercial : false
                    "
                    @change="toggleCommercial()"
                    :disabled="
                      !hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE')
                    "
                  />
                </div>
                <div
                  class="col-sm-10"
                  v-if="user.commercial && user.commercial.isCommercial"
                >
                  <div class="row">
                    <div class="col-sm-4">
                      <p>Pseudo commercial</p>
                    </div>
                    <div class="col-sm-8">
                      <el-input
                        v-model="user.commercial.pseudo"
                        size="mini"
                        @change="
                          editCommercial({
                            isCommercial: user.commercial.isCommercial,
                            pseudo: $event,
                          })
                        "
                        v-if="
                          hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      />
                      <p v-else>{{ user.commercial.pseudo }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>Poste</p>
                    </div>
                    <div class="col-sm-8">
                      <el-input
                        v-model="user.commercial.job"
                        size="mini"
                        @change="
                          editCommercial({
                            isCommercial: user.commercial.isCommercial,
                            job: $event,
                          })
                        "
                        v-if="
                          hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      />
                      <p v-else>{{ user.commercial.job }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>
                        Actif
                        <i
                          class="fa fa-info-circle ml-05"
                          v-tooltip="{
                            content:
                              'Permet d\'être attribué automatiquement à des clients/entreprises',
                            placement: 'right',
                          }"
                        ></i>
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <el-switch
                        v-model="user.commercial.active"
                        @change="
                          editCommercial({
                            active: user.commercial.active,
                          })
                        "
                        :disabled="
                          !hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>
                        B2C
                        <i
                          class="fa fa-info-circle ml-05"
                          v-tooltip="{
                            content:
                              'Permet d\'être attribué automatiquement à des clients',
                            placement: 'right',
                          }"
                        ></i>
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <el-switch
                        v-model="user.commercial.isB2C"
                        @change="
                          editCommercial({
                            isB2C: user.commercial.isB2C,
                          })
                        "
                        :disabled="
                          !hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>
                        B2B
                        <i
                          class="fa fa-info-circle ml-05"
                          v-tooltip="{
                            content:
                              'Permet d\'être attribué automatiquement à des entreprises',
                            placement: 'right',
                          }"
                        ></i>
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <el-switch
                        v-model="user.commercial.isB2B"
                        @change="
                          editCommercial({
                            isB2B: user.commercial.isB2B,
                          })
                        "
                        :disabled="
                          !hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>
                        Masqué
                        <i
                          class="fa fa-info-circle ml-05"
                          v-tooltip="{
                            content: 'N\'apparait pas dans les propositions',
                            placement: 'right',
                          }"
                        ></i>
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <el-switch
                        v-model="user.commercial.hidden"
                        @change="
                          editCommercial({
                            hidden: user.commercial.hidden,
                          })
                        "
                        :disabled="
                          !hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <label>Période à exclure</label>
                      <i
                        class="fa fa-info-circle ml-05"
                        v-tooltip="{
                          content:
                            'Cette fonctionnalité permet de planifier les périodes à exclure afin d’anticiper les absences des commerciaux et permettre la réorganisation de la répartition des prospects en conséquence',
                          placement: 'right',
                        }"
                      ></i>
                    </div>
                    <div class="col-sm-2">
                      <el-switch
                        v-model="user.commercial.excludedPeriod"
                        @change="
                          editCommercial({
                            excludedPeriod: $event,
                          })
                        "
                        :disabled="
                          !hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="row" v-if="user.commercial.excludedPeriod">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-6">
                      Du
                      <datepicker
                        v-if="
                          hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                        v-model="user.commercial.excludedPeriodBegin"
                        format="dd.MM.yyyy"
                        language="fr"
                        input-class="form-control"
                        @input="
                          editCommercialDate('excludedPeriodBegin', $event)
                        "
                      ></datepicker>
                      <template v-else>
                        {{ formatDate(user.commercial.excludedPeriodBegin) }}
                      </template>
                    </div>
                  </div>
                  <div class="row mb-2" v-if="user.commercial.excludedPeriod">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-6">
                      au
                      <datepicker
                        v-if="
                          hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                        v-model="user.commercial.excludedPeriodEnd"
                        format="dd.MM.yyyy"
                        language="fr"
                        input-class="form-control"
                        @input="editCommercialDate('excludedPeriodEnd', $event)"
                      ></datepicker>
                      <template v-else>
                        {{ formatDate(user.commercial.excludedPeriodEnd) }}
                      </template>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>Mobile</p>
                    </div>
                    <div class="col-sm-8">
                      <PhoneNumberInput
                        v-if="
                          hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                        :phone-number="user.commercial.mobile"
                        :phone-code="user.commercial.phoneCode"
                        size="mini"
                        copy
                        required
                        @changeNumber="
                          editCommercial({
                            isCommercial: user.commercial.isCommercial,
                            mobile: $event,
                          })
                        "
                        @changeCode="
                          editCommercial({
                            isCommercial: user.commercial.isCommercial,
                            phoneCode: $event,
                          })
                        "
                      />
                      <p v-else>
                        <span v-if="user.commercial.phoneCode"
                          >({{ user.commercial.phoneCode }}) </span
                        >{{ user.commercial.mobile }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>Couleur</p>
                    </div>
                    <div class="col-sm-8">
                      <el-color-picker
                        v-model="user.commercial.color"
                        @change="
                          editCommercial({
                            isCommercial: user.commercial.isCommercial,
                            color: $event,
                          })
                        "
                        :disabled="
                          !hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      ></el-color-picker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>Photo</p>
                    </div>
                    <div class="col-sm-8">
                      <TeamMemberPicture
                        dropzoneId="photoCommercial"
                        :img="user.commercial.img"
                        :url="getImg"
                        @update="updateCommercialPhoto"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </dd>
            <dt class="col-sm-4">Rôle Coach Manager</dt>
            <dd class="col-sm-8">
              <div class="row">
                <div class="col-sm-2">
                  <el-switch
                    :value="
                      user.coachManager
                        ? user.coachManager.isCoachManager
                        : false
                    "
                    @change="toggleCoachManager()"
                    :disabled="
                      !hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE')
                    "
                  />
                </div>
                <div
                  class="col-sm-10"
                  v-if="user.coachManager && user.coachManager.isCoachManager"
                >
                  <div class="row">
                    <div class="col-sm-4">
                      <p>Pseudo coach manager</p>
                    </div>
                    <div class="col-sm-8">
                      <el-input
                        v-model="user.coachManager.pseudo"
                        size="mini"
                        @change="
                          editCoachManager({
                            isCoachManager: user.coachManager.isCoachManager,
                            pseudo: $event,
                          })
                        "
                        v-if="
                          hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      />
                      <p v-else>{{ user.coachManager.pseudo }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>Poste</p>
                    </div>
                    <div class="col-sm-8">
                      <el-input
                        v-model="user.coachManager.job"
                        size="mini"
                        @change="
                          editCoachManager({
                            isCoachManager: user.coachManager.isCoachManager,
                            job: $event,
                          })
                        "
                        v-if="
                          hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      />
                      <p v-else>{{ user.coachManager.job }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>
                        Actif
                        <i
                          class="fa fa-info-circle ml-05"
                          v-tooltip="{
                            content:
                              'Permet d\'être attribué automatiquement à des attributions',
                            placement: 'right',
                          }"
                        ></i>
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <el-switch
                        v-model="user.coachManager.active"
                        @change="
                          editCoachManager({
                            isCoachManager: user.coachManager.isCoachManager,
                            active: $event,
                          })
                        "
                        :disabled="
                          !hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>
                        Masqué
                        <i
                          class="fa fa-info-circle ml-05"
                          v-tooltip="{
                            content: 'N\'apparait pas dans les propositions',
                            placement: 'right',
                          }"
                        ></i>
                      </p>
                    </div>
                    <div class="col-sm-2">
                      <el-switch
                        v-model="user.coachManager.hidden"
                        @change="
                          editCoachManager({
                            isCoachManager: user.coachManager.isCoachManager,
                            hidden: $event,
                          })
                        "
                        :disabled="
                          !hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <label>Période à exclure</label>
                      <i
                        class="fa fa-info-circle ml-05"
                        v-tooltip="{
                          content:
                            'Cette fonctionnalité permet de planifier les périodes à exclure afin d’anticiper les absences des coachs manager et permettre la réorganisation de la répartition des attributions en conséquence',
                          placement: 'right',
                        }"
                      ></i>
                    </div>
                    <div class="col-sm-2">
                      <el-switch
                        v-model="user.coachManager.excludedPeriod"
                        @change="
                          editCoachManager({
                            excludedPeriod: $event,
                          })
                        "
                        :disabled="
                          !hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="row" v-if="user.coachManager.excludedPeriod">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-6">
                      Du
                      <datepicker
                        v-if="
                          hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                        v-model="user.coachManager.excludedPeriodBegin"
                        format="dd.MM.yyyy"
                        language="fr"
                        input-class="form-control"
                        @input="
                          editCoachManagerDate('excludedPeriodBegin', $event)
                        "
                      ></datepicker>
                      <template v-else>
                        {{ formatDate(user.coachManager.excludedPeriodBegin) }}
                      </template>
                    </div>
                  </div>
                  <div class="row mb-2" v-if="user.coachManager.excludedPeriod">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-6">
                      au
                      <datepicker
                        v-if="
                          hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                        v-model="user.coachManager.excludedPeriodEnd"
                        format="dd.MM.yyyy"
                        language="fr"
                        input-class="form-control"
                        @input="
                          editCoachManagerDate('excludedPeriodEnd', $event)
                        "
                      ></datepicker>
                      <template v-else>
                        {{ formatDate(user.coachManager.excludedPeriodEnd) }}
                      </template>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>Mobile</p>
                    </div>
                    <div class="col-sm-8">
                      <PhoneNumberInput
                        v-if="
                          hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                        :phone-number="user.coachManager.mobile"
                        :phone-code="user.coachManager.phoneCode"
                        size="mini"
                        copy
                        required
                        @changeNumber="
                          editCoachManager({
                            isCoachManager: user.coachManager.isCoachManager,
                            mobile: $event,
                          })
                        "
                        @changeCode="
                          editCoachManager({
                            isCoachManager: user.coachManager.isCoachManager,
                            phoneCode: $event,
                          })
                        "
                      />
                      <p v-else>
                        <span v-if="user.coachManager.phoneCode"
                          >({{ user.coachManager.phoneCode }}) </span
                        >{{ user.coachManager.mobile }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>Couleur</p>
                    </div>
                    <div class="col-sm-8">
                      <el-color-picker
                        v-model="user.coachManager.color"
                        @change="
                          editCoachManager({
                            isCoachManager: user.coachManager.isCoachManager,
                            color: $event,
                          })
                        "
                        :disabled="
                          !hasPermission(
                            $store.state.user,
                            'SETTINGS_TEAM_WRITE'
                          )
                        "
                      ></el-color-picker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <p>Photo</p>
                    </div>
                    <div class="col-sm-8">
                      <TeamMemberPicture
                        dropzoneId="photoCoachManager"
                        :img="user.coachManager.img"
                        :url="getCoachManagerImg"
                        @update="updateCoachManagerPhoto"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </dd>
          </dl>
        </div>
      </div>

      <div class="card" v-if="!hasRole(user, 'admin')">
        <div class="card-header">Autorisations</div>
        <div class="card-block">
          <table class="permissions">
            <thead>
              <tr>
                <th>MENU</th>
                <th class="text-center">CONSULTATION</th>
                <th class="text-center">MODIFICATION</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(set, index) of settings" :key="index">
                <td :class="getSettingsClass(set)">
                  {{ set.title }}
                  <i
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{ content: set.info, placement: 'right' }"
                    v-if="set.info"
                  ></i>
                </td>
                <td class="text-center">
                  <el-switch
                    v-if="set.read"
                    :value="hasPermission(user, set.read)"
                    @change="togglePermission(set.read)"
                    :disabled="permissionToggleDisabled(set, 'read')"
                  />
                </td>
                <td class="text-center">
                  <el-switch
                    v-if="set.write"
                    :value="hasPermission(user, set.write)"
                    @change="togglePermission(set.write)"
                    :disabled="permissionToggleDisabled(set, 'write')"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card" v-if="user">
        <div class="card-header">
          <i class="fa fa-info-circle mr-05"></i> Historique
        </div>
        <div class="card-block">
          <log :user="user" />
        </div>
      </div>
    </div>
    <ChangePassword
      v-if="user"
      :email="user.email"
      :open="modalChangePassword"
    />
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapGetters } from "vuex";
import Log from "../components/Log";
import ChangePassword from "../components/modal/ChangePassword";
import Datepicker from "vuejs-datepicker";
import PhoneNumberInput from "../components/PhoneNumberInput";
import TeamMemberPicture from "../components/TeamMemberPicture";

export default {
  components: {
    Log,
    ChangePassword,
    Datepicker,
    PhoneNumberInput,
    TeamMemberPicture,
  },
  data() {
    return {
      user: null,
      modalChangePassword: false,
      form: {
        password: null,
      },
      settings: [
        {
          title: "Dashboard",
          read: "DASHBOARD_READ",
          info: "L'onglet Dashboard présente des indicateurs globaux sur les commandes, sessions, coachs, etc...",
        },
        {
          title: "Conversion",
          read: "CONVERSION_READ",
          write: "CONVERSION_WRITE",
          info: 'L\'onglet Conversion présente la liste des commandes considérées comme "conversions". La modification permet d\'activer le push aux coachs, l\'archivage et le "désarchivage".',
        },
        {
          title: "Fidélisation",
          read: "FIDELISATION_READ",
          write: "FIDELISATION_WRITE",
          info: 'L\'onglet Fidélisation présente la liste des commandes considérées comme "fidélisations". La modification permet de changer le statut du client (converti/fidélisé), l\'archivage et le "désarchivage".',
        },
        {
          title: "Coach manager",
          read: "COACH_MANAGER_READ",
          info: "L'onglet Coach manager permet de gérer les statistiques relié aux coach).",
        },
        {
          title: "Pertes",
          read: "COACH_MANAGER_LOST_READ",
          info: "Permet d'acceder au menu Perte du coach manager.",
          parent: {
            name: "COACH_MANAGER_READ",
            type: "read",
          },
        },
        {
          title: "Status",
          read: "COACH_MANAGER_STATUS_READ",
          info: "Permet d'acceder au menu Status du coach manager.",
          parent: {
            name: "COACH_MANAGER_READ",
            type: "read",
          },
        },
        {
          title: "Conversion",
          read: "COACH_MANAGER_CONVERSION_READ",
          info: "Permet d'acceder au menu classement conversion du coach manager.",
          parent: {
            name: "COACH_MANAGER_READ",
            type: "read",
          },
        },
        {
          title: "Fidelisation",
          read: "COACH_MANAGER_FIDELISATION_READ",
          info: "Permet d'acceder au menu classement fidelisation du coach manager.",
          parent: {
            name: "COACH_MANAGER_READ",
            type: "read",
          },
        },
        {
          title: "Scrapping",
          read: "COACH_MANAGER_SCRAPPING_READ",
          info: "Permet d'acceder au menu scrapping du coach manager.",
          parent: {
            name: "COACH_MANAGER_READ",
            type: "read",
          },
        },
        {
          title: "Notes interne",
          read: "COACH_MANAGER_INTERNAL_NOTES_READ",
          info: "Permet d'acceder au menu notes interne du coach manager.",
          parent: {
            name: "COACH_MANAGER_READ",
            type: "read",
          },
        },
        {
          title: "Notes clients",
          read: "COACH_MANAGER_CLIENTS_NOTES_READ",
          info: "Permet d'acceder au menu notes clients du coach manager.",
          parent: {
            name: "COACH_MANAGER_READ",
            type: "read",
          },
        },
        {
          title: "Commandes",
          read: "ORDERS_READ",
          write: "ORDERS_WRITE",
          info: "L'onglet Commandes présente la liste des dernières commandes. La modification permet d'ajouter des paiements, envoyer un mail d'information, créer de nouvelles commandes, les publier, etc...",
        },
        {
          title: "Pack autres (Aucun menu / Stat acquisition)",
          write: "ORDERS_PACK_AUTRES",
          info: "Permet de choisir ce pack à la création d'une commande. Exemple : pouvoir créer une commande So Healthy.",
          parent: {
            name: "ORDERS_WRITE",
            type: "write",
          },
        },
        {
          title: "Commandes date commercial",
          read: "ORDERS_COMMERCIALS_WRITE",
          info: "En activant ce paramètre l'utilisateur peux modifier la date de relation entre le commercial et une commande.",
          parent: {
            name: "ORDERS_READ",
            type: "write",
          },
        },
        {
          title: "Commandes date coach manager",
          read: "ORDERS_COACH_MANAGER_WRITE",
          info: "En activant ce paramètre l'utilisateur peux modifier la date de relation entre le coach manager et une commande.",
          parent: {
            name: "ORDERS_READ",
            type: "write",
          },
        },
        {
          title: "Sessions commande",
          write: "SESSIONS_WRITE",
          info: "Permet de modifier les sessions dans la commande (planification, attribution, ...).",
          parent: {
            name: "ORDERS_WRITE",
            type: "write",
          },
        },
        {
          title: "Suppression commandes",
          write: "ORDERS_DELETE",
          info: "Permet de supprimer une commande.",
          parent: {
            name: "ORDERS_WRITE",
            type: "write",
          },
        },
        {
          title: "Date d'attribution commercial",
          write: "ORDERS_COMMERCIALS_DATE",
          info: "Permet de modifier la date d'attribution du commercial.",
          parent: {
            name: "ORDERS_WRITE",
            type: "write",
          },
        },
        {
          title: "Ajouter des paiements dans une commande",
          write: "ORDER_PAYMENT_WRITE",
          info: "Permet d'ajouter un paiement à une commande.",
          parent: {
            name: "ORDERS_WRITE",
            type: "write",
          },
        },
        {
          title: "Blocage	commande	(2	impayés	ou	+)",
          write: "BLOCKED_IMPAYED_ORDERS_READ",
          info: "Autorise un équipier à créer une commande lorsqu'il y a 2+ impayés dans ses clients.",
        },
        {
          title: "Facturation",
          read: "BILLING_READ",
          info: "L'onglet Facturation permet d'afficher les factures, paiements, avoirs & impayés",
        },
        {
          title: "Factures",
          read: "INVOICES_READ",
          info: "L'onglet Factures permet de lister et télécharger l'ensemble des factures établies.",
          parent: {
            name: "BILLING_READ",
            type: "read",
          },
        },
        {
          title: "Paiements",
          read: "PAYMENTS_READ",
          write: "PAYMENTS_WRITE",
          info: "L'onglet Paiements présente la liste des paiements en cours, passés et à venir. La modification permet de gérer les paiements (marquer comme payé, changer le moyen de paiement, ...).",
          parent: {
            name: "BILLING_READ",
            type: "read",
          },
        },
        {
          title: "Stripe",
          read: "PAYOUTS_READ",
          info: "L'onglet Virements permet de lister l'ensemble des virements depuis Stripe.",
          parent: {
            name: "BILLING_READ",
            type: "read",
          },
        },
        {
          title: "Impayés",
          read: "UNPAID_READ",
          write: "UNPAID_WRITE",
          info: "L'onglet impayés permet de lister l'ensemble des facture avec un 'Impayé'.",
          parent: {
            name: "BILLING_READ",
            type: "read",
          },
        },
        {
          title: "Remboursement",
          write: "REFUNDS_WRITE",
          info: "Possibilité d'éditer un remboursement.",
        },
        {
          title: "Attributions",
          read: "ATTRIBUTIONS_READ",
          write: "ATTRIBUTIONS_WRITE",
          info: "L'onglet Attributions permet de lister les attributions. La modification permet d'envoyer un push, attribuer, refuser, supprimer, terminer une attribution, etc...",
        },
        {
          title: "Attributions filtre automatique (commerciaux)",
          read: "ATTRIBUTIONS_FILTERS_WRITE",
          info: "En activant ce paramètre les commerciaux arrivant sur l'onglet attribution auront un filtre adapté.",
          parent: {
            name: "ATTRIBUTIONS_READ",
            type: "read",
          },
        },
        {
          title: "Attributions filtre automatique (coach manager)",
          read: "ATTRIBUTIONS_FILTERS_COACH_MANAGER_WRITE",
          info: "En activant ce paramètre les coachs manager arrivant sur l'onglet attribution auront un filtre adapté.",
          parent: {
            name: "ATTRIBUTIONS_READ",
            type: "read",
          },
        },
        {
          title: "Clients / Prospects",
          read: "CLIENTS_READ",
          write: "CLIENTS_WRITE",
          info: "L'onglet Clients / Prospects permet de lister l'ensemble des clients et prospects. La modification permet de créer, modifier, supprimer, archiver un client/prospect.",
        },
        {
          title: "Suppression clients",
          write: "CLIENTS_DELETE",
          info: "Permet de supprimer un client.",
          parent: {
            name: "CLIENTS_WRITE",
            type: "write",
          },
        },
        {
          title: "Date d'attribution commercial",
          write: "CLIENTS_COMMERCIALS_DATE",
          info: "Permet de modifier la date d'attribution du commercial.",
          parent: {
            name: "CLIENTS_WRITE",
            type: "write",
          },
        },
        {
          title: "Menu Groupe",
          read: "GROUP_MENU_READ",
          write: "GROUP_MENU_WRITE",
          info: "Le menu Groupe permet de lister les différents groupes.",
        },
        {
          title: "Menu prestataires",
          read: "USERS_MENU_READ",
          info: "Le menu Prestataires permet de lister les différents menu concernant les prestataires.",
        },
        {
          title: "Prestataires",
          read: "USERS_READ",
          write: "USERS_WRITE",
          info: "L'onglet Prestataires permet de lister les différents prestataires (intervenants, formateurs, influenceurs, ...). La modification permet de créer, modifier, supprimer, archiver un prestataire.",
          parent: {
            name: "USERS_MENU_READ",
            type: "read",
          },
        },
        {
          title: "Documents",
          read: "DOCUMENTS_READ",
          write: "DOCUMENTS_WRITE",
          info: "L'onglet Documents permet de lister les documents à traiter, partagés avec la personne connectée ou privés. La modification permet de valider, refuser, supprimer ou ajouter un document.",
          parent: {
            name: "USERS_MENU_READ",
            type: "read",
          },
        },
        {
          title: "Facturation Coach",
          read: "DISBURSEMENTS_READ",
          write: "DISBURSEMENTS_WRITE",
          info: "L'onglet Facturation permet de lister l'ensemble des factures coachs établies ou à traiter. La modification permet de marquer une facture comme payée ou de la refuser.",
          parent: {
            name: "USERS_MENU_READ",
            type: "read",
          },
        },
        {
          title: "Remplacements",
          read: "SUBSTITUTIONS_READ",
          write: "SUBSTITUTIONS_WRITE",
          info: "L'onglet Remplacements permet de lister les remplacements à traiter et archivés. La modification permet de créer, modifier, supprimer, archiver un remplacement.",
          parent: {
            name: "USERS_MENU_READ",
            type: "read",
          },
        },
        {
          title: "Photos",
          read: "COACHS_PICTURES_READ",
          write: "COACHS_PICTURES_WRITE",
          info: "L'onglet Photos permet de lister les photos de profil des coachs à valider et validées. La modification permet de valider, refuser ou mettre en attente une photo.",
          parent: {
            name: "USERS_MENU_READ",
            type: "read",
          },
        },
        {
          title: "Menu entreprise",
          read: "COMPANIES_MENU_READ",
          info: "Le menu Entreprise permet de lister les différents menu concernant les entreprises.",
        },
        {
          title: "Entreprise",
          read: "COMPANIES_COMPANY_READ",
          write: "COMPANIES_COMPANY_WRITE",
          info: "L'onglet Entreprise permet de lister les différentes entreprises.",
          parent: {
            name: "COMPANIES_MENU_READ",
            type: "read",
          },
        },
        {
          title: "Live",
          read: "LIVE_MENU_READ",
          write: "LIVE_MENU_WRITE",
          info: "L'onglet Live permet d'accéder aux différentes infos des lives (comptes Zoom, FAQ live, suggestions de cours, replays, liste des cours, programmation, participants, etc...). La modification permet de modifier tout cela.",
        },
        {
          title: "Paramètres",
          read: "SETTINGS_READ",
          info: "L'onglet Live permet d'accéder aux différents paramètres (Equipe, Concepts, Fréquence Inactivités, Intervenants, Document Contrats, Parrainage, Codes Promo). La modification permet de modifier ces diférents paramètres.",
        },
        {
          title: "Codes promo",
          read: "SETTINGS_PROMO_CODE_READ",
          write: "SETTINGS_PROMO_CODE_WRITE",
          info: "L'onglet code promo permet de créer, éditer, supprimer des codes promotionnel.",
          parent: {
            name: "SETTINGS_READ",
            type: "read",
          },
        },
        {
          title: "Concepts",
          read: "SETTINGS_CONCEPT_READ",
          write: "SETTINGS_CONCEPT_WRITE",
          info: "L'onglet concepts permet d'ajouter, éditer, supprimer des concepts.",
          parent: {
            name: "SETTINGS_READ",
            type: "read",
          },
        },
        {
          title: "Équipe",
          read: "SETTINGS_TEAM_READ",
          write: "SETTINGS_TEAM_WRITE",
          info: "L'onglet équipe permet d'éditer les informations des équipiers de l'application. ATTENTION l'édition débloque par conséquence un droit sur toute l'application, car l'utilisateur pourra éditer les droits",
          parent: {
            name: "SETTINGS_READ",
            type: "read",
          },
        },
        {
          title: "Configurations",
          read: "SETTINGS_CONFIG_READ",
          write: "SETTINGS_CONFIG_WRITE",
          info: "L'onglet configuration permet d'éditer des paramètres de l'application.",
          parent: {
            name: "SETTINGS_READ",
            type: "read",
          },
        },
        {
          title: "Moyens de paiement",
          read: "SETTINGS_PAYMENTS_TYPE_READ",
          write: "SETTINGS_PAYMENTS_TYPE_WRITE",
          info: "L'onglet Moyens de paiement permet d'éditer les moyens de paiement des differents concepts.",
          parent: {
            name: "SETTINGS_READ",
            type: "read",
          },
        },
        {
          title: "Prestataires",
          read: "SETTINGS_PRESTA_READ",
          write: "SETTINGS_PRESTA_WRITE",
          info: "L'onglet prestataires permet d'éditer les documents et catégorie accessible au prestataire.",
          parent: {
            name: "SETTINGS_READ",
            type: "read",
          },
        },
        {
          title: "Sociétés",
          read: "MERCHANTS_READ",
          write: "MERCHANTS_WRITE",
          info: "L'onglet Sociétés permet de lister l'ensemble des sociétés. La modification permet de créer, modifier, supprimer une société.",
          parent: {
            name: "SETTINGS_READ",
            type: "read",
          },
        },
        {
          title: "Export",
          read: "EXPORT_READ",
          info: "L'onglet export permet d'exporter les clients/prospects/intervenants au format excel.",
          parent: {
            name: "SETTINGS_READ",
            type: "read",
          },
        },
        {
          title: "Clients",
          read: "EXPORT_CLIENT_READ",
          info: "L'onglet export clients permet d'exporter les clients au format excel.",
          parent: {
            name: "EXPORT_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Prospects",
          read: "EXPORT_PROSPECT_READ",
          info: "L'onglet export prospects permet d'exporter les prospects au format excel.",
          parent: {
            name: "EXPORT_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Prospects",
          read: "EXPORT_PROSPECT_READ",
          info: "L'onglet export prospects permet d'exporter les prospects au format excel.",
          parent: {
            name: "EXPORT_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Commandes",
          read: "EXPORT_ORDER_READ",
          info: "L'onglet export commandes permet d'exporter les commandes au format excel.",
          parent: {
            name: "EXPORT_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Prestataires",
          read: "EXPORT_COACH_READ",
          info: "L'onglet export prestataires permet d'exporter les prestataires au format excel.",
          parent: {
            name: "EXPORT_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "NOVA",
          read: "EXPORT_NOVA_READ",
          info: "L'onglet export NOVA donne accès à l'export des données NOVA.",
          parent: {
            name: "EXPORT_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "leads & Marketing",
          read: "EXPORT_MARKETING_FORM_READ",
          info: "L'onglet export Leads & Marketing, permet d'exporter tous les leads (Prospects & Clients) avec les informations marketing sur une période.",
          parent: {
            name: "EXPORT_READ",
            type: "read",
          },
          level: 2,
        },

        {
          title: "Compta",
          read: "EXPORT_COMPTA_READ",
          info: "L'onglet export compta permet d'exporter les factures ou paiement au format excel.",
          parent: {
            name: "EXPORT_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Import",
          read: "IMPORT_WRITE",
          info: "L'onglet import permet d'importer les clients/prospects/intervenants du format excel.",
          parent: {
            name: "SETTINGS_READ",
            type: "read",
          },
        },
        {
          title: "Contenu pour les applications",
          read: "DYNAMIC_CONTENT_READ",
          write: "DYNAMIC_CONTENT_WRITE",
          info: "Contenu pour les applications permet de consulter et créer des types de contenu et des contenus dynamiques ainsi que mes message pour les coachs avec ou sans notification.",
          parent: {
            name: "SETTINGS_READ",
            type: "read",
          },
        },
        {
          title: "SAP",
          read: "SETTINGS_SAP_READ",
          info: "L'onglet SAP permet de lister l'ensemble des sap par client",
          parent: {
            name: "SETTINGS_READ",
            type: "read",
          },
        },
        {
          title: "FAQ",
          read: "FAQ_READ",
          write: "FAQ_WRITE",
          info: "L'onglet FAQ permet de lister l'ensemble des questions de la FAQ. La modification permet de créer, modifier, supprimer une question.",
          parent: {
            name: "SETTINGS_READ",
            type: "read",
          },
        },
        {
          title: "Statistiques",
          read: "STATS_READ",
          info: "L'onglet Statistiques permet d'afficher différentes statistiques concernant les dépenses, le marketing, le chiffre d'affaire ou les clients perdus.",
        },
        {
          title: "Statistiques - Marketing",
          read: "STATS_MARKETING_READ",
          info: "L'onglet Statistiques - Marketing permet d'afficher les statistiques concernant le marketing.",
          parent: {
            name: "STATS_READ",
            type: "read",
          },
        },
        {
          title: "Statistiques - CA/ROAS",
          read: "STATS_MARKETING_ROAS_READ",
          info: "L'onglet Statistiques - Marketing permet d'afficher les statistiques concernant le marketing.",
          parent: {
            name: "STATS_MARKETING_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Statistiques - Coûts formulaires",
          read: "STATS_MARKETING_FORM_EXPENSE_READ",
          info: "L'onglet Statistiques - Coûts formulaires",
          parent: {
            name: "STATS_MARKETING_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Statistiques - KPI",
          read: "STATS_MARKETING_KPI_READ",
          info: "L'onglet Statistiques - Marketing - KPI",
          parent: {
            name: "STATS_MARKETING_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Statistiques - Dépenses",
          read: "STATS_EXPENSE_READ",
          info: "L'onglet Statistiques - Dépenses permet d'afficher les statistiques concernant les dépenses.",
          parent: {
            name: "STATS_MARKETING_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Statistiques - Pertes",
          read: "STATS_LOST_READ",
          info: "L'onglet Statistiques - Pertes permet d'afficher les statistiques concernant les clients perdus.",
          parent: {
            name: "STATS_READ",
            type: "read",
          },
        },
        {
          title: "Statistiques - Pertes - Prospects/Clients",
          read: "STATS_LOST_PROSPECTS_CLIENTS_READ",
          info: "L'onglet Statistiques - Pertes - Prospects/Clients affiche les statistiques des prospects/clients perdus.",
          parent: {
            name: "STATS_LOST_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Statistiques - Pertes - Sources",
          read: "STATS_LOST_SOURCES_READ",
          info: "L'onglet Statistiques - Pertes - Sources affiche les statistiques des clients perdus selon les sources marketing.",
          parent: {
            name: "STATS_LOST_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Statistiques - Pertes - Carte",
          read: "STATS_LOST_MAP_READ",
          info: "L'onglet Statistiques - Pertes - Carte affiche la carte des prospects/clients perdus.",
          parent: {
            name: "STATS_LOST_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Statistiques - Commerciaux",
          read: "STATS_COMMERCIALS_READ",
          info: "L'onglet Statistiques - Commercials permet d'afficher differents onglet concernant les commerciaux",
          parent: {
            name: "STATS_READ",
            type: "read",
          },
        },
        {
          title: "Statistiques - Statistiques Commerciaux",
          read: "STATS_STATS_COMMERCIALS_READ",
          info: "L'onglet Statistiques - Commercials permet d'afficher les statistiques concernant les commerciaux",
          parent: {
            name: "STATS_COMMERCIALS_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Statistiques - Acquisition/Fidélisation",
          read: "STATS_ACQUISITION_READ",
          info: "L'onglet Statistiques - Acquisition/Fidélisation permet d'afficher les statistiques concernant le chiffre d'affaire.",
          parent: {
            name: "STATS_COMMERCIALS_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Statistiques - Paiements",
          read: "STATS_PAYMENTS_READ",
          info: "L'onglet Statistiques - Paiements permet d'afficher les statistiques concernant les paiements encaissés.",
          parent: {
            name: "STATS_COMMERCIALS_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Statistiques - Attribution",
          read: "STATS_ATTRIBUTION_READ",
          info: "L'onglet Statistiques - Attribution permet d'afficher les statistiques concernant les attributions.",
          parent: {
            name: "STATS_COMMERCIALS_READ",
            type: "read",
          },
          level: 2,
        },
        {
          title: "Statistiques - Parrainage",
          read: "STATS_SPONSORSHIP_READ",
          info: "L'onglet Statistiques - Parrainage permet d'afficher les statistiques concernant le CA généré par les parrainages (hors Live).",
          parent: {
            name: "STATS_READ",
            type: "read",
          },
        },
        {
          title: "Statistiques - Live",
          read: "STATS_LIVE_READ",
          info: "L'onglet Statistiques - Live permet d'afficher les statistiques concernant les données de l'application Just Coaching Live.",
          parent: {
            name: "STATS_READ",
            type: "read",
          },
        },
        {
          title: "Statistiques - CA Ratios",
          read: "STATS_CA_RATIOS_READ",
          info: "L'onglet Statistiques - CA Ratios permet d'afficher les statistiques concernant le ca grâce à la consultation de graphique.",
          parent: {
            name: "STATS_READ",
            type: "read",
          },
        },
        {
          title: "[APP COACH] Accès admin",
          read: "COACH_APP_ADMIN_ACCESS_READ",
          write: "COACH_APP_ADMIN_ACCESS_WRITE",
          info: "Permet d'accéder à l'application Coach en tant qu'administrateur et d'utiliser les comptes des coachs à leur place.",
        },
      ],
    };
  },
  async created() {
    const { data } = await this.$api
      .get("/users/coachs/" + this.$route.params.id)
      .catch(() => {
        this.$router.push({
          name: "users",
        });
      });

    if (!data.coach) {
      data.coach = {
        name: "",
      };
    }

    if (!data.commercial) {
      data.commercial = {
        isCommercial: false,
      };
    }

    if (!data.coachManager) {
      data.coachManager = {
        isCoachManager: false,
      };
    }

    this.user = data;
  },
  computed: {
    getImg() {
      return `${axios.defaults.baseURL}users/${this.user.id}/commercial-picture`;
    },

    getCoachManagerImg() {
      return `${axios.defaults.baseURL}users/${this.user.id}/coach-manager-picture`;
    },

    ...mapGetters({
      coachStatus: "getCoachStatus",
    }),

    currentStatus() {
      if (this.user.coach) {
        const status = this.coachStatus.find(
          (status) => this.user.coach.status === status.value
        );

        return status;
      }

      return null;
    },
  },
  methods: {
    handleStatus(event) {
      this.$confirm({
        message: "Attention, cela va changer le statut de l'équipier.",
        onConfirm: async () => {
          try {
            const { data } = await this.$api.put("/users", {
              email: this.user.email,
              coach: {
                status: event,
              },
            });

            this.user = data;
          } catch (e) {
            this.$errorToast(e);
          }
        },
      });
    },

    updateCommercialPhoto(img) {
      let data = {
        email: this.user.email,
        commercial: {
          isCommercial: this.user.commercial.isCommercial,
          img,
        },
      };

      this.$api.put("/users", data).then(() => {
        this.user.commercial.img = img;
      });
    },

    async editFirstName(value) {
      await this.$api.put("/users", {
        email: this.user.email,
        firstName: value,
      });
    },

    async editLastName(value) {
      await this.$api.put("/users", {
        email: this.user.email,
        lastName: value,
      });
    },

    async editCommercial(commercial) {
      const data = {
        email: this.user.email,
        commercial,
      };

      await this.$api.put("/users", data);
    },

    async editCommercialDate(field, event) {
      const data = {
        email: this.user.email,
        commercial: {
          [field]: moment(event).toDate(),
        },
      };

      await this.$api.put("/users", data);
    },

    toggleCommercial() {
      this.user.commercial.isCommercial = !this.user.commercial.isCommercial;
      let data = {
        email: this.user.email,
        commercial: {
          isCommercial: this.user.commercial.isCommercial,
        },
      };
      this.$api.put("/users", data).then(() => {});
    },

    updateCoachManagerPhoto(img) {
      let data = {
        email: this.user.email,
        coachManager: {
          isCoachManager: this.user.coachManager.isCoachManager,
          img,
        },
      };

      this.$api.put("/users", data).then(() => {
        this.user.coachManager.img = img;
      });
    },

    async editCoachManager(coachManager) {
      const data = {
        email: this.user.email,
        coachManager,
      };

      await this.$api.put("/users", data);
    },

    async editCoachManagerDate(field, event) {
      let date = moment(event).endOf("day").toDate();

      if (field === "excludedPeriodBegin") {
        date = moment(event).startOf("day").toDate();
      }

      const data = {
        email: this.user.email,
        coachManager: {
          [field]: date,
        },
      };

      await this.$api.put("/users", data);
    },

    toggleCoachManager() {
      this.user.coachManager.isCoachManager =
        !this.user.coachManager.isCoachManager;
      let data = {
        email: this.user.email,
        coachManager: {
          isCoachManager: this.user.coachManager.isCoachManager,
        },
      };
      this.$api.put("/users", data).then(() => {});
    },

    toggleRole(event) {
      this.$confirm({
        message: "Attention, cela va changer le statut Administrateur !",
        onConfirm: async () => {
          let newRole = this.user.role;

          if (event) {
            newRole = newRole | this.getRole("admin");
          } else {
            newRole = newRole & ~this.getRole("admin");
            newRole = newRole | this.getRole("member");
          }

          try {
            const { data } = await this.$api.put("/users", {
              email: this.user.email,
              role: newRole,
            });

            this.user = data;
          } catch (e) {
            this.$errorToast(e);
          }
        },
      });
    },

    async loginToUser() {
      const { data } = await this.$api.put("/users/signin", {
        email: this.user.email,
      });

      this.$store.commit("AUTH_TOKEN", data.token);
      window.location.reload(true);
    },

    togglePermission(permission) {
      let permissions = this.user.permissions || [];

      if (permissions.includes(permission)) {
        permissions = permissions.filter((ele) => ele !== permission);
      } else {
        permissions.push(permission);
      }

      const userHasPermission = permissions.includes(permission);
      permissions = this.checkIfIsParentPermission(
        permissions,
        permission,
        userHasPermission
      );

      this.$api
        .put("/users", {
          email: this.user.email,
          permissions: permissions,
        })
        .then((res) => {
          this.user = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    checkIfIsParentPermission(permissions, permission, userHasPermission) {
      const children = this.settings.filter(
        (setting) => setting.parent?.name === permission
      );

      if (children.length > 0) {
        for (const child of children) {
          const type = child.parent.type;
          const childPermission = type === "read" ? child.read : child.write;
          const userHasChildPermission = permissions.includes(childPermission);

          if (userHasPermission && !userHasChildPermission) {
            permissions.push(childPermission);
          } else if (!userHasPermission && userHasChildPermission) {
            permissions = permissions.filter(
              (perm) => perm !== childPermission
            );
          }
        }
      }

      return permissions;
    },

    permissionToggleDisabled(setting, type) {
      if (!this.hasPermission(this.$store.state.user, "SETTINGS_TEAM_WRITE")) {
        return true;
      }

      if (setting.parent?.type === type) {
        return (
          !this.user.permissions ||
          (this.user.permissions &&
            !this.user.permissions.includes(setting.parent.name))
        );
      }

      return false;
    },

    handleChangePassword() {
      let data = {
        email: this.user.email,
        password: this.form.password,
      };
      this.$api
        .put("/users", data)
        .then(() => {
          this.form.password = null;
          this.modalChangePassword = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSettingsClass(settings) {
      if (settings.level) {
        return `pl-${settings.level + 1}`;
      }

      if (settings.parent) {
        return "pl-1";
      }

      return "";
    },
  },
};
</script>

<style lang="css" scoped>
table.permissions th,
table.permissions td {
  padding-right: 32px;
  padding-bottom: 12px;
}
</style>
