var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-block"},[_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-4"},[_vm._v("Disponibilités")]),_c('dd',{staticClass:"col-sm-8"},[(_vm.checkPermission)?_c('el-input',{class:{
            'has-error': !_vm.client.disponibility && !_vm.isCollectivity,
          },attrs:{"type":"textarea"},on:{"change":function($event){return _vm.editClient('disponibility', $event)},"blur":_vm.handleBlur},model:{value:(_vm.client.disponibility),callback:function ($$v) {_vm.$set(_vm.client, "disponibility", $$v)},expression:"client.disponibility"}}):_c('span',[_vm._v(_vm._s(_vm.client.disponibility))])],1),_c('dt',{staticClass:"col-sm-4"},[_vm._v("Objectif")]),_c('dd',{staticClass:"col-sm-8"},[(_vm.checkPermission)?_c('el-input',{class:{
            'has-error': !_vm.client.objectif && !_vm.isCollectivity,
          },attrs:{"type":"textarea"},on:{"change":function($event){return _vm.editClient('objectif', $event)},"blur":_vm.handleBlur},model:{value:(_vm.client.objectif),callback:function ($$v) {_vm.$set(_vm.client, "objectif", $$v)},expression:"client.objectif"}}):_c('span',[_vm._v(_vm._s(_vm.client.objectif))])],1),_c('dt',{staticClass:"col-sm-4"},[_vm._v("Détails objectif")]),_c('dd',{staticClass:"col-sm-8"},[(_vm.checkPermission)?_c('el-input',{class:{
            'has-error': !_vm.client.clientNotes && !_vm.isCollectivity,
          },attrs:{"type":"textarea"},on:{"change":function($event){return _vm.editClient('clientNotes', $event)},"blur":_vm.handleBlur},model:{value:(_vm.client.clientNotes),callback:function ($$v) {_vm.$set(_vm.client, "clientNotes", $$v)},expression:"client.clientNotes"}}):_c('span',[_vm._v(_vm._s(_vm.client.clientNotes))])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }