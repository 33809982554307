<template>
  <div v-if="cols.length > 0" class="mb-2 marketing-custom-wrapper">
    <el-table :data="cols" style="width: 100%">
      <el-table-column
        sortable
        prop="marketingValue"
        :label="label"
        width="160"
      ></el-table-column>
      <el-table-column
        align="center"
        sortable
        prop="caAcquisition"
        label="C.A Acqui."
        width="150"
      >
        <template slot-scope="scope">
          {{ scope.row.caAcquisition }} €
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        sortable
        prop="ca"
        label="C.A TOTAL"
        width="150"
      >
        <template slot-scope="scope"> {{ scope.row.ca }} € </template>
      </el-table-column>

      <el-table-column align="center" sortable prop="amount" label="Dépenses">
        <template slot-scope="scope"> {{ scope.row.amount }} € </template>
      </el-table-column>
      <el-table-column align="center" width="160" sortable label="ROAS">
        <template slot-scope="scope">
          <span v-if="scope.row.ca !== 0 && scope.row.amount !== 0">
            {{ (scope.row.ca / scope.row.amount).toFixed(2) }} € ({{
              ((scope.row.ca / scope.row.amount) * 100).toFixed(2)
            }}%)
          </span>
          <span v-else-if="scope.row.amount === 0"></span>
          <span v-else-if="scope.row.ca === 0">(0%)</span>
          <span
            style="display: inline-block; margin-left: 2px"
            class="fa fa-info-circle"
            v-tooltip="{
              content: `Pour 1€ dépensé = ${(
                scope.row.ca / scope.row.amount
              ).toFixed(2)}€ de CA (soit ${(
                (scope.row.ca / scope.row.amount) *
                100
              ).toFixed(2)}% de retour sur investissement)`,
            }"
          ></span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        sortable
        prop="nbClientsProspects"
        label="Formulaires"
      >
      </el-table-column>
      <el-table-column
        align="center"
        sortable
        prop="nbProspectsConvert"
        label="Prospects Convertis"
      >
        <template slot-scope="scope">
          {{ scope.row.nbProspectsConvert }}
          <span class="small">
            ({{
              (
                (scope.row.nbProspectsConvert / scope.row.nbClientsProspects) *
                100
              ).toFixed(2)
            }}%)
          </span>
        </template></el-table-column
      >
      <el-table-column align="center" sortable prop="nbClients" label="Clients">
        <template slot-scope="scope">
          {{ scope.row.nbClients }}
          <span class="small">
            ({{
              (
                (scope.row.nbClients / scope.row.nbProspectsConvert) *
                100
              ).toFixed(2)
            }}%)
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        sortable
        prop="nbProspectsLosts"
        label="Perdus"
      >
        <template slot-scope="scope">
          {{ scope.row.nbProspectsLosts }}
          <span class="small">
            ({{
              (
                (scope.row.nbProspectsLosts / scope.row.nbClientsProspects) *
                100
              ).toFixed(2)
            }}%)
          </span>
          <span
            style="display: inline-block; margin-left: 2px"
            class="fa fa-info-circle"
            v-tooltip="{ content: scope.row.lostReasons }"
          ></span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        sortable
        prop="conversionRate"
        label="Taux de conversion"
        width="180"
      >
        <template slot-scope="scope">
          {{ scope.row.conversionRate.toFixed(2) }}%
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    cols: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.marketing-custom-wrapper {
  max-width: calc(100vw - 450px);
}

.marketing-custom-wrapper {
  table {
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .marketing-custom-wrapper {
    max-width: calc(100vw - 70px);
  }
}

.aside-menu-fixed.sidebar-hidden .marketing-custom-wrapper {
  max-width: calc(100vw - 70px);
}
</style>
