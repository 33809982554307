<template>
  <div>
    <div :class="`custom-card custom-card-${this.type}`">
      <div class="left-icon-btn" @click="displaySubTab = !displaySubTab">
        <i class="fa" :class="displaySubTab ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div class="card-data">
        <div class="card-column">
          <span>{{ coachs.length }}</span>
          <span>Coachs</span>
        </div>
        <div class="card-column">
          <span>{{ fidelisationRate }}%</span>
          <span>Fidelisation</span>
        </div>
      </div>
    </div>
    <div v-if="displaySubTab" :class="`subtab subtab-${this.type}`">
      <el-table :data="coachsSorted">
        <el-table-column label="Nom" prop="name" sortable>
          <template slot-scope="scope">
            <span class="user-link" @click="(e) => goToCoach(e, scope.row.id)">
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Classement" prop="rank" sortable>
          <template slot-scope="scope">
            {{ scope.row.rank }}
          </template>
        </el-table-column>
        <el-table-column label="Département" prop="department" sortable>
          <template slot-scope="scope">
            {{ scope.row.department.substring(0, 2) }}
          </template>
        </el-table-column>
        <el-table-column label="Taux de fidelisation" prop="tf" sortable>
          <template slot-scope="scope">
            {{ scope.row.tf?.toFixed(2) }}%
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coachs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      displaySubTab: false,
    };
  },

  computed: {
    coachsSorted() {
      const sorted = this.coachs;

      sorted.sort((a, b) => {
        return b.tf - a.tf;
      });

      // coach rank based on tf value
      let currentRank = 1;
      let sameRankCount = 1;

      sorted.forEach((coach, index) => {
        if (index === 0) {
          coach.rank = currentRank;
        } else if (coach.tf === sorted[index - 1].tf) {
          coach.rank = currentRank;
        } else {
          currentRank += sameRankCount;
          coach.rank = currentRank;
          sameRankCount = 1;
        }
      });

      return sorted;
    },
    fidelisationRate() {
      if (this.coachs.length === 0) return 0;
      return (
        this.coachs.reduce((acc, coach) => {
          return acc + parseFloat(coach.tf);
        }, 0) / this.coachs.length
      ).toFixed(2);
    },
  },

  methods: {
    goToCoach(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "user-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },
  },
};
</script>

<style scoped>
.custom-card {
  position: relative;
  display: flex;
  border-radius: 15px;
  align-items: center;
  color: white;
  z-index: 5;
}
.card-data {
  position: relative;
  display: flex;
  border-radius: 15px;
  justify-content: space-around;
  align-items: center;
  color: white;
  z-index: 5;
  padding: 0 20px;
  flex-wrap: wrap;
  flex-grow: 1;
}
.custom-card-success {
  background-color: #28a745;
}

.custom-card-warning {
  background-color: #ffc107;
}

.custom-card-danger {
  background-color: #dc3545;
}

.left-icon-btn {
  border-right: thick solid white;
  padding: 30px 20px;
  cursor: pointer;
}

.subtab {
  position: relative;
  margin-top: -20px;
  padding: 15px;
  padding-top: 35px;
  border-radius: 10px;
  max-height: 400px;
  overflow: auto;
  z-index: 1;
}

.subtab-success {
  border-right: 1px solid #28a745;
  border-left: 1px solid #28a745;
  border-bottom: 1px solid #28a745;
}

.subtab-warning {
  border-right: 1px solid #ffc107;
  border-left: 1px solid #ffc107;
  border-bottom: 1px solid #ffc107;
}

.subtab-danger {
  border-right: 1px solid #dc3545;
  border-left: 1px solid #dc3545;
  border-bottom: 1px solid #dc3545;
}

.card-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
}
</style>
