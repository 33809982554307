<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h3>
          Statistique glissante
          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content: tooltip.rollingStats,
              placement: 'top',
            }"
          ></i>
        </h3>
        <div class="d-flex align-items-center">
          <div
            class="cursor-pointer"
            @click="openTab = !openTab"
            v-if="stats.length > 0"
          >
            <span>
              <i :class="openTab ? 'icon-arrow-down' : 'icon-arrow-right'"></i>
            </span>
          </div>
          <div class="row ml-1">
            <div class="form-group col-sm-6">
              <label>
                Date de début
                <i
                  class="fa fa-info-circle ml-05"
                  v-tooltip="{
                    content: tooltip.rollingStatsDateFilter,
                    placement: 'top',
                  }"
                ></i>
              </label>
              <datepicker
                v-model="form.begin"
                format="dd.MM.yyyy"
                language="fr"
                input-class="form-control"
              ></datepicker>
            </div>
            <div class="form-group col-sm-6">
              <label>Date de fin</label>
              <datepicker
                v-model="form.end"
                format="dd.MM.yyyy"
                language="fr"
                input-class="form-control"
              ></datepicker>
            </div>
            <div class="col-md-12">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                @click="updateForm()"
              >
                <i class="icon-eye" aria-hidden="true"></i>&nbsp; Visualiser
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-block" v-if="openTab">
        <div class="py-1" v-for="stat of stats" :key="stat.commercial.id">
          <div
            class="rounded p-1"
            :style="`background-color:${stat.commercial.color}`"
          >
            <el-checkbox
              v-if="stat.active !== undefined"
              v-model="stat.active"
            ></el-checkbox>
            <strong
              class="ml-1"
              :class="!stat.active ? 'text-gray-dark' : ''"
              >{{ stat.commercial.pseudo }}</strong
            >
          </div>
          <div class="row" v-if="stat.active || stat.commercial.id === 'total'">
            <div class="col-md-2 mt-1 separator">
              <div class="stat-table">
                <div>
                  <div>
                    <span class="font-weight-bold">Formulaires:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.nbForms,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.nbForms }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Découverte crées:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.nbDiscovery,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.nbDiscovery }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Découvertes crées:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.createDiscoveryPercent,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ getCreateDiscoveryPercent(stat) }}%</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Découverte payées:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.nbPayedDiscovery,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.nbPayedDiscovery }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Découvertes payées:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.discoveryPayedPercent,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ getDiscoveryPayedPercent(stat) }}%</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">CA découverte:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.caDiscovery,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ formatPrice(stat.caDiscovery, true) }}</div>
                </div>

                <div>
                  <div>
                    <span class="font-weight-bold">% acquisition:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.acquisitionPercent,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ getAcquisitionPercent(stat) }} %</div>
                </div>
              </div>
            </div>
            <div class="col-md-2 mt-1 separator">
              <div class="stat-table">
                <div>
                  <div>
                    <span class="font-weight-bold">Nb pack conversion:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.nbPackConversion,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.nbPackConversion }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Pack moyen:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.averageConversionSessions,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>
                    {{ stat.averageConversionSessions }}
                    {{
                      stat.averageConversionSessions > 1 ? "séances" : "séance"
                    }}
                  </div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">% conversion:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.conversionPercent,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ getConversionPercent(stat) }} %</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">CA conversion:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.caConversion,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ formatPrice(stat.caConversion, true) }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">CA encaissé:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.caConversionPayed,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ formatPrice(stat.caConversionPayed, true) }}</div>
                </div>

                <div>
                  <div>
                    <span class="font-weight-bold">% transformation:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.transformationPercent,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ getTransformationPercent(stat) }} %</div>
                </div>
              </div>
            </div>
            <div class="col-md-2 mt-1 separator">
              <div class="stat-table">
                <div>
                  <div>
                    <span class="font-weight-bold">Nb pack finis:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.nbEndedPack,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.nbEndedPack }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Nb pack traités:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.nbTreatedPack,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.nbTreatedPack }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Nb pack fidélisés:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.nbFidelisedPack,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>
                    {{ stat.nbFidelisedPack }}
                  </div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">% fidélisations:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.fidelisationPercent,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ getFidelisationPercent(stat) }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Pack moyen:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.nbAverageSessionFidelisedPack,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.nbAverageSessionFidelisedPack }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">CA Fidelisation</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.caFidelisation,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ formatPrice(stat.caFidelisation, true) }}</div>
                </div>
              </div>
            </div>

            <div class="col-md-3 mt-1 separator">
              <div class="stat-table">
                <div>
                  <div>
                    <span class="font-weight-bold"
                      >CA Total (Hors découverte):</span
                    >
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.cumulateCa,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ formatPrice(stat.cumulateCa, true) }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Panier moyen:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.averageCa,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ formatPrice(stat.averageCa, true) }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Pack moyen:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.averagePack,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>
                    {{ stat.averagePack }}
                    {{ stat.averagePack > 1 ? "séances" : "séance" }}
                  </div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold"
                      >Moyenne des échéances de paiements:</span
                    >
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.averagePayments,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.averagePayments }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Nb échéances Pack 100:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.average100Payments,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.average100Payments }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Nb échéances Pack 40:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.average40Payments,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.average40Payments }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Nb échéances Pack 20:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.average20Payments,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.average20Payments }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold">Nb échéances Pack 10:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.average10Payments,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.average10Payments }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold"
                      >CA Total (découverte incluse):</span
                    >
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.cumulateCaWithDiscovery,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>
                    {{ formatPrice(stat.cumulateCa + stat.caDiscovery, true) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 mt-1">
              <div class="stat-table">
                <div>
                  <div>
                    <span class="font-weight-bold">Prospects perdus:</span>
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.nbLostProspects,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.nbLostProspects }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold"
                      >Pertes après commande découverte:</span
                    >
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.nbLostAfterDiscovery,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.nbLostAfterDiscovery }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold"
                      >Pertes après commande conversion:</span
                    >
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.nbLostAfterConversion,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.nbLostAfterConversion }}</div>
                </div>
                <div>
                  <div>
                    <span class="font-weight-bold"
                      >Pertes après commande fidélisation:</span
                    >
                    <i
                      v-if="stat.commercial.id === 'total'"
                      class="fa fa-info-circle ml-05"
                      v-tooltip="{
                        content: tooltip.nbLostAfterFidelisation,
                        placement: 'top',
                      }"
                    ></i>
                  </div>
                  <div>{{ stat.nbLostAfterFidelisation }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  props: {
    defaultStat: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
    },
  },

  data() {
    return {
      toggleEdit: false,
      openTab: false,
      tooltip: {
        rollingStats: "Statistiques dans l'intervalle de temps choisi",
        rollingStatsDateFilter:
          "Concerne, la date de création d'un prospect/client dans la période, la date de création de la facture d'une commande, la date des séances programmées, la date des séances finies, la date des prospects perdus, pour chaque type de données recherché",
        nbForms: "Nombre de formulaires créés",
        nbDiscovery: "Nombre de découvertes créées",
        createDiscoveryPercent:
          "Pourcentage de découvertes créées par rapport au nombre de formulaires",
        nbPayedDiscovery: "Nombre de découvertes payées",
        discoveryPayedPercent:
          "Pourcentage de découvertes payées par rapport au nombre de découvertes créées",
        caDiscovery: "Chiffre d'affaires des découvertes",
        acquisitionPercent:
          "Pourcentage de découvertes payées par rapport au nombre de formulaires",
        nbPackConversion: "Nombre de packs convertis",
        averageConversionSessions:
          "Nombre moyen de séances des packs conversion",
        conversionPercent:
          "Nombre de packs convertis par rapport aux découvertes",
        caConversion: "Chiffre d'affaires des conversions",
        caConversionPayed: "Chiffre d'affaires des conversions payées",
        transformationPercent:
          "Pourcentage de packs convertis par rapport au nombre de formulaires",
        nbEndedPack:
          "Nombre de packs finis (dont la dernière session a été faite dans la période)",
        nbTreatedPack:
          "Nombre de packs finis ayant été traités dans le menu 'Fidelisation'",
        nbFidelisedPack:
          "Nombre de packs fidélisés (dans le menu 'Fidélisation')",
        fidelisationPercent:
          "Pourcentage de packs fidélisés par rapport aux packs finis",
        nbAverageSessionFidelisedPack:
          "Nombre moyen de séances des packs fidélisés",
        caFidelisation: "Chiffre d'affaires des fidélisations",
        cumulateCa: "Chiffre d'affaires total (hors découvertes)",
        averageCa: "Panier moyen des commandes conversions et fidélisations",
        averagePack:
          "Nombre de séances moyenne des packs conversions et fidélisations",
        averagePayments:
          "Nombre d'échéances moyen des commandes conversions et fidélisations",
        average100Payments: "Nombre de paiements moyen pour les packs: 100",
        average40Payments: "Nombre de paiements moyen pour les packs: 40",
        average20Payments: "Nombre de paiements moyen pour les packs: 20",
        average10Payments: "Nombre de paiements moyen pour les packs: 10",
        cumulateCaWithDiscovery:
          "Chiffre d'affaires total (découverte incluse)",
        nbLostProspects: "Nombre de prospects perdus dans la pèriode",
        nbLostAfterDiscovery:
          "On compte le nombre de client ayant finit un pack découverte dans la période et n'ayant pas signé de pack conversion",
        nbLostAfterConversion:
          "On compte le nombre de client ayant finit un pack conversion dans la période et n'ayant pas signé de pack fidélisation",
        nbLostAfterFidelisation:
          "On compte le nombre de client ayant finit un pack fidélisation dans la période et n'ayant pas signé de pack supplémentaire",
      },
    };
  },

  computed: {
    stats() {
      if (this.defaultStat.length === 0) return [];

      const stats = this.defaultStat.filter((stat) => stat.active);

      return [
        {
          commercial: {
            id: "total",
            pseudo: "Total",
            color: "#808080",
          },
          nbForms: stats.reduce((acc, stat) => acc + stat.nbForms, 0),
          nbDiscovery: stats.reduce((acc, stat) => acc + stat.nbDiscovery, 0),
          nbPayedDiscovery: stats.reduce(
            (acc, stat) => acc + stat.nbPayedDiscovery,
            0
          ),
          caDiscovery: stats.reduce((acc, stat) => acc + stat.caDiscovery, 0),
          nbPackConversion: stats.reduce(
            (acc, stat) => acc + stat.nbPackConversion,
            0
          ),
          averageConversionSessions: (
            stats.reduce(
              (acc, stat) => acc + parseFloat(stat.averageConversionSessions),
              0
            ) / stats.length
          ).toFixed(2),
          caConversion: stats.reduce((acc, stat) => acc + stat.caConversion, 0),
          caConversionPayed: stats.reduce(
            (acc, stat) => acc + stat.caConversionPayed,
            0
          ),
          nbEndedPack: stats.reduce((acc, stat) => acc + stat.nbEndedPack, 0),
          nbTreatedPack: stats.reduce(
            (acc, stat) => acc + stat.nbTreatedPack,
            0
          ),
          nbFidelisedPack: stats.reduce(
            (acc, stat) => acc + stat.nbFidelisedPack,
            0
          ),
          nbAverageSessionFidelisedPack: (
            stats.reduce(
              (acc, stat) =>
                acc + parseFloat(stat.nbAverageSessionFidelisedPack),
              0
            ) / stats.length
          ).toFixed(2),
          caFidelisation: stats.reduce(
            (acc, stat) => acc + stat.caFidelisation,
            0
          ),
          cumulateCa: stats.reduce((acc, stat) => acc + stat.cumulateCa, 0),
          averageCa: (
            stats.reduce((acc, stat) => acc + parseFloat(stat.averageCa), 0) /
            stats.length
          ).toFixed(2),
          averagePack: (
            stats.reduce((acc, stat) => acc + parseFloat(stat.averagePack), 0) /
            stats.length
          ).toFixed(2),
          averagePayments: (
            stats.reduce(
              (acc, stat) => acc + parseFloat(stat.averagePayments),
              0
            ) / stats.length
          ).toFixed(2),
          average100Payments: (
            stats.reduce(
              (acc, stat) => acc + parseFloat(stat.average100Payments),
              0
            ) / stats.length
          ).toFixed(2),
          average40Payments: (
            stats.reduce(
              (acc, stat) => acc + parseFloat(stat.average40Payments),
              0
            ) / stats.length
          ).toFixed(2),
          average20Payments: (
            stats.reduce(
              (acc, stat) => acc + parseFloat(stat.average20Payments),
              0
            ) / stats.length
          ).toFixed(2),
          average10Payments: (
            stats.reduce(
              (acc, stat) => acc + parseFloat(stat.average10Payments),
              0
            ) / stats.length
          ).toFixed(2),
          nbLostProspects: stats.reduce(
            (acc, stat) => acc + stat.nbLostProspects,
            0
          ),
          nbLostAfterDiscovery: stats.reduce(
            (acc, stat) => acc + stat.nbLostAfterDiscovery,
            0
          ),
          nbLostAfterConversion: stats.reduce(
            (acc, stat) => acc + stat.nbLostAfterConversion,
            0
          ),
          nbLostAfterFidelisation: stats.reduce(
            (acc, stat) => acc + stat.nbLostAfterFidelisation,
            0
          ),
        },
      ].concat(this.defaultStat);
    },
  },

  methods: {
    updateForm() {
      this.$emit("updateForm", this.form);
      this.openTab = true;
    },
    getCreateDiscoveryPercent(stat) {
      return stat.nbForms > 0
        ? ((stat.nbDiscovery / stat.nbForms) * 100).toFixed(2)
        : 0;
    },

    getDiscoveryPayedPercent(stat) {
      return stat.nbDiscovery > 0
        ? ((stat.nbPayedDiscovery / stat.nbDiscovery) * 100).toFixed(2)
        : 0;
    },

    getAcquisitionPercent(stat) {
      return stat.nbForms > 0
        ? ((stat.nbPayedDiscovery / stat.nbForms) * 100).toFixed(2)
        : 0;
    },

    getConversionPercent(stat) {
      return stat.nbPayedDiscovery > 0
        ? ((stat.nbPackConversion / stat.nbPayedDiscovery) * 100).toFixed(2)
        : 0;
    },

    getTransformationPercent(stat) {
      return stat.nbForms > 0
        ? ((stat.nbPackConversion / stat.nbForms) * 100).toFixed(2)
        : 0;
    },

    getFidelisationPercent(stat) {
      return stat.nbEndedPack > 0
        ? ((stat.nbFidelisedPack / stat.nbEndedPack) * 100).toFixed(2)
        : 0;
    },
  },
};
</script>

<style type="text/css" scoped>
.stat-table > div {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.rounded {
  border-radius: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.separator {
  border-right: 1px solid black;
}
</style>
