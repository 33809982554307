<template>
  <div class="row animated fade-in">
    <div class="col-md-12">
      <Loader :isVisible="isLoading" />
      <StatsCommercialsV2Periods
        :defaultStat="statsCommercials"
        :form="form"
        @updateForm="updateForm"
      />
      <StatsCommercialsV2Static
        :defaultStat="staticStatsCommercials"
        :form="staticForm"
        @updateForm="updateStaticForm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Loader from "../components/Loader";
import StatsCommercialsV2Periods from "../components/StatsCommercialsV2Periods";
import StatsCommercialsV2Static from "../components/StatsCommercialsV2Static";

export default {
  components: {
    Loader,
    StatsCommercialsV2Periods,
    StatsCommercialsV2Static,
  },

  data() {
    return {
      page: 1,
      isLoading: false,
      statsCommercials: [],
      staticStatsCommercials: [],
      form: {
        begin: moment().subtract(6, "month").startOf("month").toDate(),
        end: moment().endOf("month").toDate(),
      },
      staticForm: {
        month: 8,
        year: 2024,
      },
    };
  },

  async created() {},

  computed: {},

  methods: {
    async getStaticStatCommercials() {
      this.isLoading = true;

      try {
        this.staticStatsCommercials = [];

        const { data } = await this.$api.get("/stats/commercials-v2", {
          params: {
            month: this.staticForm.month,
            year: this.staticForm.year,
          },
        });

        this.staticStatsCommercials = data.statCommercialDatasV2.map((stat) => {
          return {
            ...stat,
            active: true,
          };
        });
      } catch (error) {
        this.isLoading = false;
        if (error.response.status === 404) {
          this.$toast.error("Aucune donnée disponible pour cette période");
        }
      }

      this.isLoading = false;
    },

    async getStatCommercials() {
      this.statsCommercials = [];

      this.isLoading = true;
      const { data } = await this.$api.get("/stats/commercials-v2/periods", {
        params: {
          begin: this.form.begin,
          end: this.form.end,
        },
      });

      this.statsCommercials = data.map((stat) => ({
        ...stat,
        active: true,
      }));
      this.isLoading = false;
    },

    async updateForm(form) {
      this.form = form;

      await this.getStatCommercials();
    },

    async updateStaticForm(form) {
      this.staticForm = form;

      await this.getStaticStatCommercials();
    },
  },
};
</script>

<style type="text/css"></style>
