<template>
  <modal
    title="Ajouter une commande marché"
    effect="fade/zoom"
    cancelText="Fermer"
    okText="Enregistrer"
    v-model="isOpen"
    @ok="newMarketOrder"
    @cancel="$emit('closeModal')"
  >
    <Loader :is-visible="loading" />
    <div slot="modal-header" class="modal-header row m-0">
      <h4 class="modal-title col-8">Ajouter une commande marché</h4>

      <div
        class="col-4 d-flex flex-column align-items-end"
        style="padding-top: 17px"
      >
        <div class="pull-right d-flex flex-wrap justify-content-end">
          <div class="form-group d-inline-block mr-1">
            <div class="form-group d-inline-block">
              <span class="mr-1"><i class="icon-envelope"></i> Email</span>
              <label
                class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
              >
                <input
                  type="checkbox"
                  class="switch-input"
                  v-model="form.sendEmail"
                  :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
                />
                <span class="switch-label" data-on="On" data-off="Off"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-8">
        <label>Pack</label>
        <v-select v-model="form.session" :options="sessions"></v-select>
      </div>
      <div class="form-group col-sm-4" style="padding-top: 14px">
        Pointage des participants
        <label
          class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
        >
          <input
            type="checkbox"
            class="switch-input"
            v-model="form.requiredSessionUserCount"
            :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
          />
          <span class="switch-label" data-on="On" data-off="Off"></span>
          <span class="switch-handle"></span>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label> Description commande (visible facturation) </label>

        <v-select
          style="margin-bottom: 5px"
          :options="client.orderDescriptions"
          v-model="client.billing.label"
          @input="updateBillingDescriptionForB2B"
        ></v-select>

        <textarea
          v-model="client.billing.description"
          type="text"
          class="form-control"
        />
      </div>
      <div class="form-group col-sm-12">
        <label> Label de la commande (visible coach) </label>

        <textarea v-model="form.label" type="text" class="form-control" />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Loader from "./Loader.vue";

export default {
  components: {
    Modal,
    Loader,
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    clientData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      isOpen: false,
      sessions: [],
      client: null,

      form: {
        client: null,
        description: null,
        label: null,
        requiredSessionUserCount: null,
        sendEmail: true,
        session: null,
      },

      formErrors: {
        client: null,
        session: null,
      },
    };
  },

  created() {
    this.client = this.clientData;

    for (let i = 1; i <= 200; i++) {
      this.sessions.push({
        label: this.formatPack(i.toString()),
        value: i.toString(),
      });

      if (!this.form.session) {
        this.form.session = this.sessions[0];
      }
    }
  },

  watch: {
    isShow(val) {
      this.isOpen = val;
    },

    clientData(val) {
      this.client = val;
    },
  },

  methods: {
    updateBillingDescriptionForB2B(value) {
      this.client.billing = {
        ...this.client.billing,
        description: value ? value.description : "",
        label: value ? value.label : "",
      };
    },

    async newMarketOrder() {
      this.form.client = this.client.email;
      this.form.description = this.client.billing.description;

      if (!this.form.client) {
        this.$errorToast("Les informations du client sont manquantes");
        return;
      }

      try {
        this.loading = true;

        const { data } = await this.$api.post("/orders/market-establishment", {
          client: this.form.client,
          labelDescription: this.client.billing.label,
          description: this.form.description,
          label: this.form.label,
          requiredSessionUserCount: this.form.requiredSessionUserCount,
          session: this.form.session.value,
        });

        if (this.form.sendEmail) {
          this.$confirm({
            message: "Envoyer un email pour informer le client ?",
            onConfirm: async () => {
              await this.$api.post(`/orders/send-creation-email/${data.id}`);
            },
          });
        }

        this.loading = false;
        this.$emit("close");
      } catch (e) {
        this.$errorToast("La création de la commande a échoué");
      }
    },
  },
};
</script>
