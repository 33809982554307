var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.orders},on:{"sort-change":_vm.sort}},[_c('el-table-column',{attrs:{"label":"État"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"badge",class:_vm.getBadgeClass(scope.row)},[_vm._v(" "+_vm._s(`${scope.row.completed} / ${scope.row.nbSessions}`)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Grp","prop":"client.companyRef"}}),_c('el-table-column',{attrs:{"label":"Prénom","prop":"client.firstName"}}),_c('el-table-column',{attrs:{"label":"Nom","prop":"client.lastName"}}),_c('el-table-column',{attrs:{"label":"Email","prop":"client","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.client.email)+" "),_c('a',{attrs:{"href":"#"},on:{"click":(e) => _vm.goToClient(e, scope.row.client.id)}},[_c('i',{staticClass:"icon-user"})]),_c('br'),(
            scope.row.orderGroup &&
            scope.row.orderGroup.orders &&
            scope.row.orderGroup.orders.length > 1
          )?_c('div',{staticClass:"text-primary",on:{"click":function($event){return _vm.openParticipantsTabs(scope.row)}}},[_vm._v(" Groupe "),_c('i',{staticClass:"icon-people"}),_c('span',[_vm._v(" "+_vm._s(scope.row.orderGroup.orders.length)+" ")])]):_vm._e(),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Avis Google déposé.'),expression:"'Avis Google déposé.'"},{name:"show",rawName:"v-show",value:(scope.row.client && scope.row.client.googleNotice),expression:"scope.row.client && scope.row.client.googleNotice"}],staticClass:"fa fa-google"}),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Avis Trustpilot déposé.'),expression:"'Avis Trustpilot déposé.'"},{name:"show",rawName:"v-show",value:(scope.row.client && scope.row.client.trustpilotNotice),expression:"scope.row.client && scope.row.client.trustpilotNotice"}],staticClass:"fa fa-star",staticStyle:{"margin-left":"2px"}})]}}])}),_c('el-table-column',{attrs:{"label":"Téléphone","prop":"client.phone","width":"150"}}),_c('el-table-column',{attrs:{"label":"Commande créée","width":"220","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"text-primary",on:{"click":function($event){return _vm.goToOrder(_vm.e, scope.row.id)}}},[_vm._v(" Le "+_vm._s(_vm.formatDateTime(scope.row.createdAt))+" ")]),(scope.row.packs > 0)?_c('strong',[_c('i',{staticClass:"fa fa-cart-plus",class:{
              'text-success': scope.row.packsCaptured > 0,
            }}),_vm._v(" "+_vm._s(scope.row.packs)+" pack"),(scope.row.packs > 1)?_c('span',[_vm._v("s")]):_vm._e()]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Coachs","prop":"coachs","sortable":""}}),_c('el-table-column',{attrs:{"label":"Commercial"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._l((scope.row.commercials),function(rel,index){return [_c('CommercialRel',{key:`com-${index}`,attrs:{"relation":rel}})]})]}}])}),_c('el-table-column',{attrs:{"label":"Notes","prop":"notes","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.concatedNotes(scope.row)),expression:"concatedNotes(scope.row)"}],staticClass:"btn btn-sm btn-secondary",attrs:{"type":"button"},on:{"click":function($event){return _vm.showNotebook(scope.row)}}},[_c('i',{staticClass:"fa fa-commenting"}),_vm._v(" ("+_vm._s(scope.row.notebook ? scope.row.notebook.length : 0)+") Consulter ")])]}}])}),_c('el-table-column',{attrs:{"label":"Type","prop":"rank"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.rank === 'pending')?_c('span',{staticClass:"badge badge-warning"},[_vm._v(" En attente ")]):(scope.row.rank === 'fidelisation')?_c('span',{staticClass:"badge badge-success"},[_vm._v(" Fidélisation ")]):(
            scope.row.rank === 'lost' || scope.row.rank === 'lost_other'
          )?_c('div',[_c('span',{staticClass:"badge badge-danger"},[_vm._v("Perdu")]),_c('br'),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.displayLostReason(scope.row.fidelisation.lostReason)))])]):_vm._e()]}}])}),(_vm.hasPermission(_vm.$store.state.user, 'FIDELISATION_WRITE'))?_c('el-table-column',{attrs:{"width":_vm.followed ? (_vm.ordersContainOrderWithInvalidRank ? 240 : 190) : 140,"align":"right","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"d-flex justify-content-end"},[(scope.row.rank !== 'pending')?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Permet de remettre le status \'En attente\'',
              placement: 'top',
            }),expression:"{\n              content: 'Permet de remettre le status \\'En attente\\'',\n              placement: 'top',\n            }"}],staticClass:"btn btn-sm btn-warning mr-1",on:{"click":function($event){return _vm.$emit('updateRank', scope.row, 'pending')}}},[_c('i',{staticClass:"fa fa-spinner",attrs:{"aria-hidden":"true"}})]):_vm._e(),(scope.row.rank !== 'fidelisation')?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Permet de changer le status en \'Fidélisé\'',
              placement: 'top',
            }),expression:"{\n              content: 'Permet de changer le status en \\'Fidélisé\\'',\n              placement: 'top',\n            }"}],staticClass:"btn btn-sm btn-success mr-1",on:{"click":function($event){return _vm.$emit('updateRank', scope.row, 'fidelisation')}}},[_c('i',{staticClass:"fa fa-user-plus",attrs:{"aria-hidden":"true"}})]):_vm._e(),(
              scope.row.rank !== 'lost' && scope.row.rank !== 'lost_other'
            )?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Permet de changer le status en \'Perdu\'',
              placement: 'top',
            }),expression:"{\n              content: 'Permet de changer le status en \\'Perdu\\'',\n              placement: 'top',\n            }"}],staticClass:"btn btn-sm btn-danger mr-1",on:{"click":function($event){return _vm.$emit('updateRank', scope.row, 'lost')}}},[_c('i',{staticClass:"fa fa-user-times",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.isArchived(scope.row))?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Permet de retirer des archives',
              placement: 'top',
            }),expression:"{\n              content: 'Permet de retirer des archives',\n              placement: 'top',\n            }"}],staticClass:"btn btn-sm btn-primary",on:{"click":function($event){return _vm.unarchive(scope.row)}}},[_c('i',{staticClass:"fa fa-repeat"})]):_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: `${
                scope.row.rank == 'pending'
                  ? 'Pour archiver, changer le status en fidélisé ou perdu'
                  : 'Permet d\'archiver'
              }`,
              placement: 'top',
            }),expression:"{\n              content: `${\n                scope.row.rank == 'pending'\n                  ? 'Pour archiver, changer le status en fidélisé ou perdu'\n                  : 'Permet d\\'archiver'\n              }`,\n              placement: 'top',\n            }"}],staticClass:"btn btn-sm btn-primary",attrs:{"disabled":scope.row.rank == 'pending'},on:{"click":function($event){return _vm.archive(scope.row)}}},[_c('i',{staticClass:"fa fa-archive"})]),(_vm.followed)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content:
                'Permet de valider le suivi du pack (Le pack reviendra ultérieurement)',
              placement: 'top',
            }),expression:"{\n              content:\n                'Permet de valider le suivi du pack (Le pack reviendra ultérieurement)',\n              placement: 'top',\n            }"}],staticClass:"btn btn-sm btn-danger ml-1",on:{"click":function($event){return _vm.validateSuiviPack(scope.row)}}},[_c('i',{staticClass:"fa fa-times"})]):_vm._e()])]}}],null,false,1593973164)}):_vm._e()],1),(_vm.page > 1)?_c('Paginate',{staticClass:"mt-1",attrs:{"page-count":_vm.page,"click-handler":_vm.paginationHandler,"prev-text":"Précédent","next-text":"Suivant","container-class":"pagination"}}):_vm._e(),_c('ModalNotebook',{attrs:{"show":_vm.modalNotebook.show,"notebook":_vm.modalNotebook.notebook,"client":_vm.modalNotebook.client,"order":_vm.modalNotebook.order},on:{"close":_vm.closeNotebook}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }