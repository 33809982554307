<template>
  <div
    class="wrapper"
    v-if="hasPermission($store.state.user, 'COACH_MANAGER_READ')"
  >
    <Tabs v-model="activeTab" @input="changeTab" class="empty-tab">
      <Tab
        :key="`tab-${index}`"
        v-for="(item, index) of authorizedTabs"
        :header="item.label"
      >
      </Tab>
    </Tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

export default {
  components: {
    Tabs,
    Tab,
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          label: "Pertes",
          name: "coach-manager-lost",
          requiredPermission: "COACH_MANAGER_LOST_READ",
        },
        {
          label: "Status",
          name: "coach-manager-status",
          requiredPermission: "COACH_MANAGER_STATUS_READ",
        },
        {
          label: "Conversion",
          name: "coach-manager-conversion",
          requiredPermission: "COACH_MANAGER_CONVERSION_READ",
        },
        {
          label: "Fidelisation",
          name: "coach-manager-fidelisation",
          requiredPermission: "COACH_MANAGER_FIDELISATION_READ",
        },
        {
          label: "Scrapping",
          name: "coach-manager-scrapping",
          requiredPermission: "COACH_MANAGER_SCRAPPING_READ",
        },
        {
          label: "Notes interne",
          name: "coach-manager-internal-note",
          requiredPermission: "COACH_MANAGER_INTERNAL_NOTES_READ",
        },
        {
          label: "Notes clients",
          name: "coach-manager-clients-notes",
          requiredPermission: "COACH_MANAGER_CLIENTS_NOTES_READ",
        },
      ],
    };
  },

  computed: {
    authorizedTabs() {
      return this.tabs.filter((tab) => {
        return this.hasPermission(
          this.$store.state.user,
          tab.requiredPermission
        );
      });
    },
  },

  created() {
    if (
      this.$route.name === "coach-manager" &&
      this.authorizedTabs.length > 0
    ) {
      this.$router.push({ name: this.authorizedTabs[0].name });
    }

    this.setCurrentTab(this.$route.name);
  },

  methods: {
    changeTab(index) {
      if (index >= 0) {
        this.$router.push({ name: this.authorizedTabs[index].name });
      }
    },

    setCurrentTab(currentPath) {
      this.activeTab = this.authorizedTabs.findIndex(
        (tab) => tab.name === currentPath
      );
    },
  },

  watch: {
    $route(current) {
      if (current.name === "coach-manager") {
        this.$router.push({ name: this.authorizedTabs[0].name });
      }

      this.setCurrentTab(current.name);
    },
  },
};
</script>
<style lang="scss">
.empty-tab {
  .nav.nav-tabs {
    margin-bottom: 15px;
  }

  & > .tab-content {
    display: none;
  }
}

.display-tab {
  .tab-content {
    display: block !important;
  }
}
</style>
