<template>
  <div class="mb-1">
    <i class="icon-clock"></i> Valider les documents sélectionnés
    <button class="btn btn-success ml-2" @click="sendAllDocuments">
      Valider
    </button>

    <button class="btn btn-danger ml-1" @click="rejectAllDocuments">
      Refuser
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    sendAllDocuments() {
      this.$emit("sendDocuments", this.test);
    },

    rejectAllDocuments() {
      this.$emit("rejectDocuments");
    },
  },
};
</script>
