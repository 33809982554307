<template>
  <modal
    title="Changer le statut"
    v-model="isVisible"
    @cancel="close"
    effect="fade/zoom"
  >
    <div slot="modal-body" class="modal-body d-flex justify-content-around">
      <div class="d-flex flex-column justify-content-between">
        <dt>Coach Managers</dt>
        <dd>
          <el-select v-model="selectedCoachManager" size="mini">
            <el-option
              v-for="item in coachManagers"
              :key="item.id"
              :value="item.coachManager.pseudo"
              :label="item.coachManager.pseudo"
            />
          </el-select>
        </dd>
      </div>

      <div class="ml-3 d-flex flex-column justify-content-between">
        <dt>Date de prospection</dt>
        <dd>
          <el-date-picker
            v-model="selectedDate"
            size="small"
            type="date"
            :default-value="new Date()"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
          />
        </dd>
      </div>
    </div>

    <div slot="modal-footer" class="modal-footer">
      <button class="btn btn-default" @click="close">Annuler</button>
      <button class="btn btn-primary" @click="update">Modifier</button>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal.vue";

export default {
  components: {
    Modal,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      required: true,
    },
    updateClient: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isVisible: false,
      coachManagers: [],
      selectedCoachManager: undefined,
      selectedDate: new Date(),
    };
  },

  created() {
    this.getCoachManagers();
  },

  watch: {
    visible(value) {
      this.isVisible = value;
    },
  },

  methods: {
    closeModal() {
      this.close();
    },

    async getCoachManagers() {
      const { data } = await this.$api.get("/users/coach-managers");

      this.coachManagers = data;
    },

    async update() {
      if (!this.selectedCoachManager) {
        this.$errorToast("Veuillez sélectionner un coach manager");
      } else {
        this.updateClient({
          prospectedBy: this.selectedCoachManager,
          prospectedAt: this.selectedDate || new Date(),
          prospectedCoach: true,
        });

        this.close();
      }
    },
  },
};
</script>
