<template>
  <ul v-click-outside="reset" class="nav navbar-nav ml-auto">
    <div class="search-container">
      <input
        v-on:click="onSearchDebounced"
        v-on:keyup="onSearchDebounced"
        v-on:keyup.enter="redirectToSearch"
        v-model="query"
        class="form-control form-search"
        type="text"
        placeholder="Rechercher ..."
      />
      <div class="search-results" v-if="displayResults">
        <div v-if="isLoading">
          <div class="search-result">Chargement ...</div>
        </div>
        <div
          v-for="({ label, item }, index) of clients.slice(0, 5)"
          :key="`clients-${index}`"
          class="search-result clients search-result-client"
          @click="goToClient(item)"
        >
          <div v-html="label"></div>
          <div class="result-type result-type-client">Client</div>
        </div>
        <div
          v-for="({ label, item }, index) of coachs.slice(0, 5)"
          :key="`coachs-${index}`"
          class="search-result coachs search-result-coach"
          @click="goToCoach(item)"
        >
          <div v-html="label"></div>
          <div class="result-type result-type-coach">Coach</div>
        </div>
        <div
          v-for="({ label, item }, index) of companies.slice(0, 5)"
          :key="`companies-${index}`"
          class="search-result companies search-result-company"
          @click="goToCompany(item)"
        >
          <div v-html="label"></div>
          <div class="result-type result-type-company">Entreprise</div>
        </div>
      </div>
    </div>
  </ul>
</template>

<script>
import ClickOutside from "vue-click-outside";
import debounce from "lodash.debounce";

export default {
  data() {
    return {
      query: "",
      isLoading: false,
      searchCategorie: [
        { label: "Prénom", value: "firstName" },
        { label: "Nom", value: "lastName" },
        { label: "Société", value: "company" },
        { label: "Email", value: "email" },
      ],
      clients: [],
      coachs: [],
      companies: [],
      payers: [],
      onSearchDebounced: debounce(() => {
        if (this.query && this.query.length > 2) {
          this.startSearch(this.query);
        } else if (this.query && this.query.length < 2) {
          this.reset();
        }
      }, 600),
    };
  },

  computed: {
    displayResults() {
      return (
        this.clients.length > 0 ||
        this.coachs.length > 0 ||
        this.companies.length > 0 ||
        this.payers.length > 0
      );
    },
  },

  methods: {
    reset() {
      if (this.displayResults) {
        this.query = "";
        this.clients = [];
        this.coachs = [];
        this.companies = [];
      }
    },

    async startSearch(query) {
      this.isLoading = true;

      if (this.hasPermission(this.$store.state.user, "CLIENTS_READ")) {
        await this.searchClients(query);
      }

      if (this.hasPermission(this.$store.state.user, "USERS_MENU_READ")) {
        await this.searchCoachs(query);
      }

      if (
        this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_READ")
      ) {
        await this.searchCompanies(query);
      }

      this.isLoading = false;
    },

    async searchClients(query) {
      this.clients = [];

      const { data } = await this.$api.post("/clients/search", {
        query,
      });

      if (data.length) {
        this.clients = data.map((item) => {
          let label = `${item.company ? item.company : ""} ${
            item.lastName ? item.lastName : ""
          } ${item.firstName ? item.firstName : ""} ${item.email}`;

          this.query.split(" ").forEach((term) => {
            label = label
              .toLowerCase()
              .replace(term, `<strong>${term}</strong>`);
          });

          return { label, item };
        });
      }
    },

    async searchCoachs(query) {
      this.coachs = [];

      const { data } = await this.$api.post("/users/coachs/search", {
        query,
      });

      if (data.length) {
        this.coachs = data.map((item) => {
          let label = `${item.coach ? `${item.coach.name}` : ""} ${item.email}`;

          this.query.split(" ").forEach((term) => {
            label = label
              .toLowerCase()
              .replace(term, `<strong>${term}</strong>`);
          });

          return { label, item };
        });
      }
    },

    async searchCompanies(query) {
      this.companies = [];

      const { data } = await this.$api.post("/companies/search", {
        query,
      });

      if (data.length) {
        this.companies = data.map((item) => {
          let label;

          this.query.split(" ").forEach((term) => {
            label = item.name
              .toLowerCase()
              .replace(term, `<strong>${term}</strong>`);
          });

          return { label, item };
        });
      }
    },

    redirectToSearch() {
      if (this.query && this.query.length > 2) {
        this.$router.push({
          name: "search",
          query: {
            q: this.query,
          },
        });
      }
    },

    goToClient(client) {
      this.$router.push({
        name: "client-details",
        params: {
          id: client.id,
        },
      });

      this.reset();
    },

    goToCoach(coach) {
      this.$router.push({
        name: "user-details",
        params: {
          id: coach.id,
        },
      });

      this.reset();
    },

    goToCompany(company) {
      if (company.clients.length > 0) {
        this.$router.push({
          name: "client-details",
          params: {
            id: company.clients[0].id,
          },
        });
      } else {
        this.$router.push({
          name: "companies-list",
        });
      }

      this.reset();
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  position: relative;

  .form-search {
    height: 37px;
    min-width: 300px;
  }

  .search-results {
    position: absolute;
    background-color: white;
    min-width: 100%;
    border: 1px solid #e1e6ef;
    border-top: inherit;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }

  .search-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      background-color: #20a8d8;
      color: #fff;
    }

    .result-type {
      margin-left: 0.5rem;
      font-weight: bold;
    }
  }

  .search-result-client {
    background-color: #d1fae5;
    .result-type-client {
      color: #10b981;
    }
  }

  .search-result-coach {
    background-color: #fef3c7;
    .result-type-coach {
      color: #f59e0b;
    }
  }

  .search-result-company {
    background-color: #c7e8fe;
    .result-type-company {
      color: #1a8edb;
    }
  }

  .search-result-payer {
    background-color: rgb(203, 213, 225);
    .result-type-payer {
      color: rgb(51, 65, 85);
    }
  }
}
</style>
