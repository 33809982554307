<template>
  <div class="rank-custom-wrapper">
    <CoachManagerStatusFilters :default="filters" @update="handleFilters" />

    <div class="card card-inverse card-info text-center" v-if="loading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>
    <div class="card text-center" v-else-if="data.length === 0">
      <div class="card-block">
        <blockquote class="card-blockquote">
          Aucune données, appliquer ou modifier les filtres
        </blockquote>
      </div>
    </div>

    <div v-else>
      <Tabs v-model="activeTab">
        <Tab header="Départements">
          <CoachManagerStatusSector :data="mapData" :filters="filters" />
        </Tab>
        <Tab header="Global">
          <CoachManagerStatusGlobal :data="data" :filters="filters" />
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import CoachManagerStatusFilters from "../components/coachManager/status/CoachManagerStatusFilters";
import CoachManagerStatusSector from "../components/coachManager/status/CoachManagerStatusSector";
import CoachManagerStatusGlobal from "../components/coachManager/status/CoachManagerStatusGlobal";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import { mapGetters } from "vuex";

export default {
  components: {
    CoachManagerStatusFilters,
    CoachManagerStatusSector,
    CoachManagerStatusGlobal,
    Tabs,
    Tab,
  },
  data() {
    return {
      loading: false,
      data: [],
      activeTab: 0,
      filters: this.defaultFilters(),
    };
  },

  computed: {
    ...mapGetters({
      departments: "getDepartments",
    }),

    zipcodes() {
      return this.departments.map(({ code, name }) => {
        if (code === "all") {
          return { label: name, value: code };
        } else {
          return { label: code + " - " + name, value: code };
        }
      });
    },

    mapData() {
      const domTom = ["971", "972", "973", "974", "976"];
      const departments = [...this.zipcodes].filter(
        (dpt) => !domTom.includes(dpt.value) && dpt.value !== "all"
      );

      departments.forEach((dpt) => {
        dpt.data = this.departmentCoachInfo(dpt.value);
      });

      return departments;
    },
  },

  methods: {
    handleFilters(newFilters) {
      this.filters = newFilters;

      this.loadCoachs();
    },

    async loadCoachs() {
      try {
        this.loading = true;

        const { data } = await this.$api.get(`/rank/maps`, {
          params: {
            begin: this.filters.begin,
            end: this.filters.end,
            status: this.filters.status.map((s) => s.value),
            merchants: this.filters.merchants.map((m) => m.value),
            concepts: this.filters.concepts.map((s) => s.value),
            prestations: this.filters.prestations.map((s) => s.value),
            languages: this.filters.languages.map((s) => s.value),
            tags: this.filters.tags.map((s) => s.value),
            genders: this.filters.genders,
          },
        });

        this.data = data;
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },

    departmentCoachInfo(dpt) {
      let departmentCoachs = this.data.filter((coach) => {
        if (coach.department && typeof coach.department === "string") {
          return coach.department === dpt;
        }
      });

      return departmentCoachs;
    },

    defaultFilters() {
      return {
        begin: null,
        end: null,
        status: [],
        merchants: [],
        concepts: [],
        prestations: [],
        languages: [],
        tags: [],
        genders: [],
      };
    },
  },
};
</script>
