var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col flex-wrap"},[_c('div',{staticClass:"flex flex-col"},[_c('h3',[_vm._v(" TOP COACHS "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: 'Coachs ayant un taux de conversion supérieur à 66%.',
          placement: 'top',
        }),expression:"{\n          content: 'Coachs ayant un taux de conversion supérieur à 66%.',\n          placement: 'top',\n        }"}],staticClass:"fa fa-info-circle ml-05"})]),_c('FidelisationLevel',{attrs:{"id":"successCoachs","type":"success","coachs":_vm.successCoachs}})],1),_c('div',{staticClass:"flex flex-col mt-3"},[_c('h3',[_vm._v(" MIDDLE COACHS "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: 'Coachs ayant un taux de conversion entre 33% et 66%.',
          placement: 'top',
        }),expression:"{\n          content: 'Coachs ayant un taux de conversion entre 33% et 66%.',\n          placement: 'top',\n        }"}],staticClass:"fa fa-info-circle ml-05"})]),_c('FidelisationLevel',{attrs:{"id":"warningCoachs","type":"warning","coachs":_vm.warningCoachs}})],1),_c('div',{staticClass:"flex flex-col mt-3"},[_c('h3',[_vm._v(" BAD COACHS "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: 'Coachs ayant un taux de conversion inférieur à 33%.',
          placement: 'top',
        }),expression:"{\n          content: 'Coachs ayant un taux de conversion inférieur à 33%.',\n          placement: 'top',\n        }"}],staticClass:"fa fa-info-circle ml-05"})]),_c('FidelisationLevel',{attrs:{"id":"dangerCoachs","type":"danger","coachs":_vm.dangerCoachs}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }