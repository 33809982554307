<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-globe"></i> Statistiques et classement
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-md-6">
          <CoachStats
            :stats="coach.coach.stats"
            v-if="coach && coach.coach && coach.coach.stats"
          />
          <div v-else>Aucune donnée de statistique</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoachStats from "../components/CoachStats";

export default {
  components: {
    CoachStats,
  },
  props: {
    coach: {
      type: Object,
      required: true,
    },
  },
};
</script>
