<template>
  <div>
    <div class="card">
      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-6">
            <label>
              Date de début
              <el-popover trigger="hover" placement="top" :width="400">
                <div class="p-1 text-justify">
                  <p>
                    L'intervalle de date permet de filtrer uniquement les
                    clients créés dans cette periode.
                  </p>
                </div>
                <span slot="reference">
                  <i class="fa fa-info-circle ml-05"></i>
                </span>
              </el-popover>
            </label>
            <datepicker
              v-model="filters.begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>

          <div class="form-group col-sm-6">
            <label>Date de fin</label>
            <datepicker
              v-model="filters.end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>

          <div class="form-group col-sm-6">
            <label>
              Coachs manager
              <i
                class="fa fa-info-circle mr-1"
                v-tooltip="{
                  content:
                    'ATTENTION: il sagit des coachs manager ayant prospecté les coachs, et non les coachs manager des commandes.',
                  placement: 'top',
                }"
              ></i>
            </label>
            <Multiselect
              placeholder="Choisir un ou des coachs manager"
              v-model="filters.coachManagers"
              :options="coachManagers"
              label="label"
              track-by="label"
              class="custom-multiselect"
              :multiple="true"
            />
          </div>

          <div class="form-group col-sm-6">
            <label>Départements</label>
            <Multiselect
              placeholder="Choisir un ou des départements"
              v-model="filters.departments"
              :options="zipcodes"
              label="label"
              track-by="label"
              class="custom-multiselect"
              :multiple="true"
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-4">
            <button class="btn btn-default" @click="applyFilters">
              <i class="icon-eye"></i>&nbsp; Visualiser
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
    Multiselect,
  },

  props: {
    default: {
      type: Object,
      required: true,
    },
    zipcodes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      filters: [],
      coachManagers: [],
    };
  },

  async created() {
    this.filters = this.default;
    this.getCoachManagers();
  },

  methods: {
    async getCoachManagers() {
      const { data } = await this.$api.get("/users/coach-managers");

      this.coachManagers = data.map((coach) => ({
        label: coach.coachManager.pseudo,
        value: coach.coachManager.pseudo,
      }));
    },

    applyFilters() {
      this.$emit("update", {
        ...this.filters,
        coachManagers:
          this.filters.coachManagers &&
          this.filters.coachManagers.map((coach) => coach.label),
        departments:
          this.filters.departments &&
          this.filters.departments.map((dpt) => dpt.value),
      });
    },
  },
};
</script>
