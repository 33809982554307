<template>
  <div>
    <div class="d-flex flex-wrap">
      <div id="map-wrapper" class="col-12 col-lg-8" style="position: relative">
        <FranceMapSvg :default-color="defaultColor" />

        <FidelisationCard
          id="data-card"
          custom-class="data-card"
          v-show="currentHoveredId && hoveredDepartmentData"
          :department-data="hoveredDepartmentData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FranceMapSvg from "../../FranceMapSvg";
import FidelisationCard from "./FidelisationCard";

export default {
  components: {
    FranceMapSvg,
    FidelisationCard,
  },

  data() {
    return {
      currentHoveredId: null,
      defaultColor: "#E1E6EF",
      dataCardPositionIsSet: false,
    };
  },

  props: {
    data: { type: Array, required: true },
    filters: {
      required: true,
      type: Object,
    },
    colorScheme: {
      type: String,
      default: "blue",
    },
  },

  mounted() {
    this.setPathsListeners();
  },

  computed: {
    hoveredDepartmentData() {
      if (this.currentHoveredId) {
        return this.data.find(
          (departmentData) => departmentData.value === this.currentHoveredId
        );
      }

      return {};
    },
  },

  methods: {
    fidelisationRate(data) {
      if (data.length === 0) return 0;
      return (
        data.reduce((acc, coach) => {
          return acc + parseFloat(coach.tf);
        }, 0) / data.length
      ).toFixed(2);
    },

    setPathsListeners() {
      const paths = document.querySelectorAll("#map-wrapper path");

      paths.forEach((path) => {
        let id;
        let isIdf = false;

        if (path.id.startsWith("IDF")) {
          id = path.id.replace("IDF-", "");
          isIdf = true;
        } else {
          id = path.id.replace("FR-", "");
        }

        const data = [...this.data];

        const correspondingData = data.find(
          (departmentData) => departmentData.value === id
        );

        const fidelisationRate = this.fidelisationRate(correspondingData.data);

        if (fidelisationRate > 0) {
          path.setAttribute("fill", this.getColor(fidelisationRate));
        }

        path.addEventListener("mouseover", () => {
          if (!path.hasAttribute("stroke-width")) {
            path.setAttribute("stroke-width", isIdf ? ".6" : "3");
          }

          this.currentHoveredId = id;
        });

        path.addEventListener("mouseout", () => {
          if (path.hasAttribute("stroke-width")) {
            path.removeAttribute("stroke-width");
          }

          if (this.currentHoveredId === id) {
            this.currentHoveredId = null;
          }
        });
      });
    },

    getColor(percentage) {
      if (percentage < 33) {
        return "#dc3545";
      }

      if (percentage < 66) {
        return "#ffc107";
      }

      if (percentage >= 66) {
        return "#28a745";
      }
    },

    setDataCardPosition() {
      const mapWrapper = document.querySelector("#map-wrapper");
      const dataCard = document.querySelector("#data-card");

      if (mapWrapper && dataCard) {
        mapWrapper.addEventListener("mousemove", (e) => {
          const wrapperInPage = mapWrapper.getBoundingClientRect();
          dataCard.style.left = e.x - wrapperInPage.x + "px";
          dataCard.style.top = e.y - wrapperInPage.y + "px";
        });

        this.dataCardPositionIsSet = true;
      }
    },
  },

  watch: {
    currentHoveredId(value) {
      if (value && !this.dataCardPositionIsSet) {
        this.setDataCardPosition();
      }
    },
  },
};
</script>

<style scoped>
.data-card {
  position: absolute;
  width: 300px;
  height: auto;
  transform: translate(-110%, -110%);
}
</style>
