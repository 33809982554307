<template>
  <div class="animated fadeIn">
    <loader :isVisible="isLoading" />

    <div class="row mt-1">
      <div class="form-group col-sm-4">
        <label>Date de création du</label>
        <datepicker
          v-model="filters.dateBegin"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        ></datepicker>
      </div>
      <div class="form-group col-sm-4">
        <label>au</label>
        <datepicker
          v-model="filters.dateEnd"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        ></datepicker>
      </div>

      <div class="col-sm-12">
        <h5>En-tête du fichier Excel</h5>
      </div>
      <div class="col-sm-12">
        <ul>
          <li>Date de création</li>
          <li>Prix</li>
          <li>Email du client</li>
          <li>Statut de la commande</li>
          <li>Découverte ou Pack</li>
          <li>Nombre de séance</li>
          <li>Numéro de facture</li>
          <li>Commercial email</li>
        </ul>
      </div>
    </div>

    <div class="col-sm-12">
      <button
        type="button"
        class="btn btn-sm btn-primary float-right mx-1"
        @click="download()"
      >
        <i class="icon-cloud-download"></i>&nbsp; Télécharger
      </button>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment-timezone";
import Loader from "../components/Loader";
import DownloadFile from "../mixin/DownloadFile";

export default {
  components: {
    Datepicker,
    Loader,
  },
  mixins: [DownloadFile],
  data() {
    return {
      isLoading: false,
      filters: {
        dateBegin: moment().startOf("months").format("YYYY-MM-DD"),
        dateEnd: moment().endOf("day").format("YYYY-MM-DD"),
      },
    };
  },

  methods: {
    async download() {
      this.isLoading = true;

      const { data } = await this.$api.get("/exports/orders", {
        params: {
          dateBegin: this.filters.dateBegin,
          dateEnd: this.filters.dateEnd,
        },
      });

      this.downloadXLSX(data, "orders.xlsx");
      this.isLoading = false;
    },
  },
};
</script>
