<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div class="card" v-if="hasPermission($store.state.user, 'EXPORT_READ')">
        <div class="card-header d-flex justify-content-between">
          <h3>Export</h3>
          <div class="alert alert-info" role="alert">
            Un filtre n'est pris en compte qu'à partir du moment ou une valeur
            est saisie dedans. Sinon par défaut il n'exclue aucune donnée.
          </div>
        </div>
        <div class="card-block">
          <Tabs v-model="activeTab" @input="changeTab">
            <Tab
              :key="`tab-${index}`"
              v-for="(item, index) of authorizedTabs"
              :header="item.label"
            >
            </Tab>
          </Tabs>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

export default {
  components: {
    Tabs,
    Tab,
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          label: "Clients",
          name: "export-clients",
          requiredPermission: "EXPORT_CLIENT_READ",
        },
        {
          label: "Prospects",
          name: "export-prospects",
          requiredPermission: "EXPORT_PROSPECT_READ",
        },
        {
          label: "Commandes",
          name: "export-orders",
          requiredPermission: "EXPORT_ORDER_READ",
        },
        {
          label: "Prestataires",
          name: "export-prestataires",
          requiredPermission: "EXPORT_COACH_READ",
        },
        {
          label: "Leads & Marketing",
          name: "marketing-form",
          requiredPermission: "EXPORT_MARKETING_FORM_READ",
        },
        {
          label: "NOVA",
          name: "export-nova",
          requiredPermission: "EXPORT_NOVA_READ",
        },
        {
          label: "Balance comptable",
          name: "export-solde-comptable",
          requiredPermission: "EXPORT_BALANCE_COMPTABLE_READ",
        },
        {
          label: "Compta",
          name: "export-compta",
          requiredPermission: "EXPORT_COMPTA_READ",
        },
      ],
    };
  },

  computed: {
    authorizedTabs() {
      return this.tabs.filter((tab) => {
        return this.hasPermission(
          this.$store.state.user,
          tab.requiredPermission
        );
      });
    },
  },

  created() {
    if (this.$route.name === "export" && this.authorizedTabs.length > 0) {
      this.$router.push({ name: this.authorizedTabs[0].name });
    }

    this.setCurrentTab(this.$route.name);
  },

  methods: {
    changeTab(index) {
      if (index >= 0) {
        this.$router.push({ name: this.authorizedTabs[index].name });
      }
    },

    setCurrentTab(currentPath) {
      this.activeTab = this.authorizedTabs.findIndex(
        (tab) => tab.name === currentPath
      );
    },
  },

  watch: {
    $route(current) {
      if (current.name === "export") {
        this.$router.push({ name: this.authorizedTabs[0].name });
      }

      this.setCurrentTab(current.name);
    },
  },
};
</script>
