<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-basket"></i>
      <span v-if="order.status === 'refunded'">Avoir</span>
      <span v-else-if="order.giftCard">Carte cadeau</span>
      <span v-else
        >Commande <span v-if="order.live" class="info-live">Live</span></span
      >
      n° {{ order.id }}
      <button
        type="button"
        class="btn btn-info btn-sm float-right"
        @click="handleRefreshPaymentStatus"
        v-if="
          order.status !== 'refunded' &&
          hasPermission($store.state.user, 'ORDERS_WRITE')
        "
      >
        Rafraîchir le statut
      </button>
      <button
        type="button"
        class="btn btn-sm btn-danger float-right"
        v-if="
          removeAllowed && hasPermission($store.state.user, 'ORDERS_DELETE')
        "
        @click="removeOrder"
      >
        Supprimer
      </button>
      <span
        class="badge badge-success float-right"
        v-else-if="order.status === 'payed'"
        >Payée</span
      >
      <span
        class="badge badge-warning float-right"
        v-else-if="order.status === 'partial'"
        >Paiement partiel</span
      >
      <span
        class="badge badge-danger float-right"
        v-else-if="order.status === 'pending'"
        >En attente du paiement</span
      >
      <span
        class="badge badge-primary float-right"
        v-else-if="order.status === 'refunded'"
        >Avoir</span
      >
      <span
        class="badge badge-success float-right"
        v-else-if="order.status === 'unpaid'"
        >Impayé (traité)</span
      >

      <PaymentsHasTroubles :order="order" />
    </div>

    <div class="card-block">
      <dl class="row">
        <dt class="col-sm-3">
          <span v-if="order.status === 'refunded'">Avoir le</span>
          <span v-else>Commande effectuée le</span>
        </dt>
        <dd class="col-sm-9">{{ formatDate(order.createdAt) }}</dd>
        <dt class="col-sm-3">Société</dt>
        <dd class="col-sm-9">{{ merchant(order.merchantId) }}</dd>
        <dt class="col-sm-3">Concept</dt>
        <dd class="col-sm-9">{{ order.concept }}</dd>
        <dt class="col-sm-3" v-if="order.labelDescription">
          Titre de la comande
        </dt>
        <dd class="col-sm-9" v-if="order.labelDescription">
          {{ order.labelDescription }}
        </dd>
        <dt class="col-sm-3" v-if="order.description">
          Description de la commande
        </dt>
        <dd class="col-sm-9 pre-wrap" v-if="order.description">
          {{ order.description }}
        </dd>
        <dt class="col-sm-3" v-if="order.subscription">Abonnement live</dt>
        <dd class="col-sm-9" v-if="order.subscription">
          {{
            formatDate(order.subscription.startDate) +
            " - " +
            formatDate(order.subscription.endDate)
          }}
        </dd>
        <dt class="col-sm-3" v-if="order.subscription">Type d'abonnement</dt>
        <dd class="col-sm-9" v-if="order.subscription">
          {{ order.subscription.type }}
        </dd>
        <dt v-if="order.session && !order.subscription" class="col-sm-3">
          Séances
        </dt>
        <dd v-if="order.session && !order.subscription" class="col-sm-9">
          {{ formatPack(order.session) }}
          <span v-if="order.discovery">(Découverte)</span>
          &ndash;
          <button
            class="btn btn-link p-0"
            @click="editCalendar = true"
            :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
          >
            <span v-if="order.openedAt">
              <span v-if="order.closedAt">Du</span>
              <span v-else>A partir du</span>
              {{ formatDate(order.openedAt) }}
            </span>
            <span v-if="order.closedAt">
              <span v-if="order.openedAt">au</span>
              <span v-else>Jusqu'au</span>
              {{ formatDate(order.closedAt) }}
            </span>
            <span v-if="!order.closedAt && !order.openedAt">Libre</span>
          </button>
        </dd>
        <dt class="col-sm-3">Montant total</dt>
        <dd class="col-sm-9" v-if="order.free"><strong>Offert</strong></dd>
        <dd class="col-sm-9" v-else>{{ formatPrice(order.price) }}</dd>
        <dt class="col-sm-3 text-danger" v-if="order.status == 'partial'">
          Reste à régler
        </dt>
        <dd class="col-sm-9 text-danger" v-if="order.status == 'partial'">
          <strong>{{ amountDiff() }}</strong>
        </dd>
        <template
          v-if="
            order.status !== 'payed' &&
            order.status !== 'unpaid' &&
            disbursementAmount()
          "
        >
          <dt class="col-sm-3 text-success">Remboursement</dt>
          <dd class="col-sm-9 text-success">
            <strong>{{ disbursementAmount() }}</strong>
          </dd>
        </template>

        <template
          v-if="
            order.status !== 'payed' &&
            order.status !== 'unpaid' &&
            order.invoice &&
            refundAmount() &&
            hasPermission($store.state.user, 'ORDERS_WRITE')
          "
        >
          <dt
            class="col-sm-3 text-primary"
            v-if="
              order.status !== 'payed' &&
              order.status !== 'refunded' &&
              order.invoice
            "
          >
            Créer un avoir
          </dt>
          <dd
            class="col-sm-9"
            v-if="
              order.status !== 'payed' &&
              order.status !== 'refunded' &&
              order.invoice
            "
          >
            <button
              class="btn btn-info btn-sm float-right"
              @click="openRefundModal = true"
            >
              {{ refundAmount() }}
            </button>
          </dd>
        </template>

        <template v-if="order.sponsorship || order.usedSponsorship">
          <dt class="col-sm-3">Parrainage</dt>
          <dd class="col-sm-9">
            <div v-if="order.sponsorship && order.sponsorship.sponsor">
              <a :href="sponsorHref" target="_blank">
                {{ order.sponsorship.sponsor.name }}
                <i class="icon-user"></i>
              </a>
              ({{
                order.sponsorship.sponsor.gender === "Femme"
                  ? "Marraine"
                  : "Parrain"
              }})
            </div>
            <div
              v-if="order.usedSponsorship && order.usedSponsorship.sponsored"
            >
              <a :href="sponsoredHref" target="_blank">
                {{ order.usedSponsorship.sponsored.name }}
                <i class="icon-user"></i>
              </a>
              ({{
                order.usedSponsorship.sponsored.gender === "Femme"
                  ? "Filleule"
                  : "Filleul"
              }})
            </div>
          </dd>
        </template>

        <dt class="col-sm-3">Facture</dt>
        <dd class="col-sm-9" v-if="order.grandtotal">
          GrandTotal &mdash; N° {{ order.id.replace("GT-", "") }}
        </dd>
        <dd class="col-sm-9" v-else-if="!order.invoice || !order.invoice.id">
          Non
        </dd>
        <dd class="col-sm-9" v-else-if="order.invoice && order.invoice.pdf">
          <button
            v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
            class="btn btn-info btn-sm float-right"
            @click="sendInvoice = true"
          >
            <i class="icon-cursor"></i>
          </button>
          <button
            v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
            class="btn btn-info btn-sm float-right"
            @click="launchEditInvoice"
          >
            Modifier
          </button>
          <a @click="download()"> {{ order.invoice.filename }}</a>
        </dd>
        <dd class="col-sm-9" v-else>En cours de création ...</dd>

        <template v-if="order.refunds && order.refunds.length">
          <dt class="col-sm-3">Avoir(s) :</dt>
          <dd class="col-sm-9">
            <div
              v-for="(refundOrder, index) in order.refunds"
              :key="`order-${index}`"
            >
              <a href="#" @click="(e) => goToOrder(e, refundOrder.id)">
                <i class="icon-basket"></i>
              </a>
              -
              <a @click="download(refundOrder)">{{
                refundOrder.invoice && refundOrder.invoice.filename
              }}</a>
              -
              <span>
                {{ formatPrice(refundOrder.price) }}
              </span>
            </div>
          </dd>
        </template>

        <template v-if="order.defaultSessionValue">
          <dt class="col-sm-3">Coach par défaut</dt>
          <dd class="col-sm-9">
            {{ order.defaultSessionValue.coach }}
          </dd>
          <dt class="col-sm-3">Avoir par défaut</dt>
          <dd class="col-sm-9">
            {{ formatPrice(order.defaultSessionValue.credit) }}
          </dd>
        </template>

        <dt class="col-sm-3">Coach référent</dt>
        <dd class="col-sm-9">
          <div class="row">
            <div class="col-sm-8">
              <v-select
                v-if="
                  editCoachReferent &&
                  hasPermission($store.state.user, 'ORDERS_WRITE')
                "
                v-model="coachReferent"
                :options="coachsReferentOptions"
                @search="searchCoachs"
                @input="updateCoachReferent"
              ></v-select>
              <div v-else>
                <span v-if="order.referent && order.referent.coach">
                  <a href="#" @click="(e) => goToCoach(e, order.referent.id)">
                    {{ referentName }}
                  </a>
                </span>
                <span v-else>Aucun référent</span>
              </div>
            </div>
            <div class="col-sm-4">
              <button
                v-if="
                  order.referent &&
                  order.referent.coach &&
                  hasPermission($store.state.user, 'ORDERS_WRITE')
                "
                class="btn btn-danger btn-sm float-right ml-1"
                @click="removeCoachReferent"
              >
                Supprimer
              </button>
              <button
                v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
                class="btn btn-sm float-right"
                :class="editCoachReferent ? 'btn-default' : 'btn-info'"
                @click="editCoachReferent = !editCoachReferent"
              >
                {{ editCoachReferent ? "Retour" : "Éditer" }}
              </button>
            </div>
          </div>
        </dd>

        <template v-if="showToggleRequiredSessionUserCount">
          <dt class="col-sm-3">Pointage</dt>
          <dd class="col-sm-9">
            <el-switch
              v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
              v-model="order.requiredSessionUserCount"
              @change="toggleRequiredSessionUserCount"
            />
            <span v-else>{{
              order.requiredSessionUserCount ? "Oui" : "Non"
            }}</span>
          </dd>
        </template>

        <template v-if="order.giftCard">
          <dt class="col-sm-3">Carte cadeau</dt>
          <dd class="col-sm-9">
            <button
              v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
              class="btn btn-info btn-sm mr-1"
              @click="editGiftCard = true"
            >
              Éditer
            </button>
            <button
              class="btn btn-info btn-sm mr-1"
              @click="openTicketsPage"
              :disabled="sessions && sessions.length === 0"
            >
              Visualiser
            </button>
            <button
              v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
              class="btn btn-info btn-sm"
              @click="sendGiftCard = true"
              :disabled="sessions && sessions.length === 0"
            >
              Envoyer
            </button>
          </dd>
        </template>

        <template v-if="promoCodeUsages && promoCodeUsages.length > 0">
          <dt class="col-sm-3">Codes Promo</dt>
          <dd class="col-sm-9">
            <span v-for="discount in promoCodeUsages" :key="discount.id">
              <br v-if="discount !== promoCodeUsages[0]" />
              {{ discount.promoCode.code }}
              {{ displayPromoCodeDiscount(discount.promoCode) }}
            </span>
          </dd>
        </template>

        <template v-if="liveSponsorshipDiscountUsage">
          <dt class="col-sm-3">Parrainage Live</dt>
          <dd class="col-sm-9">
            {{ liveSponsorshipDiscountUsage.liveSponsorshipDiscount.name }}
            (-{{
              liveSponsorshipDiscountUsage.liveSponsorshipDiscount.discount
            }}%)
          </dd>
        </template>

        <dt class="col-sm-3" v-if="order.live">Cours Live</dt>
        <dd class="col-sm-9" v-if="order.live">{{ order.live.name }}</dd>

        <dt class="col-sm-3" v-if="order.live && order.live.groupId">
          Réservé au Groupe
        </dt>
        <dd class="col-sm-9" v-if="order.live && order.live.groupId">
          {{ order.live.group && order.live.group.name }}
        </dd>

        <dt class="col-sm-3" v-if="order.live">Mode/Type Live</dt>
        <dd class="col-sm-9" v-if="order.live">{{ order.live.type }}</dd>
        <dt v-if="averageSessionRate" class="col-sm-3">Note moyenne</dt>
        <dd
          v-if="averageSessionRate"
          class="col-sm-9 d-flex"
          style="cursor: pointer"
          @click="showSessionRatingsModal = true"
        >
          <StarRating :rating="averageSessionRate" />
          <span> {{ averageSessionRate }}/5 ({{ totalRates }} avis)</span>
        </dd>
      </dl>
      <div class="row">
        <dt class="col-sm-3">Label (visible coach)</dt>
        <dd class="col-sm-9">
          <input
            :value="order.label"
            @input="handleLabel"
            type="text"
            class="form-control"
          />
        </dd>
        <div class="form-group col-sm-12">
          <strong>Menus : </strong>
          <v-select
            v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
            v-model="menuStat"
            :options="menuStatsOptions"
            @input="handleStatusType"
            label="label"
            :selectable="(option) => !option.disable"
          ></v-select>
          <span v-else> {{ form.menuStat.label }}</span>
        </div>
      </div>
    </div>

    <modal
      title="Calendrier"
      v-model="editCalendar"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleEditCalendar()"
      effect="fade/zoom"
    >
      <div class="form-group">
        <h5 class="mb-2">Période de disponibilité pour les coachs</h5>
        <div class="row">
          <div class="col-sm-6">
            <div>Début</div>
            <datepicker
              v-model="order.openedAt"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            />
          </div>
          <div class="col-sm-6">
            <div>Fin</div>
            <datepicker
              v-model="order.closedAt"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            />
          </div>
        </div>
        <button class="btn btn-sm btn-secondary mt-1" @click="resetCalendar">
          <i class="fa fa-refresh" />
          Remettre à zéro
        </button>
      </div>
    </modal>

    <RefundModal
      :order="order"
      :modalShow="openRefundModal"
      @close="onRefundModalClose"
    />

    <modal
      title="Envoyer la facture"
      v-model="sendInvoice"
      cancelText="Fermer"
      okText="Envoyer la facture"
      @ok="handleSendInvoice()"
      effect="fade/zoom"
    >
      <div class="form-group" v-if="client">
        <p class="alert alert-success" v-if="sended">Facture envoyée !</p>
        <label>Adresse email</label>
        <input
          v-if="client.contactEmail && client.contactEmail.length > 5"
          :value="client.contactEmail"
          @input="emailInvoice = $event.target.value"
          type="text"
          class="form-control"
        />
        <input
          v-else-if="client.billing && client.billing.email"
          :value="client.billing.email"
          @input="emailInvoice = $event.target.value"
          type="text"
          class="form-control"
        />
        <input
          v-else
          :value="client.email"
          @input="emailInvoice = $event.target.value"
          type="text"
          class="form-control"
        />
      </div>
    </modal>

    <modal
      title="Modifier la facture"
      v-model="editInvoice"
      cancelText="Fermer"
      okText="Générer une nouvelle facture"
      @ok="handleEditInvoice"
      effect="fade/zoom"
      :backdrop="false"
    >
      <template v-if="invoice">
        <div class="form-group">
          <label>Nom de la facture</label>
          <div class="input-group">
            <input v-model="invoice.serial" type="text" class="form-control" />
          </div>
        </div>
        <div class="form-group">
          <label>Nom et prénom</label>
          <div class="input-group">
            <input
              v-model="invoice.name"
              type="text"
              class="form-control"
              :class="{ 'has-error': invoiceError && !invoice.name }"
            />
          </div>
          <small
            v-if="invoiceError && !invoice.name"
            class="d-block text-danger text-right"
          >
            Champ requis
          </small>
        </div>
        <div class="form-group">
          <label>Société</label>
          <div class="input-group">
            <textarea
              v-model="invoice.company"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group">
          <label>Service</label>
          <div class="input-group">
            <input v-model="invoice.service" type="text" class="form-control" />
          </div>
        </div>
        <div class="form-group">
          <label>Adresse</label>
          <div class="input-group">
            <input
              v-model="invoice.address"
              type="text"
              class="form-control"
              :class="{ 'has-error': invoiceError && !invoice.address }"
            />
          </div>
          <small
            v-if="invoiceError && !invoice.address"
            class="d-block text-danger text-right"
          >
            Champ requis
          </small>
        </div>
        <div class="row">
          <div class="form-group col-sm-6">
            <label>Code postal</label>
            <div class="input-group">
              <input
                v-model="invoice.zipcode"
                type="text"
                class="form-control"
                :class="{ 'has-error': invoiceError && !invoice.zipcode }"
              />
            </div>
            <small
              v-if="invoiceError && !invoice.zipcode"
              class="d-block text-danger text-right"
            >
              Champ requis
            </small>
          </div>
          <div class="form-group col-sm-6">
            <label>Ville</label>
            <div class="input-group">
              <input
                v-model="invoice.city"
                type="text"
                class="form-control"
                :class="{ 'has-error': invoiceError && !invoice.city }"
              />
            </div>
            <small
              v-if="invoiceError && !invoice.city"
              class="d-block text-danger text-right"
            >
              Champ requis
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Description</label>
          <div class="input-group">
            <textarea
              v-model="invoice.description"
              class="form-control"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <div class="commercial-toggle">
            <label class="switch switch-sm switch-text switch-info mb-0">
              <input
                type="checkbox"
                class="switch-input"
                v-model="invoice.showCommercial"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span class="txt-sap">
              Afficher les informations du commercial dans la facture
            </span>
          </div>
        </div>
        <div class="form-group">
          <label>Facture créée le</label>
          <datepicker
            v-model="invoice.createdAt"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          />
        </div>
        <div class="row">
          <div class="form-group col-sm-4">
            <label>Nombre de séances</label>
            <input
              v-model="invoice.session"
              type="number"
              class="form-control"
            />
          </div>
          <div class="form-group col-sm-4">
            <label>Participants</label>
            <input
              v-model="invoice.clients"
              type="number"
              class="form-control"
            />
          </div>
          <div class="form-group col-sm-4">
            <label>Découverte</label>
            <select class="form-control" v-model="invoice.discovery">
              <option :value="false">Non</option>
              <option :value="true">Oui</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-6">
            <label>Montant</label>
            <div
              class="input-group"
              :class="{ 'has-error': invoiceError && !invoice.price }"
            >
              <input
                v-model="invoice.price"
                type="text"
                class="form-control"
                @input="(e) => formPriceInput(e.target.value)"
              />
              <span class="input-group-addon"><i class="fa fa-euro"></i></span>
            </div>
            <small
              v-if="invoiceError && !invoice.price"
              class="d-block text-danger text-right"
            >
              Champ requis
            </small>
          </div>
          <div class="form-group col-sm-6">
            <label>SAP</label>
            <div class="input-group">
              <input v-model="invoice.sap" type="text" class="form-control" />
              <span class="input-group-addon"><i class="fa fa-euro"></i></span>
            </div>
            <small
              ><em
                >Si le champ est vide, la valeur sera calculée à 50% du
                montant.</em
              ></small
            >
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 d-flex justify-content-start">
            <span class="mr-1">Facture acquittée</span>
            <label
              v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="invoice.discharged"
                @change="displayDischargedAt"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span v-else>{{ invoice.discharged ? "Oui" : "Non" }}</span>
          </div>
        </div>
        <div class="row" v-if="invoice.discharged">
          <div class="form-group col-12">
            <label>Facture acquittée le</label>
            <datepicker
              v-model="invoice.dischargedAt"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            />
          </div>
        </div>
      </template>
    </modal>

    <modal
      title="Modifier la carte cadeau"
      v-model="editGiftCard"
      @ok="handleEditGiftCard"
      cancelText="Annuler"
      okText="Modifier"
      effect="fade/zoom"
      :backdrop="false"
    >
      <template v-if="order.giftCard">
        <div class="form-group">
          <label>Expéditeur</label>
          <div class="input-group">
            <input
              v-model="order.giftCard.sender"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group">
          <label>Destinataire</label>
          <div class="input-group">
            <input
              v-model="order.giftCard.recipient"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group">
          <label>Message</label>
          <textarea
            v-model="order.giftCard.message"
            type="text"
            class="form-control"
            maxlength="80"
          />
          <p>
            <strong class="text-muted">
              {{ giftCardMaxChar }} caractères restants.
            </strong>
          </p>
        </div>
      </template>
    </modal>

    <modal
      title="Envoyer la carte cadeau"
      v-model="sendGiftCard"
      cancelText="Annuler"
      okText="Envoyer"
      @ok="handleSendGiftCard"
      effect="fade/zoom"
    >
      <p>Voulez-vous vraiment envoyer la carte cadeau au client ?</p>
    </modal>

    <SessionRatingsModal
      :show="showSessionRatingsModal"
      :sessions="ratedSessions"
      @close="showSessionRatingsModal = false"
    />
  </div>
</template>

<script>
import debounce from "lodash.debounce";

import Datepicker from "vuejs-datepicker";
import Modal from "vue-strap/src/Modal";
import PaymentsHasTroubles from "../components/PaymentsHasTrouble.vue";
import StarRating from "../components/StarRating";
import RefundModal from "../components/RefundModal";
import SessionRatingsModal from "../components/SessionRatingsModal";
import DownloadFile from "../mixin/DownloadFile";

export default {
  components: {
    Modal,
    Datepicker,
    PaymentsHasTroubles,
    StarRating,
    RefundModal,
    SessionRatingsModal,
  },

  mixins: [DownloadFile],

  props: {
    order: {
      type: Object,
      required: true,
    },

    client: {
      type: Object,
      default: null,
    },

    payments: {
      type: Array,
      default: null,
    },

    sessions: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      otherParticipants: 0,
      menuStat: null,
      menuStatsOptions: [
        {
          label: "Découverte (Menu Conversion / Stat Acquisition)",
          value: "decouverte",
          disable: false,
        },
        {
          label: "Pack Conversion (Menu Fidélisation / Stat Acquisition)",
          value: "pack-conversion",
          disable: false,
        },
        {
          label:
            "Pack OneShot/Event (Menu Conversion - Fidélisation / Stat Acquisition)",
          value: "one-shot",
          disable: false,
        },
        {
          label: "Pack Fidélisation (Menu Fidélisation / Stat Fidélisation)",
          value: "pack-fidelisation",
          disable: false,
        },
        {
          label: "Pack Autres (Stat Acquisition)",
          value: "autres",
          disable: !this.hasPermission(
            this.$store.state.user,
            "ORDERS_PACK_AUTRES"
          ),
        },
      ],

      editCalendar: false,
      openRefundModal: false,
      editGiftCard: false,
      sendGiftCard: false,

      sendInvoice: false,
      emailInvoice: null,
      sended: false,

      editInvoice: false,
      invoice: null,
      invoiceError: false,

      editCoachReferent: false,
      coachsReferentOptions: [],
      coachReferent: null,

      ratedSessions: null,
      averageSessionRate: null,
      totalRates: null,
      showSessionRatingsModal: false,
    };
  },

  computed: {
    removeAllowed() {
      if (this.order.invoice?.pdf) return false;

      if (
        this.order.status === "pending" &&
        !this.order.allowed &&
        (!this.payments || this.payments.length === 0)
      ) {
        return true;
      }

      if (this.order.free) {
        const sessions = this.sessions?.filter((s) => s.scheduledAt);

        return sessions?.length === 0;
      }

      return false;
    },

    sponsorHref() {
      if (this.order.sponsorship?.sponsor?.id) {
        const route = this.$router.resolve({
          name: "client-details",
          params: {
            id: this.order.sponsorship.sponsor.id,
          },
        });

        return route.href;
      }

      return null;
    },

    sponsoredHref() {
      if (this.order.usedSponsorship?.sponsored?.id) {
        const route = this.$router.resolve({
          name: "client-details",
          params: {
            id: this.order.usedSponsorship.sponsored.id,
          },
        });

        return route.href;
      }

      return null;
    },

    referentName() {
      let name = this.order.referent?.coach?.name;

      if (this.order.referent?.email) {
        name = `${name} (${this.order.referent.email})`;
      }

      return name;
    },

    showToggleRequiredSessionUserCount() {
      return this.client?.groups.some(({ group }) =>
        group?.features?.includes("SESSION_USER_COUNT")
      );
    },

    giftCardMaxChar() {
      return this.order.giftCard && 80 - this.order.giftCard.message.length;
    },

    promoCodeUsages() {
      return (
        this.order.discountUsages &&
        this.order.discountUsages.filter((discount) => discount.promoCode)
      );
    },

    liveSponsorshipDiscountUsage() {
      if (this.order.discountUsages) {
        const liveSponsorshipDiscountUsages = this.order.discountUsages.filter(
          (discount) => discount.liveSponsorshipDiscount
        );

        return liveSponsorshipDiscountUsages[0];
      }

      return false;
    },
  },

  watch: {
    sessions() {
      if (this.sessions) {
        this.getSessionsAverageRatings();
        this.getGlobalAverageRating();
      }
    },
  },

  mounted() {
    if (this.order.orderGroup) {
      const joinedOrders = this.order.orderGroup.orders.filter(
        (order) => order.id !== this.order.id && order.refClient
      );

      this.otherParticipants = joinedOrders.length;
    }

    if (
      this.order.isConversion &&
      this.order.isFidelisation &&
      this.order.isAcquisition
    ) {
      this.menuStat = {
        label:
          "Pack OneShot/Event (Menu Conversion - Fidélisation / Stat Acquisition)",
        value: "one-shot",
        disable: false,
      };
    }

    if (
      this.order.isConversion &&
      !this.order.isFidelisation &&
      this.order.isAcquisition
    ) {
      this.menuStat = {
        label: "Découverte (Menu Conversion / Stat Acquisition)",
        value: "decouverte",
        disable: false,
      };
    }

    if (
      !this.order.isConversion &&
      this.order.isFidelisation &&
      this.order.isAcquisition
    ) {
      this.menuStat = {
        label: "Pack Conversion (Menu Fidélisation / Stat Acquisition)",
        value: "pack-conversion",
        disable: false,
      };
    }

    if (
      !this.order.isConversion &&
      !this.order.isFidelisation &&
      this.order.isAcquisition
    ) {
      this.menuStat = {
        label: "Pack Autres (Stat Acquisition)",
        value: "autres",
        disable: !this.hasPermission(
          this.$store.state.user,
          "ORDERS_PACK_AUTRES"
        ),
      };
    }

    if (
      !this.order.isConversion &&
      this.order.isFidelisation &&
      !this.order.isAcquisition
    ) {
      this.menuStat = {
        label: "Pack Fidélisation (Menu Fidélisation / Stat Fidélisation)",
        value: "pack-fidelisation",
        disable: false,
      };
    }
  },

  methods: {
    async handleRefreshPaymentStatus() {
      await this.$api.put(`/orders/refresh-payment-status/${this.order.id}`);

      this.$emit("reload");
    },

    removeOrder() {
      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          try {
            await this.$api.delete(`/orders/${this.order.id}`);

            if (this.client) {
              this.goToClient(this.client.id, true);
            } else {
              this.$router.push({ name: "orders" });
            }
          } catch (e) {
            this.$errorToast("Impossible de supprimer la commande");
          }
        },
      });
    },

    goToClient(id, self) {
      const route = this.$router.resolve({
        name: "client-details",
        params: { id },
      });

      window.open(route.href, self ? "_self" : "_blank");
    },

    merchant(merchantId) {
      for (let i in this.$store.state.merchants) {
        if (this.$store.state.merchants[i].id === merchantId) {
          return this.$store.state.merchants[i].name;
        }
      }
    },

    async handleEditCalendar() {
      await this.$api.put(`/orders/${this.order.id}`, {
        openedAt: this.order.openedAt,
        closedAt: this.order.closedAt,
      });

      this.editCalendar = false;
    },

    resetCalendar() {
      this.order.openedAt = null;
      this.order.closedAt = null;
    },

    amountDiff(raw) {
      let payed = 0;

      if (this.payments) {
        for (const payment of this.payments) {
          if (payment.captured) {
            payed += payment.price;
          }
        }
      }

      return raw
        ? this.order.price - payed
        : this.formatPrice(this.order.price - payed);
    },

    disbursementAmount(raw) {
      let disbursed = 0;

      if (this.payments) {
        for (const payment of this.payments) {
          if (payment.captured && payment.price < 0) {
            disbursed += payment.price;
          }
        }
      }

      return raw ? disbursed : this.formatPrice(disbursed);
    },

    refundAmount(raw) {
      return raw
        ? this.amountDiff(true) + this.disbursementAmount(true)
        : this.formatPrice(
            this.amountDiff(true) + this.disbursementAmount(true)
          );
    },

    onRefundModalClose() {
      this.openRefundModalModal = false;

      this.$emit("reload");
    },

    handleSendInvoice() {
      if (!this.emailInvoice && this.client) {
        if (this.client.contactEmail) {
          this.emailInvoice = this.client.contactEmail;
        } else {
          this.emailInvoice =
            this.client.billing && this.client.billing.email
              ? this.client.billing.email
              : this.client.email;
        }
      }

      if (this.emailInvoice) {
        this.$confirm({
          message: `Envoyer la facture à ${this.emailInvoice} ?`,
          onConfirm: async () => {
            try {
              await this.$api.put(`/orders/invoice/${this.order.id}/mail`, {
                email: this.emailInvoice,
              });

              this.sended = true;
            } catch (e) {
              this.$errorToast("Impossible d'envoyer la facture au client");
            }
          },
        });
      }
    },

    launchEditInvoice() {
      if (this.order.invoice) {
        this.invoice = { ...this.order.invoice };
      }

      if (!this.order.invoice && this.client) {
        this.invoice = {
          ...this.invoice,
          name: this.client.billing?.name || this.client.name,
          address: this.client.billing?.address || this.client.address,
          zipcode: this.client.billing?.zipcode || this.client.zipcode,
          city: this.client.billing?.city || this.client.city,
          country: this.client.billing?.country || this.client.country,
          company: this.client.billing
            ? this.client.billing.company
            : this.client.company,
          service: this.client.billing?.service,
          showCommercial: this.client.billing?.showCommercial,
        };
      }

      const price = this.formatPrice(this.order.price, true, true);
      this.invoice.price = this.formatFormPrice(price);

      this.invoice.description = this.order.description;

      this.invoice.session = this.order.session;
      this.invoice.clients = this.order.clients;
      this.invoice.discovery = this.order.discovery;

      if (this.order.invoice.sap) {
        this.invoice.sap = this.formatPrice(this.order.invoice.sap, true, true);
      }

      this.editInvoice = true;
    },

    checkInvoiceFields() {
      this.invoiceError =
        !this.invoice.name ||
        !this.invoice.address ||
        !this.invoice.zipcode ||
        !this.invoice.city ||
        !this.invoice.price;
    },

    handleEditInvoice() {
      this.invoiceError = false;

      this.checkInvoiceFields();

      if (this.invoiceError) return;

      this.$confirm({
        message: "Modifier la facture et générer un nouveau PDF ?",
        onConfirm: async () => {
          try {
            await this.$api.put(
              `/orders/invoice/${this.order.id}`,
              this.invoice
            );

            location.reload();
          } catch (e) {
            this.$errorToast(
              "Une erreur est survenue, veuillez vérifier que le nom de la facture ne soit pas déjà utilisé."
            );
          }
        },
      });
    },

    formPriceInput(value) {
      this.invoice = {
        ...this.invoice,
        price: this.formatFormPrice(value),
      };
    },

    displayDischargedAt() {
      if (this.invoice.discharged && !this.invoice.dischargedAt) {
        this.invoice.dischargedAt = new Date();
      } else if (!this.invoice.discharged) {
        this.invoice.dischargedAt = null;
      }
    },

    download(refundOrder) {
      let pdfName = this.order.invoice.pdf;

      if (refundOrder) {
        pdfName = refundOrder.invoice?.pdf;
      }

      this.downloadSecuredFile(`uploads/invoice/${pdfName}.pdf`);
    },

    goToOrder(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "order-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },

    async searchCoachs(search, loading) {
      if (search !== "") {
        await this.fetchCoachs(search, loading, this);
      }
    },

    fetchCoachs: debounce(async (search, loading, vm) => {
      if (!search || search.length < 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.post("/users/coachs/search", {
        query: search,
      });

      vm.coachsReferentOptions = data.map((c) => ({
        label: `${c.coach ? c.coach.name : ""} (${c.email})`,
        value: c.email,
        id: c.id,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    async updateCoachReferent() {
      await this.$api.post(`/orders/${this.order.id}/referent`, {
        referent: this.coachReferent?.value,
      });

      this.order.referent = this.coachReferent && {
        id: this.coachReferent.id,
        coach: {
          name: this.coachReferent.label,
        },
      };

      this.coachReferent = null;
      this.editCoachReferent = false;
    },

    async removeCoachReferent() {
      await this.$api.delete(`/orders/${this.order.id}/referent`, {
        referent: null,
      });

      this.order.referent = null;
      this.coachReferent = null;
      this.editCoachReferent = false;
    },

    goToCoach(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "user-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },

    async toggleRequiredSessionUserCount(requiredSessionUserCount) {
      try {
        await this.$api.put(`/orders/${this.order.id}`, {
          requiredSessionUserCount,
        });
      } catch (e) {
        this.order.requiredSessionUserCount = !requiredSessionUserCount;
      }
    },

    async handleEditGiftCard() {
      await this.$api.put(`/giftcards/${this.order.giftCard.id}`, {
        sender: this.order.giftCard.sender,
        recipient: this.order.giftCard.recipient,
        message: this.order.giftCard.message,
      });

      this.editGiftCard = false;
    },

    openTicketsPage() {
      window.open(
        `${
          process.env.VUE_APP_TICKET_URL ||
          "https://cartes-cadeaux.justcoaching.fr"
        }/${this.order.giftCard.key}`,
        "_blank"
      );
    },

    async handleSendGiftCard() {
      await this.$api.patch(`/giftcards/${this.order.giftCard.id}/send-email`);

      this.sendGiftCard = false;
    },

    displayPromoCodeDiscount(promoCode) {
      if (promoCode?.discount?.euros) {
        return `(-${this.formatPrice(promoCode.discount.euros, true)})`;
      } else if (promoCode?.discount?.percentage) {
        return `(-${promoCode.discount.percentage}%)`;
      }
    },

    getSessionsAverageRatings() {
      this.sessions.forEach((session) => {
        if (session.sessionBookings?.length > 0) {
          session.sessionBookings.forEach((booking) => {
            const { rating, comment } = booking;
            const { name, email, id } = booking.client;

            if (rating) {
              if (session.rating?.ratings) {
                session.rating.ratings = [
                  ...session.rating.ratings,
                  {
                    rating,
                    comment,
                    clientName: name || email,
                    clientId: id,
                    sessionId: session.id,
                  },
                ];
              } else {
                session.rating = {
                  ratings: [
                    {
                      rating,
                      comment,
                      clientName: name || email,
                      clientId: id,
                      sessionId: session.id,
                    },
                  ],
                };
              }
            }
          });

          if (session.rating?.ratings) {
            const ratings = session.rating.ratings.map(({ rating }) => rating);

            session.rating.average = parseFloat(
              (ratings.reduce((a, b) => a + b, 0) / ratings.length).toFixed(1)
            );
          }
        }
      });
    },

    getGlobalAverageRating() {
      this.ratedSessions = this.sessions.filter((session) => session.rating);

      const ratings = [];

      this.ratedSessions.forEach((session) => {
        session.rating.ratings.forEach((ratingObj) => {
          ratings.push(ratingObj.rating);
        });
      });

      this.averageSessionRate = parseFloat(
        (ratings.reduce((a, b) => a + b, 0) / ratings.length).toFixed(1)
      );
      this.totalRates = ratings.length;
    },

    handleLabel(e) {
      this.order.label = e.target.value;

      this.$api.put(`/orders/${this.order.id}`, {
        label: this.order.label,
      });
    },

    handleStatusType() {
      const conversion = ["decouverte", "one-shot"];
      const acquisition = [
        "decouverte",
        "pack-conversion",
        "one-shot",
        "autres",
      ];
      const fidelisation = ["pack-conversion", "pack-fidelisation", "one-shot"];

      const { value } = this.menuStat;

      if (value) {
        const isConversion = conversion.includes(value);
        const isAcquisition = acquisition.includes(value);
        const isFidelisation = fidelisation.includes(value);
        const discovery = isConversion && isAcquisition && !isFidelisation;

        this.$api.put(`/orders/${this.order.id}`, {
          isConversion,
          isAcquisition,
          isFidelisation,
          discovery,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pre-wrap {
  white-space: pre-wrap;
}

.info-live {
  font-weight: 600;
  color: #20a8d8;
}

.has-error {
  border: 1px solid #ff5454 !important;
  border-radius: 4px;
}
</style>
