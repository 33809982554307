<template>
  <tr>
    <td style="width: 50%">
      <span v-if="coach.deviceToken" class="text-success"
        ><i class="icon-screen-smartphone"></i
      ></span>

      {{ coach.name }}
      <a @click="goToUser"><i class="icon-user"></i></a>
      <span v-if="coach.disbursement" class="text-success doc-icon"
        ><i class="fa fa-file"></i
      ></span>

      <el-popover
        v-if="coach.notes"
        placement="top"
        title="Note manager"
        trigger="hover"
        :width="400"
        :content="coach.notes"
      >
        <template #reference><i class="fa fa-pencil"></i></template>
      </el-popover>

      <br />
      <div class="stats" v-if="coach.rank">
        <StatIndicator
          label="TC"
          :value="coach.rank.conversion"
          :range="[0, 100]"
          unit="%"
        />
        <StatIndicator
          label="TF"
          :value="coach.rank.fidelisation"
          :range="[0, 100]"
          unit="%"
        />
        <StatIndicator
          label="NC"
          :value="coach.rank.rank"
          :range="[0, 5]"
          :decimals="2"
        />
      </div>
      <small v-if="coachStatusInfo" :style="`color: ${coachStatusInfo.color};`">
        {{ coachStatusInfo.label }}
      </small>
      <br />
      <small v-if="coach.distance"
        >{{ coach.distance.distance }} {{ coach.distance.duration }}</small
      >
      <small v-else>Hors secteur {{ coach.distance }}</small>
      <br />
      <CoachStats :stats="coach.stats" />
    </td>
    <td>
      <div v-if="coach && coach.comments" style="min-width: max-content">
        <span
          class="com-pos"
          v-tooltip="{
            content: displayComs(coach.comments),
            placement: 'top',
          }"
        >
          <i class="fa fa-thumbs-up" />
          {{
            coach.comments.filter((com) => {
              return com.type === 1;
            }).length
          }}
        </span>
        <span
          class="com-neg"
          v-tooltip="{
            content: displayComs(coach.comments, true),
            placement: 'top',
          }"
        >
          <i class="fa fa-thumbs-down" />
          {{
            coach.comments.filter((com) => {
              return com.type === -1;
            }).length
          }}
        </span>
      </div>
    </td>
    <td class="text-right">
      <div v-if="accepted">
        <div v-if="coach.attributed">
          <span class="text-success">Attribué</span>
        </div>
        <div v-else>
          <span class="text-success">Accepté</span>
          <LoaderButton
            @ok="assign()"
            :loading="attribute"
            btn-class="btn-success"
            v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
          >
            <i class="icon-user-follow"></i> Attribuer
            <template v-if="credit">
              au tarif : {{ formatPrice(credit) }}
            </template>
          </LoaderButton>
          <button
            class="btn btn-sm btn-danger"
            @click="$emit('unassign')"
            v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
          >
            <i class="icon-close"></i> Refuser
          </button>
        </div>
        <small v-if="coach.respondedAt"
          >Accepté le : {{ formatDate(coach.respondedAt) }}</small
        >
      </div>
      <div v-else-if="rejected">
        <span class="text-danger">Refusé</span><br />
        <small v-if="coach.respondedAt"
          >Répondu le : {{ formatDate(coach.respondedAt) }}</small
        >
      </div>
      <div v-else-if="queued">
        <span class="text-primary">En attente de réponse</span>
        <button
          class="btn btn-sm btn-danger"
          @click="$emit('remove')"
          v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
        >
          <i class="icon-close"></i>
        </button>
        <br />
        <small>Envoyé le : {{ formatDate(coach.assignedAt) }}</small>
        <br />
        <button
          class="btn btn-link btn-sm"
          @click="$emit('force')"
          v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
        >
          <small>
            <i class="icon-user-follow"></i>
            forcer l'attribution
            <template v-if="credit"
              >au tarif : {{ formatPrice(credit) }}</template
            >
          </small>
        </button>
      </div>
      <div v-else>
        <LoaderButton
          @ok="add()"
          :loading="attribute"
          btn-class="btn-secondary"
          v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
        >
          <i class="icon-plus"></i> Ajouter
          <template v-if="credit">
            au tarif : {{ formatPrice(credit) }}
          </template>
        </LoaderButton>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import StatIndicator from "./coach/stats/indicator";
import LoaderButton from "./form/LoaderButton";
import CoachStats from "./CoachStats";

export default {
  components: {
    StatIndicator,
    LoaderButton,
    CoachStats,
  },
  props: {
    coach: {
      type: Object,
      default: null,
    },
    queued: {
      type: Boolean,
      default: false,
    },
    accepted: {
      type: Boolean,
      default: false,
    },
    rejected: {
      type: Boolean,
      default: false,
    },
    credit: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      adding: false,
      attribute: false,
    };
  },

  computed: {
    ...mapGetters({
      coachStatus: "getCoachStatus",
    }),

    coachStatusInfo() {
      if (this.coach?.status) {
        const status = this.coachStatus.find(
          (s) => s.value === this.coach.status
        );

        return status;
      }

      return false;
    },
  },

  methods: {
    goToUser(e) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "user-details",
        params: {
          id: this.coach.id,
        },
      });
      window.open(route.href, "_blank");
    },

    add() {
      this.adding = true;
      this.$emit("add");
    },

    assign() {
      this.attribute = true;
      this.$emit("assign");
    },

    displayComs(comments, negative) {
      const comsToDisplay = comments.filter((com) =>
        negative ? com.type === -1 : com.type === 1
      );

      const strings = comsToDisplay.map(
        ({ label, text, author }, index) =>
          `${index + 1}. ${label} - ${text} (${author})`
      );

      return strings.join(" ----- ");
    },
  },
};
</script>

<style scoped>
.doc-icon {
  padding-left: 5px;
}

.stats .badge {
  font-size: 12px;
  margin: 0;
}

.com-pos {
  color: #79c447;
}

.com-neg {
  color: #ff5454;
  margin-left: 4px;
}

.fa-pencil {
  padding-left: 5px;
  color: #409eff;
}
</style>
