<template>
  <div
    class="card"
    :class="customClass"
    v-show="
      departmentData && departmentData.data && departmentData.data.length !== 0
    "
  >
    <div class="card-header font-weight-bold">
      {{ departmentData.label }}
    </div>

    <div
      class="card-body p-1 font-weight-bold"
      v-if="departmentData.data?.length > 0"
    >
      <h5>
        {{ departmentData.data.length }} client{{
          departmentData.data.length > 1 ? "s" : ""
        }}
      </h5>
      <h5>Taux de fidelisation: {{ fidelisationRate }}%</h5>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    departmentData: {
      type: Object,
      default: null,
    },
    customClass: {
      type: String,
      default: "",
    },
  },

  computed: {
    fidelisationRate() {
      if (!this.departmentData.data || this.departmentData.data.length === 0)
        return 0;

      return (
        this.departmentData.data.reduce((acc, coach) => {
          return acc + parseFloat(coach.tf);
        }, 0) / this.departmentData.data.length
      ).toFixed(2);
    },
  },
};
</script>
