<template>
  <div class="card">
    <div class="card-block">
      <dl class="row">
        <dt class="col-sm-4">Disponibilités</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.disponibility"
            type="textarea"
            :class="{
              'has-error': !client.disponibility && !isCollectivity,
            }"
            @change="editClient('disponibility', $event)"
            @blur="handleBlur"
            v-if="checkPermission"
          />
          <span v-else>{{ client.disponibility }}</span>
        </dd>
        <dt class="col-sm-4">Objectif</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.objectif"
            type="textarea"
            :class="{
              'has-error': !client.objectif && !isCollectivity,
            }"
            @change="editClient('objectif', $event)"
            @blur="handleBlur"
            v-if="checkPermission"
          />
          <span v-else>{{ client.objectif }}</span>
        </dd>
        <dt class="col-sm-4">Détails objectif</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.clientNotes"
            type="textarea"
            :class="{
              'has-error': !client.clientNotes && !isCollectivity,
            }"
            @change="editClient('clientNotes', $event)"
            @blur="handleBlur"
            v-if="checkPermission"
          />
          <span v-else>{{ client.clientNotes }}</span>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      client: undefined,
    };
  },

  created() {
    this.client = this.clientData;
  },

  watch: {
    clientData(value) {
      this.client = value;
    },
  },

  computed: {
    checkPermission() {
      return (
        this.hasPermission(this.$store.state.user, "CLIENTS_WRITE") &&
        !this.client.deletedAt
      );
    },

    isCollectivity() {
      return this.client.isB2B || this.client.marketEstablishment;
    },
  },

  methods: {
    editClient(name, value) {
      this.$emit("edit", { name, value });
    },

    handleBlur() {
      let fieldsNotValid = true;

      if (!this.isCollectivity) {
        const fieldsToValidate = [
          !!this.client.disponibility,
          !!this.client.objectif,
          !!this.client.clientNotes,
        ];

        fieldsNotValid = fieldsToValidate.find((field) => field === false);
      }

      if (fieldsNotValid === false) {
        this.$errorToast(
          "Veuillez remplir les champs obligatoires (entourés en rouge)"
        );
      }
    },
  },
};
</script>
