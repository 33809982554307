<template>
  <div class="wrapper">
    <router-view></router-view>

    <div class="animated fadeIn">
      <div class="card">
        <div class="card-header font-weight-bold">Inactivité</div>

        <div class="card-block">
          <div class="row d-flex flex-row align-items-center">
            <div class="col-sm-3">Statut actif intervenant</div>
            <div class="col-sm-9">
              <el-input-number
                size="small"
                :min="0"
                v-model="settings['days_active_intervenant']"
                @change="handleChange('days_active_intervenant', $event)"
                v-if="hasPermission($store.state.user, 'SETTINGS_CONFIG_WRITE')"
              ></el-input-number>
              <span v-else>{{ settings["days_active_intervenant"] }}</span>
              <span class="ml-1"
                >Jours (durée sans séance validée en X jours)</span
              >
            </div>
          </div>

          <hr />

          <div class="row d-flex flex-row align-items-center">
            <div class="col-sm-3">Statut actif client</div>
            <div class="col-sm-9">
              <el-input-number
                size="small"
                :min="0"
                v-model="settings['days_active_client']"
                @change="handleChange('days_active_client', $event)"
                v-if="hasPermission($store.state.user, 'SETTINGS_CONFIG_WRITE')"
              ></el-input-number>
              <span v-else>{{ settings["days_active_client"] }}</span>
              <span class="ml-1"
                >Jours (durée sans séance validée en X jours)</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header font-weight-bold">
          Configuration rappel avoir coach (1er de chaque mois)
        </div>
        <div class="card-block">
          <div class="row d-flex flex-row align-items-center">
            <div class="col-sm-3">Prévenir par email à partir de</div>
            <div class="col-sm-9">
              <el-input-number
                size="small"
                :min="0"
                v-model="settings['reminder_credit_amount']"
                @change="handleChange('reminder_credit_amount', $event)"
                v-if="hasPermission($store.state.user, 'SETTINGS_CONFIG_WRITE')"
              ></el-input-number>
              <span v-else>{{ settings["reminder_credit_amount"] }}</span>
              <span class="ml-1">euros</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header font-weight-bold">
          <div class="d-flex justify-content-between align-items-center">
            <div><span>Facturation coach</span><br /></div>
          </div>
        </div>
        <div class="card-block">
          <div class="row d-flex flex-row align-items-center">
            <div class="col-sm-3">
              Débloquer la création de facture à partir de
            </div>
            <div class="col-sm-9">
              <el-input-number
                size="small"
                :min="0"
                v-model="settings['minimum_credit_amount']"
                @change="handleChange('minimum_credit_amount', $event)"
                v-if="hasPermission($store.state.user, 'SETTINGS_CONFIG_WRITE')"
              ></el-input-number>
              <span v-else>{{ settings["minimum_credit_amount"] }}</span>
              <span class="ml-1">euros</span>
            </div>
          </div>
          <hr />
          <div class="row d-flex flex-row align-items-center">
            <div class="col-sm-3">Bloquer la facturation unique par mois</div>
            <div class="col-sm-9">
              <el-switch
                v-model="settings['month_lock_credit']"
                @change="handleChange('month_lock_credit', $event)"
                v-if="hasPermission($store.state.user, 'SETTINGS_CONFIG_WRITE')"
                active-text="Oui"
                inactive-text="Non"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header font-weight-bold">
          <div class="d-flex justify-content-between align-items-center">
            <div><span>Cache</span><br /></div>
            <button class="btn btn-primary" @click="flushCache()">
              Vider le cache
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      settings: {},
      monthRankOptions: [
        {
          label: "1 mois",
          value: 1,
        },
        {
          label: "2 mois",
          value: 2,
        },
        {
          label: "3 mois",
          value: 3,
        },
        {
          label: "4 mois",
          value: 4,
        },
        {
          label: "5 mois",
          value: 5,
        },
        {
          label: "6 mois",
          value: 6,
        },
        {
          label: "1 an",
          value: 12,
        },
        {
          label: "2 ans",
          value: 24,
        },
        {
          label: "Depuis le début",
          value: "all",
        },
      ],
    };
  },
  methods: {
    async fetchSettings() {
      const { data } = await this.$api.get("/settings");

      this.settings = data;
    },

    async handleChange(key, value) {
      const { data } = await this.$api
        .patch("/settings", { [key]: value })
        .catch((e) => {
          this.$errorToast(`Erreur lors de la sauvegarde des paramètre: ${e}`);
        });

      this.config = data;
    },

    async flushCache() {
      try {
        this.$confirm({
          message: "Voulez-vous vraiment vider le cache ?",
          onConfirm: async () => {
            await this.$api.get(`/cache/flush`);
            this.$successToast(`Cache supprimé`);
          },
        });
      } catch (e) {
        this.$errorToast(`Erreur lors de la suppression du cache`);
      }
    },
  },
  created() {
    this.fetchSettings();
  },
};
</script>
