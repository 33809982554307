<template>
  <div class="d-flex gap-1" v-if="stats && stats.nbClients > 0">
    <div class="d-flex align-items-center mr-1" style="padding: 2px 5px">
      <i class="icon-people"></i>
      <span style="margin-left: 3px">x{{ stats.nbClients }}</span>
    </div>
    <div
      :class="`d-flex align-items-center mr-1 ${getCustomClass(stats.tc)}`"
      style="padding: 2px 5px"
    >
      TC
      <span style="margin-left: 3px">{{ stats.tc?.toFixed(2) }}%</span>
    </div>
    <div
      :class="`d-flex align-items-center mr-1 ${getCustomClass(stats.tf)}`"
      style="padding: 2px 5px"
      v-if="stats.nbClients > 3"
    >
      TF
      <span style="margin-left: 3px">{{ stats.tf?.toFixed(2) }}%</span>
    </div>
    <div class="d-flex align-items-center mr-1" style="padding: 2px 5px" v-else>
      TF
      <span style="margin-left: 3px">
        <i class="fa fa-hourglass-half"></i>
      </span>
    </div>
    <div class="col-md-3 d-flex align-items-center" style="padding: 2px 5px">
      LC
      <span style="margin-left: 3px">{{ stats.clientLongevity }} s</span>
    </div>
    <div class="col-md-3 d-flex align-items-center" style="padding: 2px 5px">
      <Rates :value="parseFloat(stats.externalRanking)" size="fa-1x" />
    </div>
  </div>
</template>

<script>
import Rates from "./Rates";

export default {
  components: {
    Rates,
  },
  props: {
    stats: {
      type: Object,
      default: null,
    },
  },

  methods: {
    getCustomClass(value) {
      if (value >= 66) {
        return "success";
      } else if (value >= 33 && value < 66) {
        return "warning";
      } else {
        return "danger";
      }
    },
  },
};
</script>

<style scoped>
.success {
  background-color: #28a745;
  color: white;
}

.warning {
  background-color: #ffc107;
  color: black;
}

.danger {
  background-color: #dc3545;
  color: white;
}
</style>
