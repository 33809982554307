<template>
  <div>
    <form>
      <div class="card" v-if="order">
        <div class="card-header">
          <button
            class="btn btn-small btn-info float-right"
            @click="showClient"
            v-if="hasPermission($store.state.user, 'CLIENTS_READ')"
          >
            Voir la fiche
          </button>
          <i class="icon-basket"></i> Commande n° {{ order.id }}
        </div>
        <div class="card-block">
          <div class="row">
            <div class="col-sm-7">
              <dl class="row">
                <dt class="col-sm-5">Concept</dt>
                <dd class="col-sm-7">{{ order.concept }}</dd>
                <dt class="col-sm-5">Prénom</dt>
                <dd class="col-sm-7" @click="showClient">
                  {{ order.client.firstName }}
                </dd>
                <dt class="col-sm-5">Nom</dt>
                <dd class="col-sm-7" @click="showClient">
                  {{ order.client.lastName }}
                </dd>
                <template v-if="order.client.company">
                  <dt class="col-sm-5">Société</dt>
                  <dd class="col-sm-7" @click="showClient">
                    {{ order.client.company }}
                  </dd>
                </template>
                <dt class="col-sm-5">Adresse</dt>
                <dd class="col-sm-7">{{ formatAddress(order.client) }}</dd>
                <dt class="col-sm-5">Email</dt>
                <dd class="col-sm-7">{{ order.client.email }}</dd>
                <dt class="col-sm-5">Téléphone</dt>
                <dd class="col-sm-7">{{ order.client.phone }}</dd>
                <dt class="col-sm-5" v-if="order.client.gender">Sexe</dt>
                <dd class="col-sm-7" v-if="order.client.gender">
                  {{ order.client.gender }}
                </dd>
                <dt class="col-sm-5">
                  <el-popover trigger="hover" placement="top" :width="400">
                    <div class="p-1 text-justify">
                      <p>
                        Si cette case est coché, alors le coach actuel sera
                        remplacé lors de l'attribution du nouveau coach pour les
                        sessions non faites. Si elle n'est pas coché, l'ancien
                        coach sera conservé et le nouveau sera ajouté.
                      </p>
                    </div>
                    <span slot="reference">
                      Remplacer le coach actuel ?
                      <i class="fa fa-info-circle ml-05"></i>
                    </span>
                  </el-popover>
                </dt>
                <dd class="col-sm-7">
                  <el-switch
                    v-model="order.attribution.replaceCoach"
                    active-text="Oui"
                    inactive-text="Non"
                    size="mini"
                    @change="editAttribution($event)"
                    :disabled="!checkClientPermission"
                  />
                </dd>
                <dt class="col-sm-5" v-if="order.client.preferedCoachGender">
                  Préférence coach
                </dt>
                <dd class="col-sm-7" v-if="order.client.preferedCoachGender">
                  {{ order.client.preferedCoachGender }}
                </dd>
                <dt class="col-sm-5" v-if="order.description">Commande</dt>
                <dd class="col-sm-7" v-if="order.description">
                  {{ order.description }}
                </dd>
                <dt class="col-sm-5">Pack</dt>
                <dd class="col-sm-7">{{ formatPack(order.session) }}</dd>
                <dt class="col-sm-5">Participants</dt>
                <dd class="col-sm-7">{{ order.clients }}</dd>
                <dt class="col-sm-5" v-if="order.label">Label</dt>
                <dd class="col-sm-7" v-if="order.label">{{ order.label }}</dd>
              </dl>
              <div class="row">
                <div class="col-sm-6">
                  <p><u>Labels commande</u> :</p>
                  <textarea
                    v-model="order.appNotes"
                    class="form-control"
                    rows="4"
                    v-if="
                      hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')
                    "
                  ></textarea>
                  <span v-else>{{ order.appNotes }}</span>
                  <button
                    class="btn btn-secondary btn-sm"
                    @click="handlEditAppNotes"
                    v-if="
                      hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')
                    "
                  >
                    Enregistrer
                  </button>
                  <p class="badge badge-success" v-if="savedNotes">
                    Enregistré !
                  </p>
                </div>
                <div class="col-sm-6">
                  <p><u>Notes admin</u> :</p>
                  <textarea
                    v-model="order.adminNotes"
                    class="form-control"
                    rows="4"
                    v-if="
                      hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')
                    "
                  ></textarea>
                  <span v-else>{{ order.adminNotes }}</span>
                  <button
                    class="btn btn-secondary btn-sm"
                    @click="handlEditAdminNotes"
                    v-if="
                      hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')
                    "
                  >
                    Enregistrer
                  </button>
                  <p class="badge badge-success" v-if="savedAdminNotes">
                    Enregistré !
                  </p>
                </div>
              </div>
              <div class="row recall">
                <div class="col-sm-2"><b>Rappel</b></div>
                <div class="col-sm-4">
                  <el-switch
                    :value="order.attribution.recall"
                    @change="updateRecall"
                    v-if="
                      hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')
                    "
                  />
                  <span v-else>{{
                    order.attribution.recall ? "Oui" : "Non"
                  }}</span>
                </div>
              </div>
            </div>

            <div class="col-sm-5">
              <geoloc :geoloc="order.geoloc" />
              <div>
                <label class="mr-1"
                  >Remplacer le nom de la ville
                  <span class="small">Ex. "Visio"</span></label
                >
                <el-switch
                  :value="order.attribution.overwriteCityEnabled"
                  @change="toggleOverwriteCity"
                  v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
                />
                <span v-else>{{
                  order.attribution.overwriteCityEnabled ? "Oui" : "Non"
                }}</span>
                <div
                  class="form-group"
                  v-if="order.attribution.overwriteCityEnabled"
                >
                  <label for="cardholderName"
                    >Nom de la ville
                    <span class="small">
                      : liste des attributions "appli mobile"</span
                    ></label
                  >
                  <input
                    @input="updateOverwriteCity"
                    type="text"
                    class="form-control"
                    v-model="order.attribution.overwriteCity"
                    v-if="
                      hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')
                    "
                  />
                  <span v-else>{{ order.attribution.overwriteCity }}</span>
                </div>
              </div>

              <div>
                <label class="mr-05">Attribution scrapping</label>
                <i
                  class="fa fa-info-circle mr-1"
                  v-tooltip="{
                    content:
                      'Ces informations seront actualisés dans la fiche du client APRÈS avoir terminer l\'attribution.',
                    placement: 'top',
                  }"
                ></i>
                <el-switch
                  :value="order.attribution.prospectedCoach"
                  @change="toggleProspectedCoach"
                  v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
                />
                <span class="ml-1" v-if="order.attribution.prospectedCoach">
                  Prospecté le
                  {{ formatDate(order.attribution.prospectedAt) }} par
                  {{ order.attribution.prospectedBy }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <template v-if="order">
      <div
        class="card card-inverse card-danger text-center"
        v-if="order.sessions && order.sessions.length == 0"
      >
        <div class="card-block">
          <blockquote class="card-blockquote">
            Attention, il n'y a aucune séance sur cette commande
          </blockquote>
        </div>
      </div>

      <filters
        :order="order"
        :client="order.client"
        :attribution="order.attribution"
        :setCredit="true"
        @assign="assignCoach"
        ref="attributionFiltersRef"
        v-else
      />
    </template>

    <ModalProspectedCoach
      :visible="showModalProspectedCoach"
      :close="closeProspectedCoach"
      :updateClient="addedProspectedCoach"
    />
  </div>
</template>

<script>
import Geoloc from "../components/Geoloc";
import Filters from "../components/AttributionFilters";
import debounce from "lodash.debounce";
import ModalProspectedCoach from "../components/coachManager/scrapping/prospected-coach/ModalProspectedCoach.vue";

export default {
  components: {
    Geoloc,
    Filters,
    ModalProspectedCoach,
  },
  data() {
    return {
      order: null,
      savedNotes: false,
      savedAdminNotes: false,
      showModalProspectedCoach: false,
    };
  },
  created() {
    this.loadAttribution();
  },

  computed: {
    checkClientPermission() {
      return (
        this.hasPermission(this.$store.state.user, "CLIENTS_WRITE") &&
        !this.order.client?.deletedAt
      );
    },
  },

  methods: {
    toggleProspectedCoach() {
      if (!this.order.attribution.prospectedCoach) {
        this.showModalProspectedCoach = true;
      } else {
        this.addedProspectedCoach({
          prospectedBy: null,
          prospectedAt: null,
          prospectedCoach: false,
        });
      }
    },

    closeProspectedCoach() {
      this.showModalProspectedCoach = false;
    },

    async addedProspectedCoach(updateData) {
      try {
        const { data } = await this.$api.patch(
          `/attributions/${this.order.attribution.id}/coach-manager`,
          {
            prospectedBy: updateData.prospectedBy,
            prospectedAt: updateData.prospectedAt,
            prospectedCoach: updateData.prospectedCoach,
          }
        );

        this.order.attribution.prospectedBy = data.prospectedBy;
        this.order.attribution.prospectedAt = data.prospectedAt;
        this.order.attribution.prospectedCoach = data.prospectedCoach;
        this.$successToast(data.message);
      } catch (e) {
        this.$errorToast("La mise à jour de l'attribution a échoué");
      }
    },

    async editClient() {
      try {
        await this.$api.put("/clients", {
          email: this.order.client.email,
        });
      } catch (e) {
        this.$errorToast("La mise à jour du client a échoué");
      }
    },

    async editAttribution(value) {
      try {
        await this.$api.put(`/attributions/${this.order.attribution.id}`, {
          replaceCoach: value,
        });
      } catch (e) {
        this.$errorToast("La mise à jour de l'attribution a échoué");
      }
    },

    handlEditAppNotes() {
      this.$api
        .put("/orders/" + this.order.id, {
          appNotes: this.order.appNotes,
        })
        .then(() => {
          this.savedNotes = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handlEditAdminNotes() {
      this.$api
        .put("/orders/" + this.order.id, {
          adminNotes: this.order.adminNotes,
        })
        .then(() => {
          this.savedAdminNotes = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async assignCoach(coach, credit) {
      await this.$api.put("/sessions", {
        attributionId: this.order.attribution.id,
        replaceCoach: this.order.attribution.replaceCoach,
        orderId: this.order.id,
        coachs: coach.email,
        credit: credit,
      });

      this.$refs.attributionFiltersRef.refreshCoachs();
    },

    updateRecall() {
      this.$api
        .put(`/attributions/${this.order.attribution.id}`, {
          recall: !this.order.attribution.recall,
        })
        .then(() => {
          this.loadAttribution();
        });
    },

    loadAttribution() {
      this.$api
        .get("/orders/" + this.$route.params.id)
        .catch(() => {
          this.$router.push({
            name: "attributions",
          });
        })
        .then((res) => {
          this.order = res.data;

          if (this.order.client.geoloc) {
            this.order.geoloc = {
              lat: this.order.client.geoloc.coordinates[1],
              lng: this.order.client.geoloc.coordinates[0],
            };
          }
          if (!this.order.client.notes) {
            this.$set(this.order.client, "notes", "");
          }
        });
    },

    showClient() {
      this.$router.push({
        name: "client-details",
        params: {
          id: this.order.client.id,
        },
      });
    },

    async toggleOverwriteCity(value) {
      this.order.attribution.overwriteCityEnabled = value;

      await this.$api.put(
        `/attributions/toggle-overwrite/${this.order.attribution.id}`,
        { value }
      );
    },

    async updateOverwriteCity() {
      await this.saveOverwriteCity(this);
    },

    saveOverwriteCity: debounce(async (vm) => {
      await vm.$api.put(
        `/attributions/city-overwrite/${vm.order.attribution.id}`,
        { value: vm.order.attribution.overwriteCity }
      );
    }, 600),
  },
};
</script>

<style lang="css">
.row.recall {
  margin-top: 15px;
}
</style>
