<template>
  <div class="p-2">
    <h4>Total: {{ data.length }} client{{ data.length > 1 ? "s" : "" }}.</h4>
    <div class="mt-2">
      <div v-for="lostReason of completeLostReason" :key="lostReason.value">
        <div
          v-if="
            data.filter(
              (client) => client.lostReason.value === lostReason.value
            ).length > 0
          "
          :style="getLostReasonColor(lostReason.value)"
        >
          {{ lostReason.label }}:
          {{
            data.filter(
              (client) => client.lostReason.value === lostReason.value
            ).length
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    data: { type: Array, required: true },
  },

  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
    }),

    completeLostReason() {
      return [
        {
          label: "Aucune raison",
          value: "no-reason",
          color: "#d3d3d3",
        },
        ...this.lostReasons,
      ];
    },
  },

  methods: {
    getLostReasonColor(lostReason) {
      return {
        padding: ".5rem",
        "padding-left": "1rem",
        "border-radius": "1rem",
        "margin-bottom": ".3rem",
        "margin-top": ".3rem",
        color: "white",
        "background-color": this.completeLostReason.find(
          (s) => s.value === lostReason
        ).color,
      };
    },
  },
};
</script>
