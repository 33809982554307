<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h3>
          Statistique par période
          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content: tooltip.staticStats,
              placement: 'top',
            }"
          ></i>
        </h3>
        <div class="d-flex align-items-center mt-1">
          <div
            class="cursor-pointer"
            @click="openTab = !openTab"
            v-if="stats.length > 0"
          >
            <span>
              <i :class="openTab ? 'icon-arrow-down' : 'icon-arrow-right'"></i>
            </span>
          </div>
          <div class="row ml-1">
            <div class="form-group col-md-6">
              <label class="control-label">Année</label>
              <select class="form-control" v-model="filters.year">
                <option :value="null"></option>
                <option :key="year" v-for="year of filterYears" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label class="control-label">Mois</label>
              <select class="form-control" v-model="filters.month">
                <option :value="null"></option>
                <option
                  :key="month.value"
                  v-for="month of filterMonths"
                  :value="month.value"
                >
                  {{ month.name }}
                </option>
              </select>
            </div>
            <div class="col-md-12">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                @click="updateForm()"
              >
                <i class="icon-eye" aria-hidden="true"></i>&nbsp; Visualiser
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-block" v-if="openTab">
        <div class="row">
          <div class="col-md-6 p-2 mt-1" v-for="stat of stats" :key="stat.id">
            <div
              class="rounded p-1"
              :style="`background-color:${stat.commercialColor}`"
            >
              <el-checkbox
                v-if="stat.active !== undefined"
                v-model="stat.active"
              ></el-checkbox>
              <strong
                class="ml-1"
                :class="!stat.active ? 'text-gray-dark' : ''"
                >{{ stat.commercialName }}</strong
              >
            </div>
            <div class="row" v-if="stat.active || stat.id === 'total'">
              <div class="col-md-4 mt-1 separator">
                <div class="stat-table">
                  <div>
                    <div>
                      <span class="font-weight-bold">Heure d'appels</span>
                      <i
                        v-if="stat.id === 'total'"
                        class="fa fa-info-circle ml-05"
                        v-tooltip="{
                          content: tooltip.nbCallTime,
                          placement: 'top',
                        }"
                      ></i>
                    </div>
                    <div>{{ stat.nbCallTime }}</div>
                  </div>
                  <div>
                    <div>
                      <span class="font-weight-bold">Nb appels sortants</span>
                      <i
                        v-if="stat.id === 'total'"
                        class="fa fa-info-circle ml-05"
                        v-tooltip="{
                          content: tooltip.nbOutCall,
                          placement: 'top',
                        }"
                      ></i>
                    </div>
                    <div>{{ stat.nbOutCall }}</div>
                  </div>
                  <div>
                    <div>
                      <span class="font-weight-bold">Nb avis</span>
                      <i
                        v-if="stat.id === 'total'"
                        class="fa fa-info-circle ml-05"
                        v-tooltip="{
                          content: tooltip.notices,
                          placement: 'top',
                        }"
                      ></i>
                    </div>
                    <div>{{ stat.notices }}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 mt-1 separator">
                <div class="stat-table">
                  <div>
                    <div>
                      <span class="font-weight-bold">Pack impayés</span>
                      <i
                        v-if="stat.id === 'total'"
                        class="fa fa-info-circle ml-05"
                        v-tooltip="{
                          content: tooltip.nbUnpayedOrder,
                          placement: 'top',
                        }"
                      ></i>
                    </div>
                    <div>{{ stat.nbUnpayedOrder }}</div>
                  </div>
                  <div>
                    <div>
                      <span class="font-weight-bold">CA impayés</span>
                      <i
                        v-if="stat.id === 'total'"
                        class="fa fa-info-circle ml-05"
                        v-tooltip="{
                          content: tooltip.caUnpayedOrder,
                          placement: 'top',
                        }"
                      ></i>
                    </div>
                    <div>{{ formatPrice(stat.caUnpayedOrder) || 0 }}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-5 mt-1">
                <div class="stat-table">
                  <div>
                    <div>
                      <span class="font-weight-bold">Prospect + Clients</span>
                      <i
                        v-if="stat.id === 'total'"
                        class="fa fa-info-circle ml-05"
                        v-tooltip="{
                          content: tooltip.nbProspectsClients,
                          placement: 'top',
                        }"
                      ></i>
                    </div>
                    <div>{{ stat.nbProspects + stat.nbClients }}</div>
                  </div>
                  <div>
                    <div>
                      <span class="font-weight-bold">Clients actifs</span>
                      <i
                        v-if="stat.id === 'total'"
                        class="fa fa-info-circle ml-05"
                        v-tooltip="{
                          content: tooltip.activeClients,
                          placement: 'top',
                        }"
                      ></i>
                    </div>
                    <div>{{ stat.activeClients }}</div>
                  </div>
                  <div>
                    <div>
                      <span class="font-weight-bold">% actifs</span>
                      <i
                        v-if="stat.id === 'total'"
                        class="fa fa-info-circle ml-05"
                        v-tooltip="{
                          content: tooltip.activePercent,
                          placement: 'top',
                        }"
                      ></i>
                    </div>
                    <div>{{ getActivePercent(stat) }} %</div>
                  </div>
                  <div>
                    <div>
                      <span class="font-weight-bold">Inactifs</span>
                      <i
                        v-if="stat.id === 'total'"
                        class="fa fa-info-circle ml-05"
                        v-tooltip="{
                          content: tooltip.inactiveClients,
                          placement: 'top',
                        }"
                      ></i>
                    </div>
                    <div>{{ stat.inactiveClients }}</div>
                  </div>
                  <div>
                    <div>
                      <span class="font-weight-bold">Perdu</span>
                      <i
                        v-if="stat.id === 'total'"
                        class="fa fa-info-circle ml-05"
                        v-tooltip="{
                          content: tooltip.lostClients,
                          placement: 'top',
                        }"
                      ></i>
                    </div>
                    <div>{{ stat.lostClients }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    defaultStat: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
    },
  },

  data() {
    return {
      openTab: false,
      filters: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
      },
      filterYears: [],
      filterMonths: [
        {
          value: 1,
          name: "Janvier",
        },
        {
          value: 2,
          name: "Février",
        },
        {
          value: 3,
          name: "Mars",
        },
        {
          value: 4,
          name: "Avril",
        },
        {
          value: 5,
          name: "Mai",
        },
        {
          value: 6,
          name: "Juin",
        },
        {
          value: 7,
          name: "Juillet",
        },
        {
          value: 8,
          name: "Aout",
        },
        {
          value: 9,
          name: "Septembre",
        },
        {
          value: 10,
          name: "Octobre",
        },
        {
          value: 11,
          name: "Novembre",
        },
        {
          value: 12,
          name: "Decembre",
        },
      ],
      tooltip: {
        staticStats: "Statistiques arreté en fin de mois.",
        nbProspectsClients:
          "Nombre de prospects et clients créé sur la période, attribués au commercial",
        activeClients:
          "Nombre de clients actifs créé sur la période, attribués au commercial",
        activePercent:
          "Ratio des clients actifs par rapport au nombre total de CLIENTS.",
        inactiveClients:
          "Nombre de clients inactifs créé sur la période, attribués au commercial",
        lostClients:
          "Nombre de clients perdus créé sur la période, attribués au commercial",
        nbCallTime:
          "Temps total d'appels sortants sur la période du commercial",
        nbOutCall: "Nombre d'appels sortants sur la période du commercial",
        notices:
          "Nombre d'avis déposé sur la période par les prospects/clients du commercial",
        nbUnpayedOrder:
          "Nombre de pack impayés (similaire aux packs du menu 'Facturation/impayés') dont la date de création de la facture est dans la période.",
        caUnpayedOrder: "Chiffre d'affaire des pack impayés",
      },
    };
  },

  created() {
    const currentYear = new Date().getFullYear();
    this.filterYears = Array.from({ length: 10 }, (v, k) => currentYear - k);
  },

  computed: {
    stats() {
      if (this.defaultStat.length === 0) return [];

      this.defaultStat.forEach((stat) => {
        const date = new Date(null);
        date.setSeconds(stat.nbCallSecs);

        stat.nbCallTime = date.toISOString().slice(11, 19);
      });

      const stats = this.defaultStat.filter((stat) => stat.active);

      const nbCallSecs = stats.reduce((acc, stat) => acc + stat.nbCallSecs, 0);
      const date = new Date(null);

      date.setSeconds(nbCallSecs);
      return [
        {
          commercialName: "Total",
          id: "total",
          commercialColor: "#808080",
          activeClients: stats.reduce(
            (acc, stat) => acc + stat.activeClients,
            0
          ),
          caUnpayedOrder: stats.reduce(
            (acc, stat) => acc + stat.caUnpayedOrder,
            0
          ),
          inactiveClients: stats.reduce(
            (acc, stat) => acc + stat.inactiveClients,
            0
          ),
          lostClients: stats.reduce((acc, stat) => acc + stat.lostClients, 0),
          nbCallSecs,
          nbCallTime: date.toISOString().slice(11, 19),
          nbOutCall: stats.reduce((acc, stat) => acc + stat.nbOutCall, 0),
          notices: stats.reduce((acc, stat) => acc + stat.notices, 0),
          nbUnpayedOrder: stats.reduce(
            (acc, stat) => acc + stat.nbUnpayedOrder,
            0
          ),
          nbProspects: stats.reduce((acc, stat) => acc + stat.nbProspects, 0),
          nbClients: stats.reduce((acc, stat) => acc + stat.nbClients, 0),
        },
      ].concat(this.defaultStat);
    },
  },

  methods: {
    getActivePercent(stat) {
      if (stat.nbClients === 0) return 0;

      return ((stat.activeClients / stat.nbClients) * 100).toFixed(2);
    },

    updateForm() {
      this.$emit("updateForm", this.filters);
      this.openTab = true;
    },
  },
};
</script>

<style type="text/css" scoped>
.stat-table > div {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.rounded {
  border-radius: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.separator {
  border-right: 1px solid black;
}
</style>
