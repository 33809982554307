<template>
  <div>
    <div class="card text-center" v-if="data.length === 0">
      <div class="card-block">
        <blockquote class="card-blockquote">
          Aucune données, appliquer ou modifier les filtres
        </blockquote>
      </div>
    </div>
    <div v-else>
      <template>
        <div class="flex flex-col flex-wrap">
          <div class="flex flex-col">
            <h3>
              TOP COACHS
              <i
                class="fa fa-info-circle ml-05"
                v-tooltip="{
                  content: 'Coachs ayant les notes les plus positive',
                  placement: 'top',
                }"
              ></i>
            </h3>
            <InternalNotesLevel
              id="successCoachs"
              type="success"
              :coachs="successCoachs"
            />
          </div>

          <div class="flex flex-col mt-3">
            <h3>
              MIDDLE COACHS
              <i
                class="fa fa-info-circle ml-05"
                v-tooltip="{
                  content: 'Coachs ayant des notes qui s\'annulent',
                  placement: 'top',
                }"
              ></i>
            </h3>
            <InternalNotesLevel
              id="warningCoachs"
              type="warning"
              :coachs="warningCoachs"
            />
          </div>

          <div class="flex flex-col mt-3">
            <h3>
              BAD COACHS
              <i
                class="fa fa-info-circle ml-05"
                v-tooltip="{
                  content: 'Coachs des notes négatives',
                  placement: 'top',
                }"
              ></i>
            </h3>
            <InternalNotesLevel
              id="dangerCoachs"
              type="danger"
              :coachs="dangerCoachs"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import InternalNotesLevel from "./InternalNotesLevel";

export default {
  components: {
    InternalNotesLevel,
  },

  props: {
    data: { type: Array, required: true },
    filters: {
      required: true,
      type: Object,
    },
  },

  computed: {
    successCoachs() {
      return this.data.filter((coach) => coach.points > 0);
    },
    warningCoachs() {
      return this.data.filter((coach) => coach.points == 0);
    },
    dangerCoachs() {
      return this.data.filter((coach) => coach.points < 0);
    },
  },

  methods: {
    goToCoach(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "user-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },
  },
};
</script>
