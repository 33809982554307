<template>
  <div>
    <div :class="`custom-card custom-card-${this.type}`">
      <div class="left-icon-btn" @click="displaySubTab = !displaySubTab">
        <i class="fa" :class="displaySubTab ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div class="card-data">
        <div class="card-column">
          <span>{{ coachs.length }}</span>
          <span>Coachs</span>
        </div>
        <div class="card-column">
          <span>{{ noteRate }}</span>
          <span>notes moyenne</span>
        </div>
      </div>
    </div>
    <div v-if="displaySubTab" :class="`subtab subtab-${this.type}`">
      <el-table :data="coachsSorted">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="row">
              <div class="col-md-12">
                <el-table :data="scope.row.comments" style="width: 100%">
                  <el-table-column label="Client" prop="client">
                    <template slot-scope="comment">
                      <span
                        class="user-link"
                        @click="(e) => goToClient(e, comment.row.client?.id)"
                      >
                        {{ comment.row.client?.name }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Note" prop="rating" />
                  <el-table-column label="Commentaire" prop="text" />
                </el-table>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Nom" prop="name" sortable>
          <template slot-scope="scope">
            <span class="user-link" @click="(e) => goToCoach(e, scope.row.id)">
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Classement" prop="rank" sortable />
        <el-table-column label="Département" prop="department" sortable />
        <el-table-column label="Notes" prop="points" sortable />
        <el-table-column label="Nombre de notes" sortable>
          <template slot-scope="scope">
            {{ scope.row.comments.length }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coachs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      displaySubTab: false,
    };
  },

  computed: {
    coachsSorted() {
      const sorted = this.coachs;

      sorted.sort((a, b) => {
        return b.points - a.points;
      });

      // coach rank based on points value
      let currentRank = 1;
      let sameRankCount = 1;

      sorted.forEach((coach, index) => {
        if (index === 0) {
          coach.rank = currentRank;
        } else if (coach.points === sorted[index - 1].points) {
          coach.rank = currentRank;
        } else {
          currentRank += sameRankCount;
          coach.rank = currentRank;
          sameRankCount = 1;
        }
      });

      return sorted;
    },

    noteRate() {
      if (this.coachs.length === 0) return 0;
      return (
        this.coachs.reduce((acc, coach) => {
          return acc + parseFloat(coach.points);
        }, 0) / this.coachs.length
      ).toFixed(2);
    },
  },

  methods: {
    goToCoach(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "user-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },

    goToClient(e, id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },
  },
};
</script>

<style scoped>
.custom-card {
  position: relative;
  display: flex;
  border-radius: 15px;
  align-items: center;
  color: white;
  z-index: 5;
}
.card-data {
  position: relative;
  display: flex;
  border-radius: 15px;
  justify-content: space-around;
  align-items: center;
  color: white;
  z-index: 5;
  padding: 0 20px;
  flex-wrap: wrap;
  flex-grow: 1;
}
.custom-card-success {
  background-color: #28a745;
}

.custom-card-warning {
  background-color: #ffc107;
}

.custom-card-danger {
  background-color: #dc3545;
}

.left-icon-btn {
  border-right: thick solid white;
  padding: 30px 20px;
  cursor: pointer;
}

.subtab {
  position: relative;
  margin-top: -20px;
  padding: 15px;
  padding-top: 35px;
  border-radius: 10px;
  max-height: 400px;
  overflow: auto;
  z-index: 1;
}

.subtab-success {
  border-right: 1px solid #28a745;
  border-left: 1px solid #28a745;
  border-bottom: 1px solid #28a745;
}

.subtab-warning {
  border-right: 1px solid #ffc107;
  border-left: 1px solid #ffc107;
  border-bottom: 1px solid #ffc107;
}

.subtab-danger {
  border-right: 1px solid #dc3545;
  border-left: 1px solid #dc3545;
  border-bottom: 1px solid #dc3545;
}

.card-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
}

i.fa-thumbs-up {
  color: #79c447;
}

i.fa-thumbs-down {
  color: #ff5454;
}
</style>
