<template>
  <modal v-model="visible" effect="fade/zoom">
    <div slot="modal-header"></div>

    <div class="row">
      <small v-if="isUpdate" class="text-muted mx-1">
        {{ descriptionToEdit.updatedAt ? "Modifier" : "Créer" }} le
        {{
          descriptionToEdit.updatedAt
            ? formatDateTime(descriptionToEdit.updatedAt)
            : formatDateTime(descriptionToEdit.createdAt)
        }}
      </small>

      <input
        class="form-control mx-1"
        type="text"
        v-model="descriptionToEdit.label"
      />

      <textarea
        class="form-control mx-1 mt-1"
        rows="5"
        v-model="descriptionToEdit.description"
      ></textarea>
    </div>

    <div
      slot="modal-footer"
      class="modal-footer d-flex justify-content-between"
    >
      <button class="btn btn-secondary" @click="deleteDescription">
        <i class="fa fa-trash"></i>
      </button>

      <div>
        <button class="btn btn-secondary" @click="close">
          <i class="fa fa-check mr-05"></i>Fermer
        </button>

        <button v-if="isUpdate" class="btn btn-primary ml-1" @click="edit">
          <i class="fa fa-plus mr-05"></i>Modifier
        </button>

        <button v-if="!isUpdate" class="btn btn-primary ml-1" @click="create">
          <i class="fa fa-plus plus mr-05"></i>Ajouter
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    description: {
      type: Object,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      visible: false,
      descriptionToEdit: {},
    };
  },

  watch: {
    show(val) {
      this.visible = val;
    },

    description(val) {
      this.descriptionToEdit = val;
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    async edit() {
      this.$confirm({
        message: "Êtes-vous sûr de vouloir modifier cette description ?",
        onConfirm: async () => {
          try {
            const { data } = await this.$api.patch(
              `/client-order-description/${this.descriptionToEdit.id}`,
              this.descriptionToEdit
            );

            this.$emit("editOrderDescription", data);
            this.$toast.success(
              "Description de la commande modifiée avec succès"
            );
            this.close();
          } catch (e) {
            this.$toast.error(
              "Erreur lors de la modification de la description de la commande"
            );
          }
        },
      });
    },

    async deleteDescription() {
      this.$confirm({
        message: "Êtes-vous sûr de vouloir supprimer cette description ?",
        onConfirm: async () => {
          try {
            await this.$api.delete(
              `/client-order-description/${this.descriptionToEdit.id}`
            );

            this.$emit("deleteOrderDescription", this.descriptionToEdit.id);
            this.$toast.success(
              "Description de la commande supprimée avec succès"
            );
            this.close();
          } catch (e) {
            this.$toast.error(
              "Erreur lors de la suppression de la description de la commande"
            );
          }
        },
      });
    },

    async create() {
      try {
        const { data } = await this.$api.post("/client-order-description", {
          label: this.descriptionToEdit.label,
          description: this.descriptionToEdit.description,
          clientId: this.clientId,
        });

        this.$emit("addOrderDescription", data);
        this.$toast.success("Description de la commande ajoutée avec succès");
        this.close();
      } catch (e) {
        this.$toast.error(
          "Erreur lors de l'ajout de la description de la commande"
        );
      }
    },
  },
};
</script>

<style scoped>
.fa-trash {
  color: red;
}
</style>
