<template>
  <div class="d-flex justify-content-between">
    <el-select
      v-model="code"
      filterable
      :filter-method="filterCountries"
      :size="size"
      placeholder="Pays"
      style="max-width: 85px; margin-right: 5px"
      @change="changeCode"
    >
      <el-option
        v-for="country in filteredOptions"
        :key="country.isoCode"
        :label="country.phoneCode"
        :value="country.phoneCode"
      >
        <span :class="country.flagIcon" class="mr-05"></span>
        <span>{{ country.name }}</span>
        <span class="float-right text-muted">({{ country.phoneCode }})</span>
      </el-option>
    </el-select>

    <el-input
      v-model="number"
      :size="size"
      :class="{ 'has-error': required && !number }"
      @change="changeNumber"
      @blur="requireField($event)"
    />

    <template v-if="copy">
      <button
        v-if="copied"
        class="btn btn-sm btn-outline-success ml-05"
        disabled
      >
        <i class="fa fa-check"></i>
      </button>
      <button
        v-else
        type="button"
        class="btn btn-sm btn-info ml-05"
        :disabled="!number"
        @click="copyFullNumber"
      >
        <i class="fa fa-copy"></i>
      </button>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    phoneNumber: {
      type: String,
      default: null,
    },

    phoneCode: {
      type: String,
      default: null,
    },

    size: {
      type: String,
      default: "small",
    },

    required: {
      type: Boolean,
      default: false,
    },

    copy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      code: "",
      number: "",
      query: null,
      copied: false,
    };
  },

  computed: {
    ...mapGetters({
      phoneCodes: "getPhoneCodes",
    }),

    filteredOptions() {
      if (this.query) {
        return this.phoneCodes.filter(({ name }) =>
          name.toLowerCase().startsWith(this.query)
        );
      }

      return this.phoneCodes;
    },

    fullNumber() {
      let number = this.number.split(" ").join("");

      if (this.code && number.startsWith("0")) {
        number = number.substring(1);
      }

      return this.code ? this.code + number : number;
    },
  },

  mounted() {
    const initPhoneNumber = this.phoneNumber;
    const initPhoneCode = this.phoneCode;

    this.number = initPhoneNumber;
    this.code = initPhoneCode;
  },

  methods: {
    requireField(e) {
      if (e.target.value === "") {
        this.$emit("checkField", false);
      } else {
        this.$emit("checkField", true);
      }
    },

    filterCountries(query) {
      this.query = query ? query.toLowerCase() : null;
    },

    changeNumber(number) {
      this.$emit("changeNumber", number);
    },

    changeCode(code) {
      this.$emit("changeCode", code);
    },

    copyFullNumber() {
      navigator.clipboard.writeText(this.fullNumber);

      this.copied = true;
      this.$successToast("Copié avec succès !");

      setTimeout(() => {
        this.copied = false;
      }, 4000);
    },
  },
};
</script>
