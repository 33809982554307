<template>
  <div class="rank-custom-wrapper">
    <Filters :default="filters" @update="handleFilters" />

    <div class="card card-inverse card-info text-center" v-if="loading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>

    <Tabs v-model="activeTab">
      <Tab header="Données individuelles">
        <Internal :data="internal" :filters="filters" />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import Filters from "../components/coachManager/note/Filters";
import Internal from "../components/coachManager/note/Internal";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

export default {
  components: {
    Filters,
    Internal,
    Tabs,
    Tab,
  },
  data() {
    return {
      loading: false,
      internal: [],
      activeTab: 0,
      filters: this.defaultFilters(),
    };
  },

  methods: {
    handleFilters(newFilters) {
      this.filters = newFilters;

      this.loadCoachs();
    },

    async loadCoachs() {
      try {
        this.loading = true;

        const { data } = await this.$api.get(`/rank/internal/notes`, {
          params: {
            status: this.filters.status.map((s) => s.value),
            merchants: this.filters.merchants.map((m) => m.value),
            concepts: this.filters.concepts.map((s) => s.value),
            prestations: this.filters.prestations.map((s) => s.value),
            languages: this.filters.languages.map((s) => s.value),
            departments: this.filters.departments.map((s) => s.value),
            tags: this.filters.tags.map((s) => s.value),
            genders: this.filters.genders,
          },
        });

        this.internal = data;

        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },

    defaultFilters() {
      return {
        status: [],
        merchants: [],
        concepts: [],
        prestations: [],
        languages: [],
        tags: [],
        genders: [],
        departments: [],
      };
    },
  },
};
</script>
